import React from "react";
import TheValueProducts from "../theDayValue/theValueProducts/TheValueProducts";

const NewArrivals = () => {
  return (
    <>
      <div className="section">
        <TheValueProducts />
      </div>
    </>
  );
};

export default NewArrivals;
