import moment from "moment";
import React, { useState } from "react";
import "./MyBooking.scss";
import { useNavigate } from "react-router-dom";
import OrderEmpty from "../../../Cart/orderEmptyView/OrderEmpty";
import FeedBack from "../feedback/FeedBack";

const MyBooking = ({ getBookedServices, setCurrentTab }) => {
  const navigate = useNavigate();
  const [openFeedback, setOpenFeedback] = useState(false);
  const [productId, setProductId] = useState();

  const calculateTotal = (orderItem) => {
    var totalPrice = null;

    orderItem &&
      orderItem.forEach(function (value, index, arry) {
        totalPrice += parseInt(value.product_price);
      });

    return totalPrice.toFixed(2);
  };

  return (
    <div className="OrderPage-wrapper">
      {getBookedServices && getBookedServices.length === 0 ? (
        <OrderEmpty />
      ) : (
        <>
          {getBookedServices &&
            getBookedServices.map((data, key) => (
              <section className="recent-orderwrapper" key={key}>
                <div className="recent-order-toptab">
                  <div className="order-place-wrapp">
                    <p className="order-place-line">Booking Date</p>
                    <p className="order-date">
                      {moment(data.created_date, "DD/MM/YYYY").format(
                        "DD MMMM YYYY"
                      )}
                    </p>
                    <p className="order-date">
                      Time: {data.order_item[0].sloat_time}
                    </p>
                  </div>
                  <div className="shipto-wrapper">
                    <p className="shipto-line">Service TO</p>
                    <p className="owner-order-name">
                      {data.order_meta.shipping_first_name}
                    </p>
                    <p className="short-addras">
                      {data.order_meta.shipping_address2}
                    </p>
                  </div>
                  <div className="total-wrapper">
                    <p className="total-tag">Booking Price</p>
                    <p className="total-amounts">
                      {data.order_meta.currency_sign}
                      {calculateTotal(data.order_item)}
                    </p>
                  </div>
                  <div className="order-details-wrapper">
                    <p className="order-number">Order #{data.order_id}</p>
                    <p className="vierorder-details-link">
                      <span
                        onClick={() => {
                          setCurrentTab("orderdetails");
                          navigate("/user/orderdetails");
                          localStorage.setItem("orderViewId", data.id);
                        }}
                      >
                        View Order Details
                      </span>{" "}
                      | <span>Download Invoice</span>
                    </p>
                  </div>
                </div>
                {data &&
                  data.order_item &&
                  data.order_item.map((item, key) => (
                    <div className="order-items-wrapper" key={key}>
                      <p className="deliverd-status">Booking Date</p>
                      <section className="order-product-wrapper">
                        <div className="order-product-wrapperImage">
                          <div className="order-productimg">
                            <img src={item.featured_image} alt="#" />
                          </div>
                          <div className="order-items-info">
                            <p className="order-product-name">
                              {item.product_name}
                            </p>

                            <label className="price-tag">
                              {data.order_meta.currency_sign}
                              {parseInt(item.product_price).toFixed(2)}
                            </label>
                          </div>
                        </div>
                        <div className="orders-btns">
                          <div
                            className="trackpackage-btn"
                            onClick={() => {
                              setOpenFeedback(true);
                              setProductId(item);
                            }}
                          >
                            Service Feedback
                            <i className="fas fa-angle-right" />
                          </div>
                        </div>
                      </section>
                    </div>
                  ))}
              </section>
            ))}
        </>
      )}
      <FeedBack
        open={openFeedback}
        productId={productId}
        onCloseModal={() => setOpenFeedback(false)}
      />
    </div>
  );
};

export default MyBooking;
