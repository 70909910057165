import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { onChangeForgotPassword } from "../../redux/homeAction";

const ChangePassword = ({ onCloseModal, open }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (!values?.password) {
      toast.error("Enter Password");
    } else if (!strongRegex.test(values?.password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else if (!values?.confirmPassword) {
      toast.error("Enter Confirm Password");
    } else if (values?.password !== values?.confirmPassword) {
      toast.error("Password and confirm password should be match");
    } else {
      const data = {
        password: values?.password,
        email: localStorage.getItem("registerEmail"),
      };
      dispatch(onChangeForgotPassword(data));
    }
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      classNames={{ modal: "login-popup-modal" }}
    >
      <div className="inner-container-login">
        <div className="title">
          <h2 className="title-text">Change Password</h2>
        </div>
        <div className="content">
          <div className="changePassword-wrapper">
            <div className="form">
              <form onSubmit={handleChangePassword}>
                <div className="form-element-group password-input">
                  <label htmlFor="password">Password</label>
                  <br />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="username"
                    name="password"
                    value={values?.password}
                    onChange={onInputChange}
                  />
                  {showPassword === false ? (
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={() => setShowPassword(true)}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  )}
                </div>
                <div className="form-element-group password-input">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <br />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="username"
                    name="confirmPassword"
                    value={values?.confirmPassword}
                    onChange={onInputChange}
                  />
                  {showConfirmPassword === false ? (
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={() => setShowConfirmPassword(true)}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      onClick={() => setShowConfirmPassword(false)}
                    ></i>
                  )}
                </div>
                <div className="form-element-group">
                  <button
                    type="submit"
                    className="submit-btn"
                    name="submit-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePassword;
