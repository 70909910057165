import React from "react";
import { useState } from "react";
import StripeCheckout from "react-stripe-checkout";

const YourOrder = ({
  handlePlaceOrder,
  getAllCardList,
  handleAddCard,
  selectPaymentCard,
  selectPaymentMethod,
  liveCartItemsList,
  appleCouponCode,
  onCouponChange,
  couponCode,
  appliedCoupon,
  fixedFreeShipping,
  setShippingPrice,
}) => {
  const [selected, setSelected] = useState("");
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));

  return (
    <div className="Yourorderwrap">
      <p>Your Order</p>
      <div className="subtotal-wrapp">
        <div className="products-tag">
          <span>Product</span>
          <span>Subtotal</span>
        </div>
        <div className="products-tagger">
          {liveCartItemsList &&
            liveCartItemsList.cart &&
            liveCartItemsList.cart.map((item, key) => (
              <div className="products-tagwrapper" key={key}>
                <div className="product_left">
                  <img src={item.featured_image} alt="" />
                  <span>{item.pname}</span>
                </div>
                <span className="product_leftPrice">
                  {item.qty} <i className="fa fa-remove"></i>{" "}
                  {item.currency_sign}
                  {item.s_price}
                </span>
              </div>
            ))}
        </div>

        <div className="products-tag">
          <span>Subtotal</span>
          <span>
            {currencyCode && currencyCode.sign
              ? currencyCode && currencyCode.sign
              : "$"}
            {liveCartItemsList && liveCartItemsList.subtotal}
          </span>
        </div>

        <div className="products-tag">
          <span>Shipping</span>
          <span>
            {currencyCode && currencyCode.sign
              ? currencyCode && currencyCode.sign
              : "$"}
            {liveCartItemsList && liveCartItemsList.shipping}
          </span>
        </div>

        {/* <div className="products-tagLine">
          <span className="shiphead">Shipping</span>
          <span>
            {currencyCode && currencyCode.sign
              ? currencyCode && currencyCode.sign
              : "$"}
            {liveCartItemsList && liveCartItemsList.shipping}
          </span>
        </div> */}
        <div className="products-tag">
          <span>Discount</span>
          <span>
            {currencyCode && currencyCode.sign
              ? currencyCode && currencyCode.sign
              : "$"}
            {(appliedCoupon && appliedCoupon.discount) || 0}
          </span>
        </div>
        <div className="carts-btn-wrapper">
          <span className="Applycoupon">Apply Coupon</span>
          <form className="apply-wrap" onSubmit={appleCouponCode}>
            <input
              type="text"
              placeholder="Coupon Code"
              value={couponCode}
              onChange={onCouponChange}
            />
            <button>
              <span className="loader-spinner" type="submit">
                {appliedCoupon && appliedCoupon.status === true
                  ? "Applied"
                  : "Apply Coupon"}
              </span>
            </button>
          </form>
        </div>
        {/* <div className="Shipping-tag">
          <span className="shiphead">Apply Gift Card </span>
          <div className="applyvoucherwrapper">
            <input type="text" placeholder="Gift Card Code" />
            <button>Apply</button>
          </div>
        </div> */}

        <div className="payments-wrappe">
          <div className="Total-tag">
            <span>Total</span>
            <label>
              {currencyCode && currencyCode.sign
                ? currencyCode && currencyCode.sign
                : "$"}
              {appliedCoupon && appliedCoupon.status === true
                ? appliedCoupon && appliedCoupon.total_amount
                : liveCartItemsList && liveCartItemsList.total}
            </label>
          </div>
          <div className="accordion" id="myAccordion">
            <div className="#">
              <div
                className="accordion-header"
                id="headingOne"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                >
                  <label className="paymenttags">
                    <input
                      type="radio"
                      name="stripe"
                      value="stripe"
                      onChange={(e) => selectPaymentMethod(e)}
                    />
                    <span>Stripe</span>
                  </label>
                </div>
                <div className="stripcardaddbtnwrpp">
                  <StripeCheckout
                    label="Add Card"
                    token={handleAddCard}
                    stripeKey="pk_live_51Ldat6Eh9lYQpdiHNGxm3DQ8SIUtJPYl2Y5WyrDG0NaxtgX13b1AgbumcBmBy4pewxtNlGZ96p6DhUk3Q3QvFrW900kPexmNKD"
                  >
                    <button className="stripe-btn">addcard</button>
                  </StripeCheckout>
                </div>
              </div>

              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#myAccordion"
              >
                <div className="comment-wrapper">
                  <div className="property-down-aroww">
                    <i className="fas fa-sort-down"></i>
                  </div>

                  <div className="strippayments-wrapper">
                    <div className="cardnamestrip-wrapp">
                      {getAllCardList &&
                        getAllCardList.map((data, key) => (
                          <div className="card-details-wrapper" key={key}>
                            <label>
                              <div className="mb-3">
                                <input
                                  className="me-2"
                                  type="radio"
                                  id="card"
                                  name="cards"
                                  onChange={() => selectPaymentCard(data)}
                                />
                                <img
                                  src="/img/cards.png"
                                  alt="#"
                                  className="me-2"
                                />
                                ***** *****
                                <span>{data?.last4}</span>
                              </div>
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button className="place-btn" onClick={(e) => handlePlaceOrder(e)}>
        Place Order
      </button>
    </div>
  );
};

export default YourOrder;
