import React from "react";
import { Link } from "react-router-dom";
import "../cartWelcomeView/cartWelcome.scss";

const OrderEmpty = () => {
  return (
    <>
      <section
        className="welcome-cartbg-wrappp2"
        style={{ background: "transparent" }}
      >
        <div className="container2">
          <div className="welcome-screenWrapper2">
            <h4 className="no-productwrapp2">
              You have not placed any order yet{" "}
            </h4>
            <p
              className="no-producttext-wrapp2"
              style={{ marginBottom: "20px" }}
            >
              Your placed order will show here, so place your order now and
              track your order from shipped to delivered
            </p>
            <Link to="/products">
              <button
                className="shopp-btn-incart2"
                style={{ padding: "10px 20px", fontSize: "14px" }}
              >
                Place Order
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderEmpty;
