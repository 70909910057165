import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./otpPopups.scss";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  onResendOtp,
  onVerifyOtp,
  verifyOtpEmail,
} from "../../redux/homeAction";
import { toast } from "react-toastify";

const OtpPopups = ({ otpPopup, closeOtpPopup }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState();

  const handleOtp = (value) => {
    setOtp(value);
  };

  const onOtpConfirm = (e) => {
    e.preventDefault();

    if (!otp) {
      toast.error("Please Enter Otp");
    } else {
      const data = {
        email: localStorage.getItem("registerEmail"),
        otp: otp,
      };
      dispatch(verifyOtpEmail(data));
    }
  };

  const handleResendOtp = () => {
    const data = {
      email: localStorage.getItem("registerEmail"),
    };

    dispatch(onResendOtp(data));
  };

  return (
    <>
      <Modal
        open={otpPopup}
        onClose={closeOtpPopup}
        classNames={{ modal: "otppopup-modal" }}
      >
        <div className="pin-wrapper">
          <p className="otp_message_heading">OTP Verification</p>
          <OtpInput value={otp} onChange={handleOtp} numInputs={4} />
          <button
            type="submit-btn"
            className="submit-btn1"
            onClick={onOtpConfirm}
          >
            CONFIRM
          </button>
          <p className="text-center mt-2 otp_message_p">
            Didn't get code?
            <Link to="#" className="ml-1" onClick={handleResendOtp}>
              Resend Code
            </Link>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default OtpPopups;
