import React, { useEffect, useState } from "react";
import "./orderTrack.scss";
import Loader from "../../../Home/loader/loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { orderTrack } from "../../../../redux/homeAction";

const OrderTrack = ({ getOrderHistory }) => {
  const dispatch = useDispatch();
  const [orderTrackDetails, setOrderTrackDetails] = useState();
  const [singleOrder, setSingleOrder] = useState();
  const singleOrderItem = singleOrder && singleOrder.order_item;
  const getOrderTracking = useSelector((state) => state.home.getOrderTracking);

  useEffect(() => {
    if (getOrderHistory) {
      for (let i = 0; i < getOrderHistory.length; i++) {
        if (getOrderHistory[i].id == localStorage.getItem("orderViewId")) {
          setSingleOrder(getOrderHistory[i]);
        }
      }
    }
  }, [getOrderHistory]);

  useEffect(() => {
    if (singleOrderItem) {
      for (let j = 0; j < singleOrderItem.length; j++) {
        if (singleOrderItem[j].id == localStorage.getItem("orderTrackId")) {
          setOrderTrackDetails(singleOrderItem[j]);
        }
      }
    }
  }, [singleOrderItem]);

  useEffect(() => {
    if (orderTrackDetails) {
      const data = {
        orderid: orderTrackDetails && orderTrackDetails.order_id,
        productid: orderTrackDetails && orderTrackDetails.product_id,
      };
      dispatch(orderTrack(data));
    }
  }, [orderTrackDetails]);

  return (
    <>
      {getOrderTracking && getOrderTracking.length === 0 ? (
        <Loader />
      ) : (
        <div className="Order-trackWrapper">
          <div className="orderimg-wrapper">
            <div className="product-imgwrapper">
              <img
                className="thumbnail"
                src="http://smileszy.eoxyslive.com/dashboard/products/feature/1656677991.png"
                alt=""
              />
            </div>
            <div className="ordertrackedpoinwrap">
              {getOrderTracking &&
                getOrderTracking.map((data, key) => (
                  <div
                    key={key}
                    className={
                      data.completed
                        ? "order-wrapperpointfirst"
                        : "order-wrapperpointsecond"
                    }
                  >
                    <div className="orderfirstpoint-wrapp">
                      <span className="order-tagwrapp">{data.title}</span>
                      <small className="order-subdetails-line">
                        {data.date}
                      </small>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="order-summary-details">
            <div className="order-detailswrap">
              <p className="shipment-heading">Shipment Information</p>
              <ul className="shipment-info-wrapper">
                <li className="shipment-detaisline">
                  <span className="ordertrackdate">
                    {moment(
                      orderTrackDetails && orderTrackDetails.created_at
                    ).format("DD MMM YYYY")}
                  </span>
                  <span className="ordertrack-viewbtn">View Order</span>
                </li>
                <li className="shipment-detaisline">
                  <span className="infotxt-wrapp">Delivery Method</span>
                  <span className="infodetalstxt-wrapp">
                    {singleOrder && singleOrder.shipping_method}
                  </span>
                </li>
                <li className="shipment-detaisline">
                  <span className="infotxt-wrapp">Order</span>
                  <span className="infodetalstxt-wrapp">
                    {singleOrder &&
                      singleOrder.order_meta &&
                      singleOrder.order_meta.currency_sign}
                    {orderTrackDetails && orderTrackDetails.product_price}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderTrack;
