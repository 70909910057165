import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Login from "../../popups/Login/Login";
import {
  deleteFromCart,
  onUpdateCartQty,
  setLiveCartItems,
  setLocalCartItems,
} from "../../redux/homeAction";
import "./cart.scss";
import CartWelcome from "./cartWelcomeView/CartWelcome";
import CartWrapper from "./CartWrapper";
import Loader from "../Home/loader/loader";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loginOpen, setloginOpen] = useState(false);
  const [couponCode, setCouponCode] = useState();
  let localCartItems = useSelector((state) => state.home.localCartItems);
  let liveCartItems = useSelector((state) => state.home.liveCartItems);
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      console.log("Working");
    } else {
      if (localCartItems) {
        localCartItems = localCartItems.map((p) => ({
          ...p,
          currency_sign:
            currencyCode && currencyCode.sign
              ? currencyCode && currencyCode.sign
              : "$",
        }));

        dispatch(setLocalCartItems(localCartItems));
      }
    }
  }, []);

  const deleteCart = (id) => {
    if (localStorage.getItem("user-token")) {
      const data = {
        product_id: id,
      };
      dispatch(deleteFromCart(data));
    } else {
      toast.success("Removed Successfully");
      if (localCartItems) {
        localCartItems = localCartItems.filter((p) => p.id !== id);
      }

      dispatch(setLocalCartItems(localCartItems));
    }
  };

  const productHandler = (slug) => {
    navigate(`/product/${slug}`);
  };

  const addQty = (item) => {
    if (localStorage.getItem("user-token")) {
      if (parseInt(item.qty) < parseInt(item.in_stock)) {
        liveCartItems = liveCartItems.map((p) =>
          p.id === item.id
            ? {
                ...p,
                qty: parseInt(p.qty) + 1,
              }
            : p
        );
        dispatch(setLiveCartItems(liveCartItems));
        if (liveCartItems) {
          liveCartItems = liveCartItems.map((p) =>
            p.id === item.id
              ? {
                  ...p,
                  qty: dispatch(
                    onUpdateCartQty({
                      product_id: p.id,
                      qty: parseInt(p.qty),
                    })
                  ),
                }
              : p
          );
        }
      } else {
        toast.error("Out of stock");
      }
    } else {
      if (item.qty < item.in_stock) {
        localCartItems = localCartItems.map((p) =>
          p.id === item.id
            ? {
                ...p,
                qty: p.qty + 1,
              }
            : p
        );
        dispatch(setLocalCartItems(localCartItems));
      } else {
        toast.error("Out of stock");
      }
    }
  };

  const removeQty = (item) => {
    if (localStorage.getItem("user-token")) {
      if (item.qty > 1) {
        liveCartItems = liveCartItems.map((p) =>
          p.id === item.id
            ? {
                ...p,
                qty: p.qty - 1,
              }
            : p
        );
        dispatch(setLiveCartItems(liveCartItems));
        if (liveCartItems) {
          liveCartItems = liveCartItems.map((p) =>
            p.id === item.id
              ? {
                  ...p,
                  qty: dispatch(
                    onUpdateCartQty({
                      product_id: p.id,
                      qty: p.qty,
                    })
                  ),
                }
              : p
          );
        }
      }
    } else {
      // if (item.qty === 1) {
      //   if (localCartItems) {
      //     localCartItems = localCartItems.filter((p) => p.id !== item.id);
      //   }

      //   dispatch(setLocalCartItems(localCartItems));
      //   return;
      // }
      if (item.qty > 1) {
        localCartItems = localCartItems.map((p) =>
          p.id === item.id
            ? {
                ...p,
                qty: p.qty - 1,
              }
            : p
        );
        dispatch(setLocalCartItems(localCartItems));
      }
    }
  };

  const calculateSubTotal = () => {
    if (localStorage.getItem("user-token")) {
      let subTotal = 0;
      liveCartItems &&
        liveCartItems.forEach((p) => {
          let productTotal = p.qty * p.s_price;
          subTotal = subTotal + productTotal;
        });

      return subTotal;
    } else {
      let subTotal = 0;
      localCartItems &&
        localCartItems.forEach((p) => {
          let productTotal = p.qty * p.s_price;
          subTotal = subTotal + productTotal;
        });

      return subTotal;
    }
  };

  // const calculateDiscount = () => {
  //   if (localStorage.getItem("user-token")) {
  //     let discountTotal = 0;

  //     liveCartItems &&
  //       liveCartItems.forEach((p) => {
  //         let discount = p.p_price - p.s_price;
  //         let productDiscount = p.qty * discount;
  //         discountTotal = discountTotal + productDiscount;
  //       });

  //     return discountTotal;
  //   } else {
  //     let discountTotal = 0;

  //     localCartItems &&
  //       localCartItems.forEach((p) => {
  //         let discount = p.p_price - p.s_price;
  //         let productDiscount = p.qty * discount;
  //         discountTotal = discountTotal + productDiscount;
  //       });

  //     return discountTotal;
  //   }
  // };

  const calculateGrandTotal = () => {
    if (localStorage.getItem("user-token")) {
      let grandTotal = 0;

      liveCartItems &&
        liveCartItems.forEach((p) => {
          let productTotal = p.qty * p.s_price;
          grandTotal = grandTotal + productTotal;
        });

      return grandTotal;
    } else {
      let grandTotal = 0;

      localCartItems &&
        localCartItems.forEach((p) => {
          let productTotal = p.qty * p.s_price;
          grandTotal = grandTotal + productTotal;
        });

      return grandTotal;
    }
  };

  const proceedToCheckout = () => {
    navigate("/checkout");
  };

  const appleCouponCode = (e) => {
    e.preventDefault();

    if (!couponCode) {
      toast.error("Enter Your Code");
    } else {
      toast.success("Working");
    }
  };

  const onCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  return (
    <>
      <>
        {loader ? (
          <Loader />
        ) : (
          <>
            {localStorage.getItem("user-token") ? (
              <>
                {liveCartItems?.length ? (
                  <div className="cart-bg">
                    <div className="container">
                      <div className="cartwrapper">
                        <div className="carts-bg">
                          <div className="product-topline">
                            <span className="producttag">
                              <b>Product</b>
                            </span>
                            <span className="price-tag" id="price-mobiview">
                              <b>Price</b>
                            </span>
                            <span className="price-tag">
                              <b>Quantity</b>
                            </span>
                            <span className="price-tag" id="price-mobiview">
                              <b>Subtotal</b>
                            </span>
                          </div>
                          {liveCartItems &&
                            liveCartItems.map((item, key) => (
                              <CartWrapper
                                cartItems={item}
                                deleteCart={deleteCart}
                                productHandler={productHandler}
                                removeQty={removeQty}
                                addQty={addQty}
                                key={key}
                              />
                            ))}

                          {/* <div className="carts-btn-wrapper"> */}
                          {/* <form
                              className="apply-wrap"
                              onSubmit={appleCouponCode}
                            >
                              <input
                                type="text"
                                placeholder="Coupon Code"
                                value={couponCode}
                                onChange={onCouponChange}
                              />
                              <button>
                                <span className="loader-spinner">
                                  <i className="fa-solid fa-spinner loading-gif"></i>
                                </span>
                                <span className="loader-spinner" type="submit">
                                  Apply Coupon
                                </span>
                              </button>
                            </form> */}
                          {/* <button className="update-cart-btn">
                              <span className="loader-spinner">
                                <i className="fa-solid fa-spinner loading-gif"></i>
                              </span>
                              <span className="loader-spinner">
                                Update Cart
                              </span>
                            </button> */}
                          {/* </div> */}
                        </div>
                        <div className="cart-details">
                          <p className="cart-total">Cart Total</p>
                          <p className="subtotals">
                            <span>Sub Total</span>
                            <label>
                              {currencyCode && currencyCode.sign
                                ? currencyCode && currencyCode.sign
                                : "$"}
                              {calculateSubTotal()}
                            </label>
                          </p>
                          {/* <p className="subtotals">
                            <span>Discount</span>
                            <label>${calculateDiscount()}</label>
                          </p> */}
                          <div className="total-wrap">
                            <span>
                              <b>Total</b>
                            </span>
                            <span className="totalAmount">
                              {currencyCode && currencyCode.sign
                                ? currencyCode && currencyCode.sign
                                : "$"}
                              {calculateGrandTotal()}
                            </span>
                          </div>

                          <button
                            className="proceed-btn"
                            onClick={() => proceedToCheckout()}
                          >
                            <span className="loader-spinner">
                              Proceed to Checkout
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <CartWelcome />
                )}
              </>
            ) : (
              <>
                {localCartItems?.length ? (
                  <div className="cart-bg">
                    <div className="container">
                      <div className="cartwrapper">
                        <div className="carts-bg">
                          <div className="product-topline">
                            <span className="producttag">
                              <b>Product</b>
                            </span>
                            <span className="price-tag" id="price-mobiview">
                              <b>Price</b>
                            </span>
                            <span className="price-tag">
                              <b>Quantity</b>
                            </span>
                            <span className="price-tag" id="price-mobiview">
                              <b>Subtotal</b>
                            </span>
                          </div>
                          {localCartItems &&
                            localCartItems.map((item, key) => (
                              <CartWrapper
                                cartItems={item}
                                deleteCart={deleteCart}
                                productHandler={productHandler}
                                removeQty={removeQty}
                                addQty={addQty}
                                key={key}
                              />
                            ))}
                        </div>
                        <div className="cart-details">
                          <p className="cart-total">Cart Total</p>
                          <p className="subtotals">
                            <span>Sub Total</span>
                            <label>
                              {currencyCode && currencyCode.sign
                                ? currencyCode && currencyCode.sign
                                : "$"}
                              {calculateSubTotal()}
                            </label>
                          </p>
                          {/* <p className="subtotals">
                            <span>Discount</span>
                            <label>${calculateDiscount()}</label>
                          </p> */}

                          <div className="total-wrap">
                            <span>
                              <b>Total</b>
                            </span>
                            <span className="totalAmount">
                              {currencyCode && currencyCode.sign
                                ? currencyCode && currencyCode.sign
                                : "$"}
                              {calculateGrandTotal()}
                            </span>
                          </div>
                          <button
                            className="proceed-btn"
                            onClick={() => setloginOpen(true)}
                          >
                            <span className="loader-spinner">
                              Proceed to Checkout
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <CartWelcome />
                )}
              </>
            )}
          </>
        )}
      </>
      <Login open={loginOpen} onCloseModal={() => setloginOpen(false)} />
    </>
  );
};

export default Cart;
