import React from "react";
import "./shopDetailsHeader.scss";
import DefaultImg from "../../../../images/background.png";

const ShopDetailsHeader = ({ getSingleStoresList }) => {
  return (
    <>
      <div className="Shopheader-wrapper">
        <div className="shops-img-warpper">
          <img
            src={
              getSingleStoresList && getSingleStoresList.banner_img
                ? getSingleStoresList && getSingleStoresList.banner_img
                : DefaultImg
            }
            alt=""
          />
        </div>
        <div className="Shopheader-Addres-wrapper">
          <div className="shopaddres-wrapepr">
            <h2 className="shop-haddressline">
              {getSingleStoresList && getSingleStoresList.store_name}
            </h2>
            <p className="feedbackline">100% positive Feedback</p>
            <span className="shopopen-tag">
              <small className="opendot"></small> Open
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDetailsHeader;
