import React, { useState } from "react";
import "./testimonial.scss";
import { Link } from "react-router-dom";

const Testimonial = ({ categoriesList }) => {
  const [viewCategory, setViewCategory] = useState(9);

  return (
    <>
      <div className="container-fluid testimonial-bg">
        <div className="container">
          <div className="row">
            <h2 className="category-tag">category</h2>
            <div className="col-xl-12 " id="testimonial-items">
              <div className="testimonial-items-wrapper">
                <div className="category-wrapper">
                  {categoriesList &&
                    categoriesList
                      .filter((item, index) => index < viewCategory)
                      .map((item, index) => (
                        <div className="categorytab-wrapp" key={index}>
                          <Link to={`/category/${item.slug}`}>
                            <div className="test-items-wrap">
                              <div className="testCatigory-imgs">
                                <img
                                  src={item.category_image}
                                  className="img-fluid"
                                  alt=""
                                ></img>
                              </div>
                              <div className="productnameswrapper">
                                <span className="products-Name">
                                  {item.title}
                                </span>
                                <span className="items-quantety">
                                  {item.count} Items
                                </span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  {categoriesList &&
                  categoriesList.length < viewCategory ? null : (
                    <div className="categorytab-wrapp viewmore-bg">
                      <div className="test-items-wrap">
                        <div className="productnameswrapper">
                          <span
                            className="viewmorebtn"
                            onClick={() => setViewCategory(viewCategory + 9)}
                          >
                            view more
                            <i className="fas fa-arrow-right ms-2"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
