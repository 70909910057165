import React, { useState, useEffect } from "react";
import "./serviceDetails.scss";
import Calender from "../../../popups/Calendar/Calender";

const ServiceDetails = () => {
  return (
    <>
      <Calender />
      <div className="ServiceDetailsbg-wrapper">
        <div className="container">
          <div className="Shopheader-wrapper">
            <div className="shops-img-warpper">
              <img src="" alt="" />
            </div>
            <div className="Shopheader-Addres-wrapper">
              <div className="shopaddres-wrapepr">
                <h2 className="shop-haddressline">productDetails.pname</h2>
                <span className="shopopen-tag">
                  <small className="opendot"></small> Open
                </span>
              </div>
            </div>
          </div>
          <div className="services-hading-wrapper">
            <div className="services-hadingwrapp">
              <div>
                <h6 className="services-hadingwraper">productDetails.pname</h6>
              </div>
              <button className="shop-booknow-btn">Book Now</button>
            </div>
            <div>
              <b>$productDetails.p_price</b>
            </div>
          </div>
          <div className="servises-point-wrapper">
            <ul>
              <li>Men's Haircut</li>
              <li>Beard Shape&Style</li>
              <li>10 min Head Massage</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
