import React from "react";

const BillingDetails = ({
  addressValues,
  onAddressChange,
  getCountryList,
  addressCountryChange,
  getStateList,
  addressStateChange,
  getCityList,
  addressCityChange,
}) => {
  return (
    <div className="form-wrapper">
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="First name"
        name="first_name"
        value={addressValues?.first_name}
        onChange={(e) => onAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Last name"
        name="last_name"
        value={addressValues?.last_name}
        onChange={(e) => onAddressChange(e)}
      />
      <div class="select-wrapper">
        <select
          name="country"
          value={addressValues?.country}
          className="form-wrapperInput"
          onChange={(e) => addressCountryChange(e)}
        >
          <option>Select Country</option>
          {getCountryList &&
            getCountryList.map((item, key) => (
              <option value={item.name} key={key}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
      <div class="select-wrapper">
        <select
          className="form-wrapperInput"
          onChange={(e) => addressStateChange(e)}
          name="state"
          value={addressValues?.state}
        >
          <option>Select State</option>
          {getStateList &&
            getStateList.map((item, key) => (
              <option key={key} value={item.state_name}>
                {item.state_name}
              </option>
            ))}
        </select>
      </div>
      <div class="select-wrapper">
        <select
          className="form-wrapperInput"
          onChange={(e) => addressCityChange(e)}
          name="city"
          value={addressValues?.city}
        >
          <option>Select City</option>
          {getCityList &&
            getCityList.map((item, key) => (
              <option key={key} value={item.city_name}>
                {item.city_name}
              </option>
            ))}
        </select>
      </div>
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Street address"
        name="address"
        value={addressValues?.address}
        onChange={(e) => onAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Area,Colony,Street"
        name="address2"
        value={addressValues?.address2}
        onChange={(e) => onAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Landmark"
        name="landmark"
        value={addressValues?.landmark}
        onChange={(e) => onAddressChange(e)}
      />

      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Postcode"
        name="zip_code"
        value={addressValues?.zip_code}
        onChange={(e) => onAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Phone"
        name="phone"
        value={addressValues?.phone}
        onChange={(e) => onAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Alternate Number"
        name="alternate_phone"
        value={addressValues?.alternate_phone}
        onChange={(e) => onAddressChange(e)}
      />
      <select
        className="form-wrapperInput"
        name="address_type"
        value={addressValues?.address_type}
        onChange={(e) => onAddressChange(e)}
      >
        <option>Select Address Type</option>
        <option value="billing">Billing</option>
        <option value="shipping">Shipping</option>
      </select>
    </div>
  );
};

export default BillingDetails;
