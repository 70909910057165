import React, { useState } from "react";
import "./myWallet.scss";
import AddBalance from "../../../../popups/addBalance/AddBalance";

const Mywallet = ({ getWalletTransection }) => {
  const [addBalanceOpen, setAddBalanceOpen] = useState(false);
  const openAddblanceModal = () => setAddBalanceOpen(true);
  const closeAddblanceModal = () => setAddBalanceOpen(false);

  return (
    <>
      <AddBalance open={addBalanceOpen} onCloseModal={closeAddblanceModal} />
      <div className="mywallet-tabWrapper">
        <div className="mywalletheadeer-wrapper">
          <div className="Walletamouuntwrapp">
            <span>
              <b>Current balance</b>
            </span>
            <span>
              ${(getWalletTransection && getWalletTransection.wallet) || 0}
            </span>
          </div>
          <button className="addMoneybtn" onClick={openAddblanceModal}>
            ADD MONEY
          </button>
        </div>
        {getWalletTransection &&
          getWalletTransection.data &&
          getWalletTransection.data.map((item, key) => (
            <div className="transection-linewrapper" key={key}>
              <i className="fas fa-arrow-circle-down paid-arows"></i>

              <div className="shop-transectiondetils">
                <div className="transection-shop-wrapper">
                  <span className="paymentrecevid">{item.title}</span>
                  <span className="shopname">{item.description}</span>
                </div>
                <div className="transection-amount">
                  <span className="craditamount">
                    ${parseInt(item.amount).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          ))}

        {/* <div className="transection-linewrapper">
          
          <i className="fas fa-arrow-circle-down arows"></i>
          <div className="shop-transectiondetils">
            <div className="transection-shop-wrapper">
              <span className="paymentrecevid">Received From</span>
              <span className="shopname">Myntra Online Shop</span>
            </div>
            <div className="transection-amount">
              <span className="craditamount">$16.00</span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Mywallet;
