import React, { useEffect } from "react";
import { getPagesData } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Home/loader/loader";

const ReturnandRefund = () => {
  const dispatch = useDispatch();
  const returnAndRefunds = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 14,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Return and Refund Page</h1>
        </div>
      </div>
      <div className="container">
        <div className="terms-page-wrapper">
          {returnAndRefunds ? (
            <div
              dangerouslySetInnerHTML={{
                __html: returnAndRefunds && returnAndRefunds.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
          {/* <h4>
            Returns and cancellations for sellers/ Smileszy Money Back Guarantee
            Policy{" "}
          </h4>

          <p className="mt-4">
            Smileszy, or the Smileszy payments entities may collect the cost of
            the return shipping label from sellers when:
          </p>
          <p>
            Under specific conditions, sellers can design rules to automate
            replacements, returns, and refunds.
          </p>
          <p>
            Smileszy will implement a default rule that automates the return
            procedure for all new sellers in listings where returns are
            accepted.
          </p>
          <p>
            Sellers can delete or change their return selections in My
            Smileszy's account settings.
          </p>
          <p>
            If your settings are set to automatically accept requests for
            returns or replacements, your customer will receive a
            Smileszy-generated return shipping label.
          </p>
          <p>
            You agree to follow our <b>return policy.</b>
          </p>
          <p>
            When an item is returned, you (as seller) allow Smileszy or Smileszy
            payments companies to collect the amount of the reimbursement from
            you in accordance with the Payments Terms of Use.
          </p>
          <p>
            If the transaction funds are deposited into your PayPal account You
            also enable Smileszy to request that PayPal deduct the amount of the
            refund (in the same or an other currency) from your PayPal account.
          </p>
          <p>
            Smileszy or the Smileszy payment entities may charge sellers for the
            cost of the return shipping label if:
          </p>
          <ul>
            <li class="lh-lg">
              An Smileszy-generated return shipping label is used, and the
              seller is responsible for its cost;
            </li>
            <li class="lh-lg">Your returns have been automated;</li>
            <li class="lh-lg">
              You fail to send your buyer a return shipping label and, instead,
              an Smileszy-generated shipping label is used; and/or
            </li>
            <li class="lh-lg">
              Item is no t as described in the listing and is returned in
              accordance with Smileszy Money Back Guarantee.
            </li>
          </ul>
          <p class="mt-3">
            You may be charged for the cost of return shipping labels.
          </p>
          <p>
            If you receive sales funds to your PayPal account, you enable
            Smileszy to request that the cost of the shipping label be deducted
            from your PayPal account (in same or other currency). All seller
            cancellations of orders must be in accordance with our Order
            cancellation policy.
          </p>
          <p>
            If a transaction is cancelled, Smileszy may issue a refund to the
            buyer on your behalf. If a refund is issued to a buyer, you (as
            seller) authorize Smileszy or Smileszy payments entities to collect
            the amount of the refund from you. If you receive your transaction
            proceeds to your PayPal account, you also authorize Smileszy to
            request that PayPal remove the refund amount (in same or other
            currency) from your PayPal account.
          </p>

          <h4>Cancellations for buyers</h4>
          <p>
            In most cases, buyers do not have the ability to cancel a purchase.
          </p>
          <p>
            Buyers on Smileszy can cancel an order within one hour after
            purchase or commitment, and the seller has three days to accept or
            refuse the request.
          </p>
          <p>
            When the order cannot be cancelled, purchasers may still be able to
            return the item if it is returnable.
          </p>
          <p>
            Order cancellations submitted by buyers must follow our Order
            cancellation policy.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default ReturnandRefund;

{
  /* <h3>Return and Refund Policy</h3>
<p>Last updated: June 14, 2022</p>
<p>Thank you for shopping at</p>
<p>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns. This Return and Refund Policy has been created with the help of the</p>
<p>The following terms are applicable for any products that You purchased with Us.</p>
<h3>Interpretation and Definitions</h3>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h2>Definitions</h2>
<p>For the purposes of this Return and Refund Policy:</p>
<ul>
<li>
<p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to&nbsp;</p>
</li>
<li>
<p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
</li>
<li>
<p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Website.</p>
</li>
<li>
<p><strong>Website</strong> refers to, accessible from</p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<h3>Your Order Cancellation Rights</h3>
<p>You are entitled to cancel Your Order within 14 days without giving any reason for doing so.</p>
<p>The deadline for cancelling an Order is 14 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</p>
<p>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</p>
<ul>
<li>
<p>By email:</p>
</li>
<li>
<p>By visiting this page on our website:</p>
</li>
<li>
<p><br></p>
</li>
</ul>
<p>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</p>
<h3>Conditions for Returns</h3>
<p>In order for the Goods to be eligible for a return, please make sure that:</p>
<ul>
<li>The Goods were purchased in the last 14 days</li>
<li>The Goods are in the original packaging</li>
</ul>
<p>The following Goods cannot be returned:</p>
<ul>
<li>The supply of Goods made to Your specifications or clearly personalized.</li>
<li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
<li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
<li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
</ul>
<p>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</p>
<p>Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</p>
<h3>Returning Goods</h3>
<p>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</p>
<p>contect us by email</p>
<p>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</p>
<h1>Gifts</h1>
<p>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</p>
<p>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</p>
<h2>Contact Us</h2>
<p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
<ul>
<li>
<p>By email:&nbsp;</p>
</li>
<li>
<p>By visiting this page on our website:&nbsp;</p></li>
</ul><script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script> */
}
