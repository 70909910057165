import React from "react";
import SpacialOffers from "../spacialOffers/SpacialOffers";
import "./theDayValue.scss";

function TheDayValue2() {
  return (
    <>
      <SpacialOffers />
    </>
  );
}

export default TheDayValue2;
