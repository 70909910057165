import React from "react";
import { Link } from "react-router-dom";
import "./Bestfees.scss";

const BestFees = () => {
  return (
    <div className="bestFee-bg">
      <div className="container">
        <div className="bestfee-wrapper">
          <div className="img-wrap">
            <img src="/img/open.png" alt="#" />
          </div>
          <div className="best-text-wrapper">
            <p className="best-fee-line">BEST FEES TO START</p>
            <p className="Affordable-line">
              Affordable, Transparent, and Secure
            </p>
            <p className="it-dose-line">
              It doesn’t cost a thing to list up your products, and you only pay
              after your stuff sells. It’s just a small percent of the money you
              earn.
            </p>
            <div className="tools-wrappper">
              <div className="tool-wrap">
                <img src="/img/Powerfull.png" alt="#" />
                <span>Powerful Tools</span>
              </div>
              <div className="toolSupport-wrap">
                <img src="/img/Support.png" alt="#" />
                <span>24/7 Support</span>
              </div>
              <div className="Lowfees-wrap">
                <img src="/img/Lowfees.png" alt="#" />
                <span> Low Fees</span>
              </div>
            </div>
            <Link to="/vendor-registration" rel="noopener noreferrer">
              <button className="Sell-withbtn slide-in-bottom">
                Sell With us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestFees;
