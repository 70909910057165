import React from "react";
import LoaderImage from "../../../images/yellowloader.gif";
import "./loader.css";

const Loader = () => {
  return (
    <div>
      <div className="loading-wrapper">
        <img className="loading-gif" src={LoaderImage} alt="#" />
      </div>
    </div>
  );
};
export default Loader;
