import React, { useState } from "react";
import ShopSidebar from "../../shopSidebar/ShopSidebar";
import "./shopheader.scss";

const ShopHeader = ({
  onFilterChange,
  categoriesList,
  openCategory,
  closeCategory,
  categoryId,
}) => {
  const [openMobileFilter, setOpenMobileFilter] = useState(false);

  return (
    <>
      <div className="shopHeader-wrapper">
        <div className="Shop-items-headers">
          <div className="filters-wrapp">
            <div className="shops-viewicon"></div>
            <div className="Filter-mobile-res-wrapper">
              <i className="fas fa-filter me-2" />
              <span onClick={() => setOpenMobileFilter(true)}>Filter</span>
            </div>
            {openMobileFilter && (
              <ShopSidebar
                setOpenMobileFilter={setOpenMobileFilter}
                categoriesList={categoriesList}
                openCategory={openCategory}
                closeCategory={closeCategory}
                categoryId={categoryId}
              />
            )}
          </div>
          <div className="Shorts-wrapper">
            <div className="short-by-filter">
              <select onChange={onFilterChange}>
                <option value="top_rated">Sort by latest</option>
                <option value="recently_added">Sort By Popularity</option>
                <option value="price_low_to_high">Sort by low to high</option>
                <option value="price_high_to_low">Sort by high to low</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopHeader;
