import React, { useState } from "react";
import "./Newproduct.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import Login from "../../popups/Login/Login";

const NewProduct = ({
  homeProductList,
  getWishList,
  handleAddToWishList,
  handleRemoveFromWishList,
  addToCartHandler,
  currentId,
}) => {
  const navigate = useNavigate();
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  return (
    <>
      <div className="bestSeller-wrapp">
        <div className=" bestSells-wrappenewproduct">
          <div className="topline-wrapper">
            <label className="BestSellerstag">Today's Deals</label>
            <Link to="/today's-deals">
              <button className="viewallbtn">
                View All
                <i className="fas fa-arrow-right ms-2" />
              </button>
            </Link>
          </div>
          <section className="web-responsive">
            <div className="productitems-wraper">
              <Slider {...settings}>
                {homeProductList &&
                  homeProductList.new &&
                  homeProductList.new.map((data, index) => (
                    <div className="col-lg-3 pl-0 pr-0" key={index}>
                      <div className="product-wrapper">
                        <div className="hover-wish-list-wrappper">
                          <div className="wish-list-imgs">
                            {localStorage.getItem("user-token") ? (
                              <img
                                src={
                                  getWishList?.find(
                                    (item) => item?.id === data?.id
                                  )
                                    ? "/img/ha2.png"
                                    : "/img/ha.png"
                                }
                                alt={data?.pname}
                                className="img-fluid"
                                onClick={
                                  !getWishList?.find(
                                    (item) => item?.id == data?.id
                                  )
                                    ? () => handleAddToWishList(data?.id)
                                    : () =>
                                        handleRemoveFromWishList(
                                          getWishList?.find(
                                            (item) => item?.id == data?.id
                                          )
                                        )
                                }
                              />
                            ) : (
                              <img
                                src="/img/ha.png"
                                alt=""
                                className="img-fluid"
                                onClick={openLoginModal}
                              />
                            )}
                          </div>
                        </div>
                        <div className="product-imgs-wrap">
                          <img
                            src={data.featured_image}
                            alt=""
                            className="img-fluid"
                            onClick={() => showProduct(data.slug)}
                          />
                        </div>
                        <div className="items-content-wrappers">
                          <p
                            className="items-name"
                            onClick={() => showProduct(data.slug)}
                          >
                            {data.pname}
                          </p>
                          {data.in_stock == 0 ? (
                            <p className="instock" style={{ color: "red" }}>
                              Out Of Stock
                            </p>
                          ) : (
                            <p className="instock">In stock</p>
                          )}
                          {data.s_price ? (
                            <>
                              <del className="last-price">
                                {data.currency_sign}
                                {data.p_price}
                              </del>
                              <span className="latest-price">
                                {data.currency_sign}
                                {data.s_price}
                              </span>
                            </>
                          ) : (
                            <del className="last-price">
                              {data.currency_sign}
                              {data.p_price}
                            </del>
                          )}
                          {data.product_type === "single" ? (
                            <button
                              className="addto-cart-btn"
                              onClick={() => addToCartHandler(data)}
                            >
                              {currentId == data.id ? (
                                <span className="loader-spinner">
                                  <i className="fa-solid fa-spinner loading-gif"></i>
                                </span>
                              ) : (
                                <span className="loader-spinner">
                                  Add to cart
                                </span>
                              )}
                            </button>
                          ) : data.product_type === "bid" ? (
                            <button
                              className="addto-cart-btn"
                              onClick={() => showProduct(data.slug)}
                            >
                              Bid Now
                            </button>
                          ) : data.product_type === "booking" ? (
                            <button
                              className="addto-cart-btn"
                              onClick={() => showProduct(data.slug)}
                            >
                              Book Now
                            </button>
                          ) : data.product_type === "variants" ? (
                            <button
                              className="addto-cart-btn"
                              onClick={() => addToCartHandler(data)}
                            >
                              {currentId == data.id ? (
                                <span className="loader-spinner">
                                  <i className="fa-solid fa-spinner loading-gif"></i>
                                </span>
                              ) : (
                                <span className="loader-spinner">
                                  Add to cart
                                </span>
                              )}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </section>
        </div>
      </div>
      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default NewProduct;
