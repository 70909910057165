import React from "react";
import "./addBalance.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { addWalletAmount } from "../../redux/homeAction";
import { toast } from "react-toastify";

const AddBalance = ({ open, onCloseModal }) => {
  const dispatch = useDispatch();
  const [cardCode, setCardCode] = useState();

  const submitWalletAmount = () => {
    const data = {
      type: "giftcard",
      giftcardcode: cardCode,
    };

    if (!cardCode) {
      toast.error("Enter Your Code");
    } else {
      dispatch(addWalletAmount(data, onCloseModal));
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{ modal: "acordion" }}
      >
        <div className="AddMonyWrapper">
          <div className="addresinputwrapp">
            <p className="addmonyheading">Add Money to wallet</p>
            <input
              type="text"
              className="addamountinput"
              value={cardCode}
              onChange={(e) => setCardCode(e.target.value)}
              placeholder="Enter Your Gift Card Code"
            />
            {/* <small className="selectoptionline">
              Please select an option to add money
            </small> */}
          </div>

          <div className="selectboxwrapper">
            {/* <div className="dabitcardselectwrapp">
              <label>
                <input type="radio" name="dabit" value="debit/cradit" />
                <span>Debit/Credit Cards</span>
              </label>
            </div> */}
            <div className="sendbtnwrappp" onClick={submitWalletAmount}>
              <button className="sendbtn">Add</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddBalance;
