import React from "react";
import RecentOrder from "./Deshbord-recent-order/RecentOrder";
import "./Deshbordpage.scss";
import OrderEmpty from "../../../Cart/orderEmptyView/OrderEmpty";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Deshbordpage = ({ handleLogout, getOrderHistory, setCurrentTab }) => {
  const navigate = useNavigate();

  const calculateTotal = (orderItem) => {
    var totalPrice = null;

    orderItem &&
      orderItem.forEach(function (value, index, arry) {
        totalPrice += parseInt(value.product_price);
      });

    return totalPrice;
  };

  return (
    <>
      <section className="Deshbord-page-wrapper">
        <div className="welcome-wrapper">
          <div className="walcome-status-wrapper">
            <p className="welcome-backline">WELCOME BACK</p>
            <p className="my-recent-order-line"> MY RECENT ORDER</p>
          </div>
          <button className="logoutbtn" onClick={handleLogout}>
            LOGOUT
          </button>
        </div>
        {getOrderHistory && getOrderHistory.length === 0 ? (
          <OrderEmpty />
        ) : (
          <>
            {getOrderHistory &&
              getOrderHistory.map((data, key) => (
                <section className="recent-orderwrapper" key={key}>
                  <div className="recent-order-toptab">
                    <div className="order-place-wrapp">
                      <p className="order-place-line">Order-placed</p>
                      <p className="order-date">
                        {moment(data?.created_date, "DD/MM/YYYY").format(
                          "DD MMMM YYYY"
                        )}
                      </p>
                    </div>
                    <div className="shipto-wrapper">
                      <p className="shipto-line">Ship To</p>
                      <p className="owner-order-name">
                        {data?.order_meta?.shipping_first_name}
                      </p>
                      <p className="short-addras">
                        {data?.order_meta?.shipping_address2}
                      </p>
                    </div>
                    <div className="total-wrapper">
                      <p className="total-tag">Total</p>
                      <p className="total-amounts">
                        {data?.order_meta?.currency_sign}
                        {calculateTotal(data?.order_item)}
                      </p>
                    </div>
                    <div className="order-details-wrapper">
                      <p className="order-number">Order #{data.order_id}</p>
                      <p className="vierorder-details-link">
                        <span
                          onClick={() => {
                            setCurrentTab("orderdetails");
                            navigate("/user/orderdetails");
                            localStorage.setItem("orderViewId", data?.id);
                          }}
                        >
                          View Order Details
                        </span>{" "}
                        | <span>Download Invoice</span>
                      </p>
                    </div>
                  </div>
                  {data &&
                    data.order_item &&
                    data.order_item.map((item, key) => (
                      <div className="order-items-wrapper" key={key}>
                        {item.status === "delivered" ? (
                          <p className="deliverd-status">Delivered</p>
                        ) : (
                          <p className="deliverd-status">
                            Delivery Date{" "}
                            {moment(data?.expected_date).format("dddd")}
                          </p>
                        )}

                        <section className="order-product-wrapper">
                          <div className="order-productimg">
                            <img src={item?.featured_image} alt="#" />
                          </div>
                          <div className="order-items-wrapper">
                            <p className="order-product-name">
                              {item?.product_name}
                            </p>
                            {/* <p className="order-product-Sold-by">
                              Sold by: Sami Kotob
                            </p>
                            <p className="order-product-Sold-by">
                              Return eligiblr through 20-Jan-2022
                            </p> */}
                            <label className="price-tag">
                              {data?.order_meta?.currency_sign}
                              {parseInt(item?.product_price).toFixed(2)}
                            </label>
                          </div>
                        </section>
                      </div>
                    ))}
                </section>
              ))}
          </>
        )}
      </section>
    </>
  );
};

export default Deshbordpage;
