import React, { useState } from "react";
import "./todayDeals.scss";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import Login from "../../../popups/Login/Login";

const BestDeals = ({
  homeProductList,
  getWishList,
  handleAddToWishList,
  handleRemoveFromWishList,
}) => {
  const navigate = useNavigate();
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  return (
    <>
      <div className="todayDeals-bg-wrapp">
        <div className="container">
          <div className="todaydeal-wrapper">
            <div className="topline-wrapper">
              <label className="BestSellerstag">Bid products</label>
              <Link to="/bid-products">
                <button className="viewallbtn">
                  View All
                  <i className="fas fa-arrow-right ms-2" />
                </button>
              </Link>
            </div>

            <div className="todays-dealsitems-wrapper">
              <Slider {...settings}>
                {homeProductList &&
                  homeProductList.bidding &&
                  homeProductList.bidding.map((data, key) => (
                    <div className="bestdealproduct-wrapp" key={key}>
                      <div className="product-img-wrapper">
                        <img
                          src={data.featured_image}
                          alt="#"
                          onClick={() => showProduct(data.slug)}
                        />

                        <div className="hover-wish-list-wrappper">
                          <div className="wish-list-imgs">
                            {localStorage.getItem("user-token") ? (
                              <img
                                src={
                                  getWishList?.find(
                                    (item) => item?.id === data?.id
                                  )
                                    ? "/img/ha2.png"
                                    : "/img/ha.png"
                                }
                                alt={data?.pname}
                                className="img-fluid"
                                onClick={
                                  !getWishList?.find(
                                    (item) => item?.id == data?.id
                                  )
                                    ? () => handleAddToWishList(data?.id)
                                    : () =>
                                        handleRemoveFromWishList(
                                          getWishList?.find(
                                            (item) => item?.id == data?.id
                                          )
                                        )
                                }
                              />
                            ) : (
                              <img
                                src="/img/ha.png"
                                alt=""
                                className="img-fluid"
                                onClick={openLoginModal}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="product-description-wrapper">
                        <p
                          className="items-name"
                          onClick={() => showProduct(data.slug)}
                        >
                          {data.pname}
                        </p>

                        {data.in_stock == 0 ? (
                          <p className="instock" style={{ color: "red" }}>
                            Out Of Stock
                          </p>
                        ) : (
                          <p className="instock">In stock</p>
                        )}
                        <span className="last-price" style={{ color: "#000" }}>
                          Starting BId
                        </span>
                        <span className="bidamount">
                          {data.currency_sign}
                          {data.current_bid}
                        </span>

                        {/* {data.s_price ? (
                          <>
                            <del className="last-price ">
                              {data.currency_sign}
                              {data.p_price}
                            </del>
                            <span className="bidamount">
                              {data.currency_sign}
                              {data.s_price}
                            </span>
                          </>
                        ) : (
                          <del className="last-price ">
                            {data.currency_sign}
                            {data.p_price}
                          </del>
                        )} */}
                        <Link to={`/product/${data.slug}`}>
                          <button
                            className="bid-now-btn"
                            onClick={() => showProduct(data.slug)}
                          >
                            Bid Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default BestDeals;
