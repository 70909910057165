import React, { useEffect } from "react";
import { getPagesData } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Home/loader/loader";

const Biddingbuyinghelp = () => {
  const dispatch = useDispatch();
  const biddingBuying = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 16,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div className="registration_bg_change">
        <div className="container">
          <h1>Bidding&buying help</h1>
          <p class="lh-lg">
            Whatever you’re buying—large or small, new or refurbished— smileszy
            offers many ways to ensure you get exactly what you want.
          </p>
        </div>
      </div>
      <div class="container py-5">
        <div class="Smileszy-Guarantee">
          {/* <h4>Motor Vehicle Bidding Policy</h4>
          <p class="lh-lg">
            You give the seller permission to share your contact information
            with them in order for them to get in touch with you directly to
            discuss the transaction by making a bid in this classification.
          </p>
          <p class="lh-lg">
            You are required to follow by the conditions of our User Privacy
            Notice regarding the use of personal information you get from
            Smileszy as a Smileszy Motors vendor.
          </p>
          <p class="lh-lg">
            You may only contact a bidder using their contact information if you
            need to discuss the car they selected or other like-kind items you
            are selling.
          </p>
          <p class="lh-lg">
            You engage into a transaction and are subject to the rights and
            obligations of our User Agreement when you place a bid on a vehicle
            in an auction or make an offer using Buy It Now or Best Offer.{" "}
          </p>
          <p class="lh-lg">
            In accordance with the rules of such a bid, you expressly agree to
            share your details with the seller in certain situations and to be
            contacted by the seller regarding your offer on the vehicle or other
            similar vehicles that are up for sale.
          </p>
          <p class="lh-lg">
            High-volume Smileszy Motors sellers get access to the bidder data
            for each bidder taking part in a certain Smileszy Motors
            transaction.
          </p>
          <p class="lh-lg">
            These dealers could get in touch with you to ask about the vehicle
            you placed a bid on or to talk about other stock that might be of
            interest to you.
          </p>
          <p class="lh-lg">
            Sellers on Smileszy Motors are subject to the requirements of our
            User Privacy Notice regarding the use of personal data and are only
            permitted to use the bidder information for the reasons for which
            you have specifically consented.
          </p> */}
          {biddingBuying ? (
            <div
              dangerouslySetInnerHTML={{
                __html: biddingBuying && biddingBuying.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default Biddingbuyinghelp;
