import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-responsive-modal";
import { cancelOrder } from "../../redux/homeAction";
import "./OrderCancelForm.scss";

const OrderCancelForm = ({ open, onCloseModal, productId }) => {
  const dispatch = useDispatch();
  const [cancelReason, setCancelReason] = useState("Order Created by Mistake");

  const handleCancelOrder = (e) => {
    e.preventDefault();

    const data = {
      order_id: productId.order_id,
      itemid: [productId.product_id],
      reason: cancelReason,
    };
    dispatch(cancelOrder(data));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{ modal: "supportform-wrapper-modal" }}
      >
        <h2 className="retrunform-wrapperline">
          Order Cancel Form{" "}
          <i className="fa fa-remove" onClick={onCloseModal}></i>
        </h2>
        <div className="OrderCanelForm-wrapper">
          <div className="cancelOrderFormside-wrapper">
            <form onSubmit={handleCancelOrder}>
              <div className="Caneclform-wrapper">
                {/* <label>
                  <span>Order ID</span>
                  <select className="orderselet-wrpp" disabled>
                    <option disabled>Order Id</option>
                    <option>cancelledOrder?.id</option>
                  </select>
                </label>
                <div className="d-flex align-item-center justify-content-between py-3">
                  <label>
                    <input
                      className="mx-1"
                      type="radio"
                      name="order"
                      value="complete"
                    />
                    <span>Complete Order</span>
                  </label>
                  <label>
                    <input
                      className="mx-1"
                      type="radio"
                      name="order"
                      value="single"
                    />
                    <span>Single Order</span>
                  </label>
                </div>
                <label>
                  <span>Product Name</span>
                  <select className="orderselet-wrpp" required>
                    <option>dt?.product_name</option>
                  </select>
                </label> */}

                <label>
                  <span>Reason for Cancellation</span>
                  <select
                    className="orderselet-wrpp"
                    onChange={(e) => setCancelReason(e.target.value)}
                  >
                    <option value={"Order Created by Mistake"}>
                      Order Created by Mistake
                    </option>
                    <option value={"Item(s) Would Not Arrive On Time"}>
                      Item(s) Would Not Arrive On Time
                    </option>
                    <option value={"Shipping cost too High"}>
                      Shipping cost too High
                    </option>
                    <option value={"Found cheaper somewhere Else"}>
                      Found cheaper somewhere Else
                    </option>
                    <option value={"Need to Changes Shipping Address"}>
                      Need to Changes Shipping Address
                    </option>
                    <option value={"Need to change Billing Address"}>
                      Need to change Billing Address
                    </option>
                    <option value={"Need to Change Payment Method"}>
                      Need to Change Payment Method
                    </option>
                    <option value={"other"}>Other</option>
                  </select>
                </label>
                <button type="submit" className="submit-form-wrapp">
                  Submit
                </button>
              </div>
            </form>
          </div>

          <div className="Cancelsuportform-rightside">
            <h6>
              <b>Are You Sure you Want to cancel this Order ?</b>
            </h6>
            <p>Important Message</p>
            <p>
              Cancelling items in this order may cause you to lose promotional
              offers and/or cashbacks in undispatched items in this and/or other
              orders. Additionally, if discounted items have already been
              dispatched you may not be able to cancel some of the items in this
              order.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OrderCancelForm;
