import React from "react";
import "./mainProducts.scss";
import Loader from "../../Home/loader/loader";
import CommonPagination from "../../Home/pagination/commonPagination";
import Login from "../../../popups/Login/Login";

const Mainproducts = ({}) => {
  return (
    <>
      <div className="mian-products-wrapper">
        <div className="product-wrapper">
          <div className="hover-wish-list-wrappper">
            <div className="wish-list-imgs">
              <img src="/img/ha2.png" alt="" className="img-fluid" />
              <img src="/img/ha.png" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="product-imgs-wrap">
            <img src="" alt="#" className="img-fluid"></img>
          </div>
          <div className="items-content-wrappers">
            <p className="items-name">pname</p>
            <p className="instock">In stock</p>
            <br></br>
            <del className="last-price">30</del>{" "}
            <span className="latest-price">20</span>
            <button className="addto-cart-btn">
              {/* <span className="loader-spinner">
                <i className="fa-solid fa-spinner loading-gif"></i>
              </span> */}
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mainproducts;
