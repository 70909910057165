import React from "react";
import "./ProductStatus.scss";

const ProductStatus = ({
  getBrands,
  setBrands,
  setOutInStock,
  setOnSale,
  getAllAttributeList,
  setNewArrival,
  setAttributeId,
  brands,
  attributeId,
}) => {
  return (
    <>
      <div className="ProductStatus-wrapper">
        <p className="product-status-txt">BRANDS</p>
        <div className="product-stok-wrapper">
          <ul>
            {getAllAttributeList &&
              getAllAttributeList.Brands &&
              getAllAttributeList.Brands.map((data, key) => (
                <li key={key}>
                  <label htmlFor={data.title}>
                    <input
                      type="checkbox"
                      className="me-2"
                      name="brands"
                      id={data.title}
                      onClick={(e) => setBrands([...brands, data.title])}
                    />
                    {data.title}
                  </label>
                </li>
              ))}
          </ul>
        </div>
      </div>

      {getAllAttributeList &&
        getAllAttributeList.Attributes &&
        getAllAttributeList.Attributes.map((item, key) => (
          <div className="ProductlistStatus-wrapper" key={key}>
            <p className="product-statuss-txt">{item.attribute_name}</p>
            <div className="productliststok-wrapper">
              <ul>
                {item.attribute_value.map((data, key) => (
                  <li key={key}>
                    <label>
                      <span>
                        <input
                          type="checkbox"
                          className="me-2"
                          onClick={() =>
                            setAttributeId([...attributeId, data.id])
                          }
                        />
                        {data.attr_value_name}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}

      <div className="ProductStatus-wrapper">
        <p className="product-status-txt">PRODUCT STATUS</p>
        <div className="product-stok-wrapper">
          <ul>
            <li>
              <label htmlFor="In-Stock">
                <input
                  type="radio"
                  className="me-2"
                  name="inStock"
                  id="In-Stock"
                  value="true"
                  onChange={(e) => setOutInStock(e.target.value)}
                />
                In Stock
              </label>
            </li>
            <li>
              <label htmlFor="Out-of-Stock">
                <input
                  type="radio"
                  className="me-2"
                  name="inStock"
                  id="Out-of-Stock"
                  value="false"
                  onChange={(e) => setOnSale(e.target.value)}
                />
                On Sale
              </label>
            </li>
            <li>
              <label htmlFor="Out-of-Stock">
                <input
                  type="radio"
                  className="me-2"
                  name="inStock"
                  id="Out-of-Stock"
                  value="true"
                  onChange={(e) => setNewArrival(e.target.value)}
                />
                New Arrival
              </label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProductStatus;
