import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./sitemap.scss";

const Sitemap = () => {
  const categoriesList = useSelector((state) => state.home.categoriesList);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }} className="pb-5">
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Sitemap</h1>
        </div>
      </div>
      <div className="container mt-5 bg-white sitemap-wrapperWrapper">
        <h3 className="pt-3">Buy</h3>
        <hr />
        <div className="sitemap-wrapper">
          <h4 className="CategoriesHeader">All Categories</h4>
          {categoriesList &&
            categoriesList.map((data, key) => (
              <div className="sitemap-wrapperItem" key={key}>
                <Link to={`/category/${data.slug}`} className="data-title">
                  {data.title}
                </Link>

                <ul className="sitemap-wrapperList">
                  {data?.child_category?.map((item, key) => (
                    <li className="sitemap-wrapperListItem" key={key}>
                      <Link to={`/category/${item.slug}`}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
