import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./deskNavbar.scss";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getMenusData } from "../../../redux/homeAction";

const DeskNavbar = () => {
  const dispatch = useDispatch();
  const [viewCategory, setViewCategory] = useState(6);
  const getMenusList = useSelector((state) => state.home.getMenusList);

  useEffect(() => {
    dispatch(getMenusData());
  }, []);

  return (
    <>
      <div className="deknav-wrapeer">
        <div className="container">
          <div className="nav-links-wrapper">
            <ul className="nav-linkuls">
              <li className="navlinksd">
                <Link to="/">Home</Link>
              </li>
              <li className="navlinksd">
                <Link to="/shops">shops</Link>
              </li>
              {getMenusList &&
                getMenusList.map((data, key) => (
                  <li className="navlikslinr" key={key}>
                    <Link to={`/category/${data.slug}`}>{data.title}</Link>
                    <div className="navslists-wrapper fade-in-fwd">
                      <ul className="navlistsitem-wrapp">
                        <li className="nav-lists-items">
                          <p className="categriesheading-line">
                            Top categories
                          </p>
                          <ul>
                            {data.top_sub_menu
                              ?.filter((item, index) => index < viewCategory)
                              ?.map((item, index) => (
                                <Link to={`/category/${item.slug}`} key={index}>
                                  <li>{item.title}</li>
                                </Link>
                              ))}
                          </ul>
                        </li>
                        <li className="nav-lists-items">
                          <p className="categriesheading-line">
                            More categories
                          </p>
                          <ul>
                            {data.more_sub_menu
                              ?.filter((item, index) => index < viewCategory)
                              ?.map((item, index) => (
                                <Link to={`/category/${item.slug}`} key={index}>
                                  <li>{item.title}</li>
                                </Link>
                              ))}
                          </ul>
                        </li>
                        <li className="navitemsimgs-wraper">
                          <Link to={`/category/${data.slug}`}>
                            <img src={data.image} alt="#" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeskNavbar;
