import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrency } from "../../redux/homeAction";

const Header = () => {
  const dispatch = useDispatch();
  const currencyList = useSelector((state) => state.home.currencyList);
  const [currency, setCurrency] = useState(
    JSON.parse(localStorage.getItem("currencyCode")) || {
      currencyCode: null,
      sign: null,
      id: null,
    }
  );
  const { currencyCode, sign, id } = currency;

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  return (
    <>
      <div className="nav-top-bar">
        <div className="container">
          <div className="top-header-wrapper">
            <div className="top-links-wrapper">
              <Link to="/dailyDeals" className="top-acounts-link">
                <span className="top-links">Daily Deals </span>
              </Link>
              <Link to="/about" className="top-acounts-link">
                <span className="top-links">About us</span>
              </Link>
              <Link to="/contact" className="top-acounts-link">
                <span className="top-links">Contact us</span>
              </Link>
            </div>
            <div className="toprights-wrapper ">
              <div className="top-head-line">
                <Link to="/sell" className="toplinks-wrapp top-head-line">
                  <span className="headers-links-wrapper">Sell</span>
                </Link>
              </div>

              <select
                value={id}
                className="select-option-wrapper"
                onChange={(e) => {
                  let cloneCurrList = currencyList?.slice(0);
                  let newCode = cloneCurrList?.find(
                    (dt) => dt?.id == Number(e.target.value)
                  );
                  let currencyselectedObj = {
                    id: newCode?.id,
                    sign: newCode?.sign,
                    currencyCode: newCode?.code,
                  };
                  setCurrency({ currencyselectedObj });
                  localStorage.setItem(
                    "currencyCode",
                    JSON.stringify(currencyselectedObj)
                  );

                  window.location.reload();
                }}
              >
                {currencyList &&
                  currencyList?.map((item, key) => (
                    <option
                      selected={
                        item?.code.toLowerCase() == "usd" ? true : false
                      }
                      value={item?.id}
                      key={key}
                    >
                      {item.code}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
