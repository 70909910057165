import React, { useEffect } from "react";
import "./App.css";
import "./Components/rtlScss/rtlweb.scss";
import Footer from "./Components/Footer/Footer";
import Searched from "./Components/navbar/search/NewArrivals";
import ProductView from "./popups/quickView/ProductView";
import VendorRegistrationForm from "./Components/vendorRegistration/VendorRegistrationForm";
import Rendompage from "./Components/404Pgaes/RendomPage";
import OrderDetailspage from "./Components/MyAccounts/Dashboards/OrderDetailspage/OrderDetailspage";
import SupportForm from "./Components/support/supportForm/SupportForm";
import ThankuPage from "./Components/thankuPage/ThankuPage";
import ResetPassword from "./Components/resetPassword/resetPassword";
import NewProducts from "./Components/NEW PRODUCTS/NewProducts";
import BestSellers from "./Components/BESTSELLERS/BestSellers";
import NewArrivals from "./Components/newArrivals/NewArrivals";
import TheDayValue2 from "./Components/theDayValue/TheDayValue2";
import TheDayValue from "./Components/theDayValue/TheDayValue";
import ShopDetailsPage from "./Components/shopspage/ShopDetailsPage/ShopDetailsPage";
import UserDeshbord from "./Components/MyAccounts/Dashboards/UserDeshbord";
import MyAccounts from "./Components/MyAccounts/MyAccounts";
import ProductPage from "./pages/productDetails/ProductPage";
import ShopsPage from "./Components/shopspage/ShopsPage";
import Shop from "./Components/Shop/Shop";
import FaqPage from "./pages/faqPage/FaqPage";
import Privacy from "./pages/privacy";
import Terms from "./pages/Terms";
import Contactpage from "./Components/Contectus/Contactpage";
import About from "./Components/ABOUT/About";
import Checkout from "./Components/Checkout/Checkout";
import Cart from "./Components/Cart/Cart";
import SellWithUs from "./Components/SellWithUs/SellWithUs";
import Home from "./Components/Home/Home";
import Header from "./Components/header/Header";
import Navbar from "./Components/navbar/Navbar";
import DeskNavbar from "./Components/navbar/desk-navbar/DeskNavbar";
import { Route, Routes, useLocation } from "react-router-dom";
import DailyDeals from "./Components/DealsPage/DailyDeals";
import ServiceDetails from "./Components/shopspage/SIngleServicePage/ServiceDetails";
import Blog from "./Components/blog/Blog";
import Singleblog from "./Components/blog/Singleblog";
import CompanyInfo from "./pages/companyInfo/CompanyInfo";
import ReturnandRefund from "./pages/ReturnandRefund/ReturnandRefund";
import Registration from "./pages/Registration/Registration";
import Biddingbuyinghelp from "./pages/Biddingbuyinghelp/Biddingbuyinghelp";
import MoneyBackGuarantee from "./pages/Money Back Guarantee/MoneyBackGuarantee";
import StartSelling from "./pages/startselling/StartSelling";
import SecurityCenter from "./pages/securitycenter/SecurityCenter";
import Sitemap from "./pages/sitemap/Sitemap";
import Affiliates from "./pages/affiliates/Affiliates";
import BidsProducts from "./Components/bidsProducts/BidsProducts";
import AllFeatured from "./Components/Allfeatured/AllFeatured";
import TodayDeals from "./Components/NEW PRODUCTS/TodaysDeals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderPlaced from "./Components/order-placed/OrderPlaced";
import UserAgreement from "./pages/usersAgreement/UserAgreement";
import FeedbackGuideline from "./pages/feedbackGuideline/FeedbackGuideline";
import PaymentServices from "./pages/paymentServices/PaymentServices";
import HowItWork from "./Components/SellWithUs/howitswork/HowItWork";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="app">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={localStorage.getItem("wo_user")}>
        <div className="stickynav-top-wrapper">
          <Header />
          <Navbar />
          <DeskNavbar />
        </div>
        <Routes>
          <Route exect path="/" element={<Home />} />
          <Route exact path="/sell" element={<SellWithUs />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/searched-item/:text" element={<Searched />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contactpage />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/Privacy" element={<Privacy />} />
          <Route exact path="/FaqPage" element={<FaqPage />} />
          <Route exact path="/singleblog/:slug" element={<Singleblog />} />
          <Route exact path="/products" element={<Shop />} />
          <Route exact path="/products/:subCategory" element={<Shop />} />
          <Route exact path="/category/:slug" element={<Shop />} />
          <Route exact path="/shops" element={<ShopsPage />} />
          <Route exact path="/product/:slug" element={<ProductPage />} />
          <Route exact path="/myAccounts" element={<MyAccounts />} />
          <Route exact path="/registration" element={<Registration />} />
          <Route exact path="/order-placed" element={<OrderPlaced />} />
          <Route
            exact
            path="/biddingbuyinghelpss"
            element={<Biddingbuyinghelp />}
          />
          <Route
            exact
            path="/moneybackguarantee"
            element={<MoneyBackGuarantee />}
          />
          <Route
            exact
            path="/serviceDetails/:adId"
            element={<ServiceDetails />}
          />
          <Route exact path="/user/:params" element={<UserDeshbord />} />
          <Route
            exact
            path="/shopdetails/:shopId"
            element={<ShopDetailsPage />}
          />
          <Route exact path="/top-100-offers" element={<TheDayValue />} />
          <Route exact path="/value-of-day" element={<TheDayValue2 />} />
          <Route exact path="/arivals" element={<NewArrivals />} />
          <Route exact path="/bid-products" element={<BidsProducts />} />
          <Route exact path="/bestSellers" element={<BestSellers />} />
          <Route exact path="/featured" element={<AllFeatured />} />
          <Route exact path="/today's-deals" element={<TodayDeals />} />
          <Route exact path="/newProducts" element={<NewProducts />} />
          <Route exact path="/ProductView" element={<ProductView />} />
          <Route exact path="/dailyDeals" element={<DailyDeals />} />
          <Route exact path="/company-info" element={<CompanyInfo />} />
          <Route exact path="/return" element={<ReturnandRefund />} />
          <Route exact path="/start-selling" element={<StartSelling />} />
          <Route exact path="/securityCenter" element={<SecurityCenter />} />
          <Route exact path="/sitemap" element={<Sitemap />} />
          <Route exact path="/affiliates" element={<Affiliates />} />
          <Route
            exact
            path="/vendor-registration"
            element={<VendorRegistrationForm />}
          />
          <Route
            exact
            path="/dashboard/userresetpassword/:id"
            element={<ResetPassword />}
          />
          <Route exact path="/thankuPage" element={<ThankuPage />} />
          <Route exact path="/support" element={<SupportForm />} />
          <Route exact path="/orderdetail" element={<OrderDetailspage />} />
          <Route exact path="*" element={<Rendompage />} />
          <Route exact path="/user-agreement" element={<UserAgreement />} />
          <Route exact path="/feedback" element={<FeedbackGuideline />} />
          <Route exact path="/payment-services" element={<PaymentServices />} />
          <Route exact path="/how-it-work" element={<HowItWork />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}
export default App;
