import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  getRelatedData,
  onAddToCartList,
  refresherAction,
  setLocalCartItems,
} from "../../redux/homeAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./relatedproducts.scss";
import { onAddToWishList, onRemoveFromWishList } from "../../redux/homeAction";
import Login from "../../popups/Login/Login";
import { toast } from "react-toastify";

const RelatedProducts = ({ getProductDetails, setShowImg }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(null);
  const getWishList = useSelector((state) => state.home.getWishList);
  const getRelatedList = useSelector((state) => state.home.getRelatedList);
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const [loginOpen, setLoginOpen] = useState(false);
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  useEffect(() => {
    const data = {
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
      cat_id: getProductDetails,
    };
    dispatch(getRelatedData(data));
  }, [getProductDetails]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
    setShowImg("");
  };

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  return (
    <>
      <div className="RelatedProducts-wrapper">
        <div className="products-line">
          <label className="related-products">RELATED PRODUCTS</label>
        </div>
        <div className="relatedproducts-list">
          <div className="products-listwrapper">
            {getRelatedList &&
              getRelatedList.product &&
              getRelatedList.product.map((data, index) => (
                <div key={index} className="product-wrapes">
                  <div className="products-wrapper">
                    <div className="hover-wish-list-wrappper">
                      <div className="wish-list-imgs">
                        {localStorage.getItem("user-token") ? (
                          <img
                            src={
                              getWishList?.find((item) => item?.id === data?.id)
                                ? "/img/ha2.png"
                                : "/img/ha.png"
                            }
                            alt={data?.pname}
                            className="img-fluid"
                            onClick={
                              !getWishList?.find((item) => item?.id == data?.id)
                                ? () => handleAddToWishList(data?.id)
                                : () =>
                                    handleRemoveFromWishList(
                                      getWishList?.find(
                                        (item) => item?.id == data?.id
                                      )
                                    )
                            }
                          />
                        ) : (
                          <img
                            src="/img/ha.png"
                            alt=""
                            className="img-fluid"
                            onClick={openLoginModal}
                          />
                        )}
                      </div>
                    </div>
                    <div className="product-imgs-wrap">
                      <img
                        src={data.featured_image}
                        alt=""
                        className="img-fluid"
                        onClick={() => showProduct(data.slug)}
                      ></img>
                    </div>
                    <div className="items-content-wrappers">
                      <p
                        className="items-name"
                        onClick={() => showProduct(data.slug)}
                      >
                        {data.pname}
                      </p>
                      {data.in_stock == 0 ? (
                        <p className="instock" style={{ color: "red" }}>
                          Out of stock
                        </p>
                      ) : (
                        <p className="instock">In stock</p>
                      )}

                      {data.s_price ? (
                        <>
                          <del className="last-price">
                            {data.currency_sign}
                            {data.p_price}
                          </del>
                          <span className="latest-price">
                            {data.currency_sign}
                            {data.s_price}
                          </span>
                        </>
                      ) : (
                        <span className="latest-price">
                          {data.currency_sign}
                          {data.p_price}
                        </span>
                      )}

                      {data.product_type === "bid" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(data.slug)}
                        >
                          Bid Now
                        </button>
                      ) : data.product_type === "single" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(data)}
                        >
                          {currentId == data.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : data.product_type === "variants" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(data)}
                        >
                          {currentId == data.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : data.product_type === "booking" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(data.slug)}
                        >
                          Book Now
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <section className="related-product-mobslider">
          <Slider {...settings}>
            {getRelatedList &&
              getRelatedList.product &&
              getRelatedList.product.map((data, index) => (
                <div className="product-wrapes" key={index}>
                  <div className="products-wrapper">
                    <div className="hover-wish-list-wrappper">
                      <div className="wish-list-imgs">
                        <img src="/img/ha.png" alt="#" className="img-fluid" />
                        <br />
                      </div>
                    </div>
                    <div className="product-imgs-wrap">
                      <img
                        src={data.featured_image}
                        alt=""
                        className="img-fluid"
                        onClick={() => showProduct(data.slug)}
                      ></img>
                    </div>
                    <div className="items-content-wrappers">
                      <p
                        className="items-name"
                        onClick={() => showProduct(data.slug)}
                      >
                        {data.pname}
                      </p>
                      {data.in_stock == 0 ? (
                        <p className="instock">Out of stock</p>
                      ) : (
                        <p className="instock">{data.in_stock} in stock</p>
                      )}

                      <img src="/img/stars.png" alt="#"></img>
                      {data.s_price ? (
                        <>
                          <del className="last-price">
                            {`${data.currency_sign} ` + data.p_price}
                          </del>{" "}
                          <span className="latest-price">
                            {`${data.currency_sign} ` + data.s_price}
                          </span>
                        </>
                      ) : (
                        <span className="latest-price">
                          {`${data.currency_sign} ` + data.p_price}
                        </span>
                      )}
                      {data.product_type === "bid" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(data.slug)}
                        >
                          Bid Now{" "}
                        </button>
                      ) : data.product_type === "single" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(data)}
                        >
                          {currentId == data.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : data.product_type === "variants" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(data)}
                        >
                          {currentId == data.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : data.product_type === "booking" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(data.slug)}
                        >
                          Book Now
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </section>
      </div>
      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default RelatedProducts;
