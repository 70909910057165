import React from "react";
import Shopad from "../Shop-left/Ads-photo/Shopad";
import FilterByPrice from "../Shop-left/Filter-by-Price/FilterByPrice";
import ProductCategories from "../Shop-left/Product-Categories/ProductCategories";
import ProductListStatus from "../Shop-left/Product-list-status/ProductListStatus";
import ProductStatus from "../Shop-left/Product-Status/ProductStatus";
import "./shopSidebar.scss";

const ShopSidebar = ({
  setOpenMobileFilter,
  categoriesList,
  openCategory,
  closeCategory,
  categoryId,
}) => {
  return (
    <>
      <div className="shop-Sidebar-section">
        <div className="filterslie-wrapper">
          <span>Filter</span>
          <i
            className="fas fa-times cut-icon"
            onClick={() => setOpenMobileFilter(false)}
          ></i>
        </div>
        <ProductCategories
          categoriesList={categoriesList}
          openCategory={openCategory}
          closeCategory={closeCategory}
          categoryId={categoryId}
          closeSidebar={() => setOpenMobileFilter(false)}
        />
        {/* <FilterByPrice />
        <ProductStatus />
        <ProductListStatus />
        <Shopad /> */}
      </div>
    </>
  );
};

export default ShopSidebar;
