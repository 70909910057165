import React from "react";
import { useNavigate } from "react-router-dom";

const UserSidebar = ({
  setCurrentTab,
  currentTab,
  getAccountDetails,
  handleLogout,
}) => {
  const navigate = useNavigate();

  return (
    <div className="left-side-wrapp">
      <div className="user-details-wrapper">
        <div
          className="userimg-wrapper"
          style={{
            backgroundImage: `url("${
              getAccountDetails && getAccountDetails.profile_image
            }")`,
          }}
        ></div>
        <div className="user-details-wrpapp">
          <p className="status-line">Hello</p>
          <p className="user-line">
            {getAccountDetails && getAccountDetails.name}
          </p>
        </div>
      </div>
      <div className="Deshbord-tab-wrapp">
        <p
          className={
            currentTab === "dashboard" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("dashboard");
            navigate("/user/dashboard");
          }}
        >
          <i className="fas fa-boxes-alt iconsboxsd"></i>
          DASHBOARD
        </p>
        <p
          className={
            currentTab === "myaccount" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("myaccount");
            navigate("/user/myaccount");
          }}
        >
          <i className="fas fa-user-circle iconsboxsd"></i>
          MY ACCOUNT
        </p>
        <p
          className={
            currentTab === "myorders" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("myorders");
            navigate("/user/myorders");
          }}
        >
          <i className="fas fa-box-open iconsboxsd"></i>
          MY ORDERS
        </p>
        <p
          className={
            currentTab === "mybids" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("mybids");
            navigate("/user/mybids");
          }}
        >
          <i className="fas fa-box-open iconsboxsd"></i>
          MY BIDS
        </p>
        <p
          className={
            currentTab === "mybooking" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("mybooking");
            navigate("/user/mybooking");
          }}
        >
          <i className="fas fa-box-open iconsboxsd"></i>
          MY BOOKINGS
        </p>
        <p
          className={
            currentTab === "payments" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("payments");
            navigate("/user/payments");
          }}
        >
          <i className="fas fa-wallet iconsboxsd"></i>
          PAYMENTS
        </p>
        <p
          className={
            currentTab === "address" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("address");
            navigate("/user/address");
          }}
        >
          <i className="fas fa-map-marker-alt iconsboxsd"></i>
          ADDRESS
        </p>
        <p
          className={
            currentTab === "mywallet" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("mywallet");
            navigate("/user/mywallet");
          }}
        >
          <i className="fas fa-box-open iconsboxsd"></i>
          MY WALLET
        </p>
        <p
          className={
            currentTab === "wishlist" ? "dashboardTabActive" : "dashboardTab"
          }
          onClick={() => {
            setCurrentTab("wishlist");
            navigate("/user/wishlist");
          }}
        >
          <i className="fas fa-heart iconsboxsd"></i>
          WISHLIST
        </p>
        <p className="dashboardTab" onClick={handleLogout}>
          <i className="fas fa-sign-out-alt iconsboxsd"></i>
          LOGOUT
        </p>
      </div>
    </div>
  );
};

export default UserSidebar;
