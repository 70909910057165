import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPagesData } from "../../redux/homeAction";
import Loader from "../../Components/Home/loader/loader";

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const companyInformation = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 13,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Company Info Page</h1>
        </div>
      </div>
      <div className="container">
        <div className="terms-page-wrapper">
          {companyInformation ? (
            <div
              dangerouslySetInnerHTML={{
                __html: companyInformation && companyInformation.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyInfo;
