import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import "./Login.scss";
import SignUp from "../singUp/SignUp";
import Forgot from "../Forgot/Forgot";
import { useDispatch } from "react-redux";
import { getCurrency, onLogin, onSocialLogin } from "../../redux/homeAction";
import { toast } from "react-toastify";
import { auth, facebookProvider, googleProvider } from "../../firebase";

const Login = ({ open, onCloseModal, openLoginModal }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [registerOpen, setRegisterOpen] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  const openRegisterModal = () => {
    setRegisterOpen(true);
    onCloseModal();
    dispatch(getCurrency());
  };
  const closeRegisterModal = () => setRegisterOpen(false);
  const openForgotModal = () => {
    setForgotOpen(true);
    onCloseModal();
    dispatch(getCurrency());
  };
  const closeForgotModal = () => setForgotOpen(false);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const data = {
      email: values.email,
      password: values.password,
    };

    if (!values.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values.email)) {
      toast.error("Invalid Email Address");
    } else if (!values.password) {
      toast.error("Enter Password");
    }
    // else if (!strongRegex.test(values.password)) {
    //   toast.error(
    //     "password should contain numeric value, special character, upper case letter and lower case letter"
    //   );
    // }
    else {
      dispatch(onLogin(data));
    }
  };

  const handleGoogleLogin = () => {
    auth
      .signInWithPopup(googleProvider)
      .then((result) => {
        var credential = result.credential;
        var token = credential.accessToken;

        console.log(token);

        if (result) {
          const data = {
            access_token: token,
            provider: "google",
          };
          dispatch(onSocialLogin(data));
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };

  const handleFacebookLogin = () => {
    auth
      .signInWithPopup(facebookProvider)
      .then((result) => {
        var credential = result.credential;
        var accessToken = credential.accessToken;

        if (result) {
          const data = {
            access_token: accessToken,
            provider: "facebook",
          };
          dispatch(onSocialLogin(data));
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{ modal: "login-popup-modal" }}
      >
        <div className="inner-container-login">
          <div className="title">
            <h2 className="title-text">Sign in</h2>
          </div>
          <div className="content">
            <div className="left">
              <div className="form">
                <form onSubmit={handleSubmitForm}>
                  <div className="form-element-group ">
                    <label htmlFor="email">Email</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Email"
                      className="username"
                      name="email"
                      value={values.email}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="form-element-group password-input">
                    <label htmlFor="password">Password</label>
                    <br />
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="username"
                      name="password"
                      value={values.password}
                      onChange={onInputChange}
                    />
                    {showPassword === false ? (
                      <i
                        class="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={() => setShowPassword(true)}
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => setShowPassword(false)}
                      ></i>
                    )}
                  </div>
                  <div
                    className="forgott-password-link"
                    onClick={openForgotModal}
                  >
                    <Link className="forgot-link" to="">
                      Forgot your Password ?
                    </Link>
                  </div>
                  <div className="form-element-group">
                    <button
                      type="submit"
                      className="submit-btn"
                      name="submit-btn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="right">
              <h3 className="area-title">New Here</h3>
              <div className="discription">
                <div className="heading-text">
                  Registration is free and easy
                </div>
                <ul className="points">
                  <li>Faster checkout</li>
                  <li>Save multiple addresses</li>
                  <li>View and track orders and more</li>
                </ul>
              </div>
              <button
                className="create-account-btn"
                onClick={openRegisterModal}
              >
                Create An Account
              </button>
            </div>
          </div>
          <div className="social-singinWraper">
            <div className="social-loginbtnwrapper">
              <div onClick={handleGoogleLogin}>
                <img
                  src="/img/google-icon.png"
                  alt="#"
                  className="social-img"
                />
              </div>
              <div onClick={handleFacebookLogin}>
                <img
                  src="/img/Facebook-logo.svg"
                  alt="#"
                  className="social-img"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <SignUp
        open={registerOpen}
        onCloseModal={closeRegisterModal}
        openLoginModal={openLoginModal}
      />
      <Forgot open={forgotOpen} onCloseModal={closeForgotModal} />
    </>
  );
};

export default Login;
