import React from "react";
import "./valueBanner.scss";
import { Link } from "react-router-dom";
import valuebanner from "../../../images/banner-2.png";

const ValueBanner = ({}) => {
  const valuebannerSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  return (
    <>
      <section className="Valuebanner-wrapper">
        <div
          className="value-banner"
          style={{
            height: `auto`,
          }}
        >
          <img src={valuebanner} alt="" />
        </div>
      </section>
    </>
  );
};

export default ValueBanner;
