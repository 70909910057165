import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.scss";
import SideNavbar from "./Side-navbar/SideNavbar";
import CategoriesDrop from "../Categories/CategoriesDrop";
import Login from "../../popups/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getHomePageData,
  getHomeProduct,
  getLiveCartList,
  getProductsData,
  getSiteSettingData,
  getTrendingOnCategory,
  getWishListData,
} from "../../redux/homeAction";
import Select from "react-select";
import { toast } from "react-toastify";
import { SET_SEARCH_LIST } from "../../redux/types";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToWishlist = useSelector((state) => state.home.addToWishlist);
  const getProductsList = useSelector((state) => state.home.getProductsList);
  const categoriesList = useSelector((state) => state.home.categoriesList);
  const removeFromWishlist = useSelector(
    (state) => state.home.removeFromWishlist
  );
  const localCartItems = useSelector((state) => state.home.localCartItems);
  const liveCartItems = useSelector((state) => state.home.liveCartItems);
  const deleteCartItems = useSelector((state) => state.home.deleteCartItems);
  const addToCartItems = useSelector((state) => state.home.addToCartItems);
  const updateCartQty = useSelector((state) => state.home.updateCartQty);
  const orderPlaced = useSelector((state) => state.home.orderPlaced);
  const bookNow = useSelector((state) => state.home.bookNow);
  const appliedCoupon = useSelector((state) => state.home.appliedCoupon);
  const getWishList = useSelector((state) => state.home.getWishList);

  const keypressed = useRef("");
  const keypressedEnter = useRef("");
  const id = useRef("");
  const [search, setSearch] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectsearch, setselectSearch] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [categorieDropOpen, setCategorieDropOpen] = useState(false);
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  const wrapperRef = useRef(null);
  const wrapperRefnew = useRef(null);
  useOutsideAlerter(wrapperRef, wrapperRefnew);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  const refresher = useSelector((state) => state.home.refresher);

  useEffect(() => {
    const data = {
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(getHomeProduct(data));
    dispatch(getHomePageData());
    dispatch(getTrendingOnCategory());
    dispatch(getCategories());
    dispatch(getSiteSettingData());
    dispatch(getProductsData(data));
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      const data = {
        currency_code:
          currencyCode && currencyCode.currencyCode
            ? currencyCode && currencyCode.currencyCode
            : "USD",
      };
      dispatch(getWishListData(data));
    }
  }, [addToWishlist, removeFromWishlist]);

  useEffect(() => {
    const clone =
      getProductsList &&
      getProductsList.map((item) => {
        return { value: item?.slug, label: item?.pname };
      });
    setSearchedProducts(clone);
  }, [getProductsList && getProductsList.length]);

  useEffect(() => {
    if (localStorage.getItem("user-token")) {
      const data = {
        currency_code:
          currencyCode && currencyCode.currencyCode
            ? currencyCode && currencyCode.currencyCode
            : "USD",
      };
      dispatch(getLiveCartList(data));
    }
  }, [
    deleteCartItems,
    addToCartItems,
    updateCartQty,
    orderPlaced,
    bookNow,
    appliedCoupon,
  ]);

  function useOutsideAlerter(ref, refnew) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !refnew.current.contains(event.target)
        ) {
          setCategorieDropOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#eceff0"
          : undefined,
        color: isDisabled ? "green" : isSelected,

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#eceff0"
            : undefined,
        },
      };
    },

    menu: (provided, state) => ({
      ...provided,

      color: "#666",
      fontSize: "12px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const fontSize = "12px";
      const borderColor = "#eceff0";

      return { ...provided, fontSize, borderColor };
    },
  };

  const productHandler = (adId) => {
    navigate(`/product/${adId}`);
  };

  const calculateQuantity = () => {
    if (localStorage.getItem("user-token")) {
      let totalQty = 0;

      liveCartItems &&
        liveCartItems.forEach((p) => {
          totalQty = totalQty + parseInt(p.qty);
        });

      return totalQty;
    } else {
      let totalQty = 0;

      localCartItems &&
        localCartItems.forEach((p) => {
          totalQty = totalQty + p.qty;
        });

      return totalQty;
    }
  };

  console.log(getWishList);

  return (
    <>
      <div className="navbar-bgwrapp">
        <div className="container">
          <div className="nav-main">
            <div className="column-left">
              <i
                className="fas fa-bars threebar"
                onClick={() => setOpenSidebar(true)}
              ></i>
              {openSidebar && <SideNavbar setOpenSidebar={setOpenSidebar} />}

              <Link to="/">
                <img src="/img/logo.png" alt="#" className="logo-img" />
              </Link>
            </div>
            <div className="column-center">
              <div
                ref={wrapperRefnew}
                className="shop-by-categorywrapp"
                onClick={() => setCategorieDropOpen(!categorieDropOpen)}
              >
                <span>Shop by Category</span>
                {categorieDropOpen && (
                  <CategoriesDrop
                    categoriesList={categoriesList}
                    wrapperRef={wrapperRef}
                  />
                )}
                <i className="fa-solid fa-chevron-down"></i>
              </div>
              <div className="serch-inputwrapper">
                <i className="fa fa-search" aria-hidden="true"></i>
                <Select
                  placeholder="Search Product"
                  styles={customStyles}
                  menuIsOpen={search ? true : false}
                  hideSelectedOptions
                  name="option-select"
                  noOptionsMessage={() => <div>No Products Available</div>}
                  inputValue={search}
                  value={search}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      keypressedEnter.current = e.key;
                    } else {
                      keypressedEnter.current = "";
                    }
                    if (e.key != "Enter") {
                      keypressed.current = e.key;
                    } else if (e.key == "Enter") {
                      dispatch({
                        type: SET_SEARCH_LIST,
                        payload:
                          getProductsList &&
                          getProductsList.filter((filtprod) =>
                            filtprod?.pname
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          ),
                      });
                      navigate(`/searched-item/${e.target.value}`);
                    }
                  }}
                  onInputChange={(e) => {
                    setSearch(e);

                    if (e) {
                      setselectSearch(e);
                    }
                  }}
                  onChange={(e, action) => {
                    id.current = e.value;
                    if (
                      keypressed.current == "ArrowUp" ||
                      keypressed.current == "ArrowDown"
                    ) {
                      productHandler(e.value);
                    } else if (keypressedEnter.current == "") {
                      productHandler(e.value);
                    }
                  }}
                  options={searchedProducts}
                />
                <div
                  className="serch-bartop"
                  onClick={() => {
                    if (!selectsearch) {
                      toast.error("Enter something to search");
                    } else {
                      dispatch({
                        type: SET_SEARCH_LIST,
                        payload:
                          getProductsList &&
                          getProductsList.filter((filtprod) =>
                            filtprod?.pname
                              .toLowerCase()
                              .includes(selectsearch.toLowerCase())
                          ),
                      });
                      navigate(`/searched-item/${selectsearch}`);
                    }
                  }}
                >
                  <button className="serch-btn-top-bg" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="column-right">
              {localStorage.getItem("user-token") ? (
                <Link to="/user/dashboard" className="user-img">
                  <i className="fa-regular fa-user"></i>
                </Link>
              ) : (
                <div className="user-img" onClick={openLoginModal}>
                  <i className="fa-regular fa-user"></i>
                </div>
              )}

              {localStorage.getItem("user-token") ? (
                <Link to="/user/wishlist" className="heart-iconlink">
                  <i className="fa-regular fa-heart hrart-icon"></i>
                  <span className="wishlist-counting">
                    <small>{(getWishList && getWishList.length) || 0}</small>
                  </span>
                </Link>
              ) : (
                <div className="heart-iconlink" onClick={openLoginModal}>
                  <i className="fa-regular fa-heart hrart-icon"></i>
                  <span className="wishlist-counting">
                    <small>{(getWishList && getWishList.length) || 0}</small>
                  </span>
                </div>
              )}

              {localStorage.getItem("user-token") ? (
                <Link to="/cart" className="cart-link">
                  <div className="cart-bgwrap">
                    <img
                      src="/img/cartbag.png"
                      alt="#"
                      className="shoping-icon"
                    />
                    <span className="cart-counting">
                      <small>{calculateQuantity()}</small>
                    </span>
                  </div>
                </Link>
              ) : (
                <Link to="/cart" className="cart-link">
                  <div className="cart-bgwrap">
                    <img
                      src="/img/cartbag.png"
                      alt="#"
                      className="shoping-icon"
                    />
                    <span className="cart-counting">
                      <small>{calculateQuantity()}</small>
                    </span>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <Login
        open={loginOpen}
        onCloseModal={closeLoginModal}
        openLoginModal={openLoginModal}
      />
    </>
  );
};

export default Navbar;
