import React, { useState } from "react";

const YourAddress = ({
  shippingAddress,
  billingAddress,
  selectYourAddress,
  changingTab,
  changingTabValue,
}) => {
  const [selected, setSelected] = useState();

  return (
    <div className="yourAll_address">
      <h4>Select Your Address</h4>
      <div className="billingAddressList">
        <label htmlFor="billing">
          <input
            type="radio"
            id="billing"
            name="billing"
            value="billing"
            onChange={(e) => changingTab(e)}
          />
          <h5>Billing Address</h5>
        </label>

        <label htmlFor="shipping">
          <input
            type="radio"
            id="shipping"
            name="billing"
            value="shipping"
            onChange={(e) => changingTab(e)}
          />
          <h5>Shipping Address</h5>
        </label>
      </div>
      {changingTabValue === "billing" ? (
        <>
          {billingAddress?.length === 0 ? (
            <h6>No Address Found</h6>
          ) : (
            <div className="save-Address-wrapper">
              {billingAddress &&
                billingAddress.map((data, key) => (
                  <div
                    key={key}
                    className={
                      selected === data.id
                        ? "save-Address-wrappActive"
                        : "save-Address-wrapp"
                    }
                    onClick={() => {
                      selectYourAddress(data);
                      setSelected(data.id);
                    }}
                  >
                    <span className="delevar-address">
                      <b>
                        {data.first_name} {data.last_name},
                      </b>
                      <br />
                      {data.address}, {data.city}, {data.state}, {data.zip_code}
                      , {data.country}
                    </span>
                    <br />
                    <span className="delevar-address">{data.phone}</span>
                  </div>
                ))}
            </div>
          )}
        </>
      ) : changingTabValue === "shipping" ? (
        <>
          {shippingAddress?.length === 0 ? (
            <h6>No Address Found</h6>
          ) : (
            <div className="save-Address-wrapper">
              {shippingAddress &&
                shippingAddress.map((data, key) => (
                  <div
                    key={key}
                    className={
                      selected === data.id
                        ? "save-Address-wrappActive"
                        : "save-Address-wrapp"
                    }
                    onClick={() => {
                      selectYourAddress(data);
                      setSelected(data.id);
                    }}
                  >
                    <span className="delevar-address">
                      <b>
                        {data.first_name} {data.last_name},
                      </b>
                      <br />
                      {data.address}, {data.city}, {data.state}, {data.zip_code}
                      , {data.country}
                    </span>
                    <br />
                    <span className="delevar-address">{data.phone}</span>
                  </div>
                ))}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default YourAddress;

{
  /* <select onChange={(e) => selectYourAddress(e)}>
{shippingAddress &&
  shippingAddress.map((data, key) => (
    <option key={key} value={data.id}>
      {data.first_name} {data.last_name}
    </option>
  ))}
</select> */
}

{
  /* <select onChange={(e) => selectYourAddress(e)}>
          {billingAddress &&
            billingAddress.map((data, key) => (
              <option key={key} value={data.id}>
                {data.first_name} {data.last_name}
              </option>
            ))}
        </select> */
}
