import React from "react";
import "./sideOfferBanner.scss";
import Defaultimg from "../../../images/background.png";
import { useNavigate } from "react-router-dom";

const SideOfferBanner = ({}) => {
  return (
    <>
      <section className="SideOfferBanner-wrapper">
        <div className="sideofferinner-wrappepr">
          <div className="spaical-line-wrapper">
            <span className="spaical-linewrapp">Special Offer</span>
            <div className="save-amountWrapper">
              <span className="save-linewrapp">save</span>
              <span className="save-amountwrap">
                currency_sign offer_discount
              </span>
            </div>
          </div>
          <div className="productimgswrapper">
            <img src={Defaultimg} alt="" className="product-img-wrapp" />
            <span className="productlineheadingwrapper">short_description</span>
          </div>
          <div className="price-wrapper">
            <span className="Cureentpricewrapper">currency_sign.s_price</span>
            <del className="lastpricewrapper">currency_sign .p_price</del>
          </div>
          <div className="timeline-wrapper">
            <span className="hurryupline-wrapp">Hurry Up! Offer ends in</span>

            <div className="Times-lineWrapper">
              <div className="timesset-wrapper">
                <div className="Houre-wrapper">
                  <span className="hours-btn"></span>
                  <span className="houre-line">hours</span>
                </div>
                <div className="Houre-wrapper">
                  <span className="hours-btn"></span>
                  <span className="houre-line">minutes</span>
                </div>
                <div className="Houre-wrapper">
                  <span className="hours-btn"></span>
                  <span className="houre-line">seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SideOfferBanner;
