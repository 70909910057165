import React from "react";
import "./Form.scss";

const AddAddress = ({
  handleUpdateAddress,
  handleCountryChange,
  getCountryList,
  onAddressChange,
  handleStateChange,
  getStateList,
  handleCityChange,
  getCityList,
}) => {
  return (
    <>
      <div className="container bg-color">
        <p className="addresheading">Add A New Address</p>
        <form
          className="addreschanges-formwrapp"
          onSubmit={(e) => handleUpdateAddress(e)}
        >
          <label htmlFor="Country">Country</label>
          <div class="select-wrapper">
            <select
              name="country"
              className="countryTab-wrapper"
              onChange={(e) => handleCountryChange(e)}
            >
              <option>Select</option>
              {getCountryList &&
                getCountryList.map((item, key) => (
                  <option value={item.name} key={key}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <input
            type="text"
            className="Addres-input-wrapp"
            placeholder="First Name"
            name="first_name"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            type="text"
            className="Addres-input-wrapp"
            placeholder="Last name"
            name="last_name"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            type="number"
            className="Addres-input-numwrapp"
            placeholder="Phone Number"
            name="phone"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            type="text"
            className="Addres-input-wrapp"
            placeholder="Alternate Mobile Number"
            name="alternate_phone"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            type="text"
            className="Addres-input-numwrapp"
            placeholder="Postal Code"
            name="zip_code"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            className="Addres-input-wrapp"
            type="text"
            placeholder="Address Line 1"
            name="address"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            className="Addres-input-wrapp"
            type="text"
            placeholder="Address Line 2"
            name="address2"
            onChange={(e) => onAddressChange(e)}
          />
          <input
            type="text"
            className="Addres-input-wrapp"
            placeholder="Landmark"
            name="landmark"
            onChange={(e) => onAddressChange(e)}
          />

          <label htmlFor="province">state province region</label>
          <div class="select-wrapper">
            <select
              className="countryTab-wrapper"
              onChange={(e) => handleStateChange(e)}
              name="state"
            >
              <option>Select</option>
              {getStateList &&
                getStateList.map((item, key) => (
                  <option key={key} value={item.state_name}>
                    {item.state_name}
                  </option>
                ))}
            </select>
          </div>
          <label htmlFor="town">town/city</label>
          <div class="select-wrapper">
            <select
              className="countryTab-wrapper"
              onChange={(e) => handleCityChange(e)}
              name="city"
            >
              <option>Select</option>
              {getCityList &&
                getCityList.map((item, key) => (
                  <option key={key} value={item.city_name}>
                    {item.city_name}
                  </option>
                ))}
            </select>
          </div>
          <label htmlFor="town">Type</label>
          <select
            className="countryTab-wrapper"
            name="type"
            onChange={(e) => onAddressChange(e)}
          >
            <option>Select</option>
            <option value="home">Home</option>
            <option value="office">Office</option>
          </select>
          <h5 className="addinstructionsline">Add delivery instructions</h5>
          <p className="Preferencesuse">
            Preferences are used to plan your delivery.However,shipments can
            sometimes can sometimes arrive early or later than planned.
          </p>
          <label>Address Type</label>
          <select
            className="countryTab-wrapper"
            name="address_type"
            onChange={(e) => onAddressChange(e)}
          >
            <option>Select</option>
            <option value="billing">Billing</option>
            <option value="shipping">Shipping</option>
          </select>
          <button className="AddAddressbtn" type="submit">
            Add Address
          </button>
        </form>
      </div>
    </>
  );
};

export default AddAddress;
