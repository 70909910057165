import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./VariationPopup.scss";

const VariationPopup = ({ open, onCloseModal, productDetail }) => {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      classNames={{ modal: "login-popup-modal" }}
    >
      <div className="inner-container-login">
        <div className="title">
          <h2 className="title-text">Select Variation</h2>
        </div>

        <div className="optionsize-wrappers">
          <div className="variation-info">
            <h3>{productDetail && productDetail.pname}</h3>
            <h6>{productDetail && productDetail.pname}</h6>
            <div className="addtobasket-btn">Add to Cart</div>
          </div>
          <div className="sizeoptin-wrapp">
            {productDetail &&
              productDetail.attributes &&
              productDetail.attributes.map((item, key) => (
                <select
                  className="sizeoption-drop"
                  key={key}
                  onChange={(e) => onVaraintionChange(e)}
                >
                  <option>{item.name}</option>
                  {item.values.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VariationPopup;
