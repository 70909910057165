import React from "react";
import "./NewProducts.scss";
import { Link } from "react-router-dom";
import Login from "../../popups/Login/Login";
import Slider from "react-slick";

const NewProducts = () => {


  return (
    <>
      
    </>
  );
};
export default NewProducts;
