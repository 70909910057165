import React from "react";
import ValueBanner from "../theDayValue/valueBanner/ValueBanner";
import Mainproducts from "./mainProducts/Mainproducts";
import "./spacialOffers.scss";
import SideOfferBanner from "./spacialSideBanner/SideOfferBanner";

const SpacialOffers = ({}) => {
  return (
    <>
      <ValueBanner />
      <div className="container">
        <section className="Spacial-offerWrapper">
          <div className="lefttime-wrapper">
            <SideOfferBanner />
          </div>
          <div className="right-sideWrapper">
            <Mainproducts />
          </div>
        </section>
      </div>
    </>
  );
};

export default SpacialOffers;
