import React, { useEffect } from "react";
import { getPagesData } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Home/loader/loader";

const UserAgreement = () => {
  const dispatch = useDispatch();
  const userAgreement = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 21,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div class="registration_bg_change">
        <div class="container">
          <h1>User Agreement</h1>
          {/* <p>
            Whatever you’re buying—large or small, new or refurbished— smileszy
            offers many ways to ensure you get exactly what you want.
          </p> */}
        </div>
      </div>

      <div class="container py-5">
        <div class="Smileszy-Guarantee">
          {userAgreement ? (
            <div
              dangerouslySetInnerHTML={{
                __html: userAgreement && userAgreement.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
          {/* <h3>Purchase Conditions</h3>
          <p>
            When buying an item on our Services, you agree to the Rules &
            policies for buyers and that:
          </p>
          <ul>
            <li class="lh-lg">
              You are responsible for reading the full item listing before
              making a bid or committing to buy,
            </li>
            <li class="lh-lg">
              You enter into a legally binding contract to purchase an item when
              you commit to buy an item, your offer for an item is accepted, or
              if you have the winning bid (or your bid is otherwise accepted),
            </li>
            <li class="lh-lg">
              For motor vehicles and real estate, a bid or offer initiates a
              non-binding transaction representing a buyer's serious expression
              of interest in buying the seller's item and does not create a
              formal contract between the buyer and the seller,
            </li>
            <li class="lh-lg">
              We do not transfer legal ownership of items from the seller to
              you,
            </li>
            <li class="lh-lg">
              Utah Code Annotated § 70A-2-401(2) and Uniform Commercial Code §
              2-401(2) apply to the transfer of ownership between the buyer and
              the seller, unless the buyer and the seller agree otherwise.
            </li>
          </ul>
          <p class="lh-lg">
            If you purchase an item on an Smileszy site that is different from
            your registration site, you are subject to the User Agreement and
            applicable policies of that other Smileszy site with respect to that
            particular purchase, as detailed in the international selling
            policy.
          </p>
          <p>
            When buying an item on our Services, you agree to the{" "}
            <b>Rules and Policy for buyers</b> and that:
          </p>
          <ul>
            <li class="lh-lg">
              Before placing a bid or agreeing to purchase, you must read the
              whole item listing.
            </li>
            <li class="lh-lg">
              When you agree to buy an item, your offer for an item is accepted,
              or you have the winning bid, you enter into a legally binding
              contract to acquire that thing (or your bid is otherwise
              accepted),
            </li>
            <li class="lh-lg">
              A bid or offer commences a non-binding transaction expressing a
              buyer's serious statement of interest in purchasing the seller's
              goods and does not generate a legal contract between the buyer and
              the seller in the case of motor vehicles and real estate.
            </li>
          </ul>
          <b>
            Smileszy policy applicable in case of purchase from using other site
          </b>
          <p class="lh-lg mt-3">
            If you purchase an item on an Smileszy site that is different from
            your registration site, you are subject to the User Agreement and
            applicable policies of Smileszy.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default UserAgreement;
