import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./feedBack.scss";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { onFeedback } from "../../../../redux/homeAction";

const FeedBack = ({ open, onCloseModal, productId }) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState();
  const [followUp, setFollowUp] = useState();
  const [message, setMessage] = useState();
  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const handleFeedback = () => {
    const data = {
      rating: rating,
      discription: message,
      follow_up: followUp,
      product_id: productId.product_id,
      user_id: userDetails && userDetails.id,
    };

    dispatch(onFeedback(data));
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      classNames={{ modal: "login-popup-modal" }}
    >
      <div className="feedback-wrapper">
        <h6 className="give-feedbackline">Give Feedback</h6>
        <span className="whatthink-line">
          What do you think of the editing tool?
        </span>
        <div className="Smiles-wrapper">
          <label>
            <input
              type="radio"
              className="d-none"
              value="1"
              onChange={(e) => setRating(e.target.value)}
            />
            <i className="far fa-sad-tear smiles-bg"></i>
          </label>
          <label>
            <input
              type="radio"
              className="d-none"
              value="2"
              onChange={(e) => setRating(e.target.value)}
            />
            <i className="far fa-frown smiles-bg"></i>
          </label>
          <label>
            <input
              type="radio"
              className="d-none"
              value="3"
              onChange={(e) => setRating(e.target.value)}
            />
            <i className="far fa-meh smiles-bg"></i>
          </label>
          <label>
            <input
              type="radio"
              className="d-none"
              value="4"
              onChange={(e) => setRating(e.target.value)}
            />
            <i className="far fa-smile smiles-bg"></i>
          </label>
          <label>
            <input
              type="radio"
              className="d-none"
              value="5"
              onChange={(e) => setRating(e.target.value)}
            />
            <i className="far fa-grin-alt smiles-bg"></i>
          </label>
        </div>
        <div className="feedback-inpt-wrapper">
          <span className="dohavethoughtline">
            Do you have any thoughts you’d like to share?
          </span>
          <textarea
            cols={25}
            rows={6}
            className="textarea-wrap"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="mayourfeed-wrapper">
          <span className="may-wefollowupline">
            May we follow you up on your feedback?
          </span>
          <div className="feed-radiowrapper">
            <label
              htmlFor="followUpOne"
              className="radiobtn-wrapp"
              onChange={(e) => setFollowUp(e.target.value)}
            >
              <input type="radio" name="yes" id="followUpOne" value="0" /> Yes
            </label>
            <label
              htmlFor="followUpTwo"
              className="radiobtn-wrapp"
              onChange={(e) => setFollowUp(e.target.value)}
            >
              <input type="radio" name="yes" id="followUpTwo" value="1" /> No
            </label>
          </div>
        </div>
        <div className="feed-btn-wrapper">
          <button className="feedback-sent-btn" onClick={handleFeedback}>
            Send
          </button>
          <button className="feedback-cancel-btn">Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedBack;
