import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./smileszyStoreTab.scss";
import DefaultImg from "../../images/background.png";

const SmileszyStoreTab = ({ productDetail }) => {
  return (
    <>
      <div className="products-storetab-wrapper">
        <div className="productstoretab-wrapper">
          <div className="storetabwrpper">
            <img
              src={
                productDetail && productDetail.profile_img
                  ? productDetail && productDetail.profile_img
                  : DefaultImg
              }
              alt="#"
              className="store-tablogo"
            />
            <div className="storetab-details-wrapper">
              <b>{productDetail && productDetail.first_name}</b>
            </div>
          </div>
          <Link to={`/shopdetails/${productDetail && productDetail.store_id}`}>
            <button className="visit-store">visit Store</button>
          </Link>
        </div>
        <div className="store-categorey-details">
          <p className="store-categoryhading">
            Popular categories from this store <small>See all </small>
          </p>
          <div className="category-tabson-store-wrapp">
            {productDetail &&
              productDetail.store_category &&
              productDetail.store_category
                .filter((item, index) => index < 5)
                .map((data, i) => (
                  <span className="storecategory-tab" key={i}>
                    {data.title}
                  </span>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SmileszyStoreTab;
