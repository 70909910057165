import React from "react";
import { Link } from "react-router-dom";
import "./affiliates.scss";

const Affiliates = () => {
  return (
    <>
      {/* <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Affiliates</h1>
        </div>
      </div> */}
      <div className="container">
        <div className="Affiliates-wrapperWrapper">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h1 className="Affiliates-heading">
                Welcome to the Smileszy Partner Network
              </h1>
              <p className="Affiliates-paraa">
                Earn money by driving traffic and prompting sales across one of
                the world's largest and most diverse marketplaces.
              </p>
              <Link to="/vendor-registration" className="vendor-registration">
                Sign up
              </Link>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src="/img/image-login.png"
                alt=""
                className="img-fluid Affiliates-image"
              />
              <img
                src="/img/image-dashboard.png"
                alt=""
                className="img-fluid Affiliates-image"
              />
            </div>
          </div>
          <div className="Smileszy-Partner">
            <h4>Why Smileszy Partner Network?</h4>
            <div className="row Smileszy-PartnerItem">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/aygAAOSw2ipdXCq6/s-l1600.jpg"
                  alt=""
                  className="smileszy-partnerImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  Work with a commerce leader
                </h6>
                <p className="Smileszy-PartnerPara">
                  From global brands to local stands, Smileszy connects millions
                  of buyers and sellers around the world, empowering people and
                  creating opportunity for all.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/tsEAAOSwD~JdXCq5/s-l1600.jpg"
                  alt=""
                  className="smileszy-partnerImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  Leverage deals and promotions
                </h6>
                <p className="Smileszy-PartnerPara">
                  You can earn for traffic you drive to a specific item, but
                  also any traffic that results in a sale. Funneling users to
                  our high-converting landing pages can really move the needle.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/Uq0AAOSw~NRdXCq5/s-l1600.jpg"
                  alt=""
                  className="smileszy-partnerImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  Track, analyze, and optimize
                </h6>
                <p className="Smileszy-PartnerPara">
                  Our portal provides intuitive tools and comprehensive reports
                  that deliver the rich, granular data you need to understand,
                  manage, and improve your results.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/no4AAOSwOmRdXCq5/s-l1600.jpg"
                  alt=""
                  className="smileszy-partnerImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  Get rewarded for innovation
                </h6>
                <p className="Smileszy-PartnerPara">
                  From mobile apps to unique integrations, many of our partners
                  engage and delight buyers by developing experiences that build
                  on our rich platform.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="Smileszy-Partner">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <img
                  src="/img/image-login.png"
                  alt=""
                  className="Smileszy-PartnerLogin"
                />
                <img
                  src="/img/image-dashboard.png"
                  alt=""
                  className="Smileszy-PartnerDashboard"
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <h1 className="More-Portal">More Than Just a Portal</h1>
                <p className="More-PortalPara">
                  Our approachable platform provides the solutions you need to
                  build ads and campaigns, drive traffic to increase revenue,
                  monitor performance, and optimize results.
                </p>
              </div>
            </div>
          </div> */}

          <div className="Smileszy-Partner">
            <h4>How does affiliate marketing work today?</h4>
            <div className="row Smileszy-PartnerItem">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/KyIAAOSwD59dXVmG/s-l1600.png"
                  alt=""
                  className="smileszy-affiliateImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  You decide the best affiliate model based on your specific
                  business
                </h6>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/HF8AAOSw7e9dXVm5/s-l1600.png"
                  alt=""
                  className="smileszy-affiliateImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  You integrate your mobile, web, and social platforms with
                  Smileszy's global inventory
                </h6>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/O88AAOSw9GRdXVm5/s-l1600.png"
                  alt=""
                  className="smileszy-affiliateImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  Your users engage with your platforms and buy Smileszy
                  products
                </h6>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <img
                  src="https://i.ebayimg.com/images/g/HNIAAOSwYMldXVm5/s-l1600.png"
                  alt=""
                  className="smileszy-affiliateImage"
                />
                <h6 className="Smileszy-PartnerHeading">
                  Your revenue increases with each qualified sale
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Affiliates;
