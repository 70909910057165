import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { onContactUs } from "../../redux/homeAction";
import "./contactus.scss";

const Contactpage = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const getSiteSettingDetails = useSelector(
    (state) => state.home.getSiteSettingDetails
  );
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const data = {
      name: values.name,
      email: values.email,
      phone: values.phoneNumber,
      company: values.company,
      message: values.message,
      platform: "web",
    };

    if (!values.name) {
      toast.error("Enter Your Name");
    } else if (!values.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values.email)) {
      toast.error("Invalid Email Address");
    } else if (!values.phoneNumber) {
      toast.error("Enter Your Phone Number");
    } else if (!values.company) {
      toast.error("Enter your Company Name");
    } else if (!values.message) {
      toast.error("Enter Your Message");
    } else {
      dispatch(onContactUs(data, setValues));
    }
  };

  return (
    <>
      <div className="conect-bg">
        <div className="container">
          <div className="row gx-0">
            <div
              className="col-sm-12 col-md-6 col-xl-4 fade-in-bottom"
              id="infoSec"
            >
              <div className="info-bg">
                <div className="location-wrapper">
                  <div>
                    <img src="/img/phone.png" alt="#"></img>
                  </div>
                  <div className="location-texts">
                    <ul>
                      <li className="locationt-hading">Phone Number</li>
                      <li className="contect-details">
                        <a
                          href={`tel:${
                            getSiteSettingDetails &&
                            getSiteSettingDetails.help_number
                          }`}
                          className="pages-link"
                          rel="noopener noreferrer"
                        >
                          {getSiteSettingDetails &&
                            getSiteSettingDetails.help_number}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="location-wrapper">
                  <div>
                    <img src="/img/email.png" alt="#"></img>
                  </div>
                  <div className="location-texts">
                    <ul>
                      <li className="locationt-hading"> Support Email</li>
                      <li className="contect-details">
                        <a
                          href={`mailto:${
                            getSiteSettingDetails && getSiteSettingDetails.email
                          }`}
                          className="pages-link"
                          rel="noopener noreferrer"
                        >
                          {getSiteSettingDetails && getSiteSettingDetails.email}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="social-wrapper">
                  <ul>
                    <li>
                      <a
                        className="social-link"
                        href={
                          getSiteSettingDetails &&
                          getSiteSettingDetails.facebook
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="social-link"
                        href={
                          getSiteSettingDetails &&
                          getSiteSettingDetails.pinterest
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        className="social-link"
                        href={
                          getSiteSettingDetails && getSiteSettingDetails.twitter
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          getSiteSettingDetails &&
                          getSiteSettingDetails.instagram
                        }
                        target="_blank"
                        className="social-link"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Form */}
            <div
              className="col-sm-12  col-md-6 col-xl-8 ps-0 fade-in-bottom"
              id="formSec"
            >
              <form
                className="contact-form-wrapper"
                onSubmit={handleSubmitForm}
              >
                <p className="contact-text">Drop us a Line</p>
                <p className="Get-in-touch"> Get in touch with us</p>
                <div className="form-group row">
                  <div className="col-sm-6 ">
                    <input
                      type="text"
                      className=" contecttextinpt"
                      placeholder="Your Name"
                      name="name"
                      value={values.name}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-sm-6 matchinwl-wrap">
                    <input
                      type="text"
                      className=" contecttextinpt"
                      placeholder="Your Email"
                      name="email"
                      value={values.email}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-sm-6 matchinwl-wrap">
                    <input
                      type="Number"
                      className=" contecttextinpt mobnumcon"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-sm-6 matchinwl-wrap">
                    <input
                      type="text"
                      className="contecttextinpt  "
                      placeholder="Company"
                      name="company"
                      value={values.company}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12">
                    <textarea
                      className=" contect-form-txtrea "
                      rows="6"
                      placeholder="Message"
                      name="message"
                      value={values.message}
                      onChange={onInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 col-xxl-4 col-md-12 col-sm-12 d-flex align-items-center ">
                    <button className="btn send-btn " type="submit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Form section end */}
    </>
  );
};

export default Contactpage;
