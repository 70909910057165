import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { SET_SEARCH_LIST } from "../../../redux/types";
import "./SideNavbar.scss";

const SideNavbar = ({ setOpenSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const keypressed = useRef("");
  const keypressedEnter = useRef("");
  const id = useRef("");
  const [search, setSearch] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [selectsearch, setselectSearch] = useState("");
  const getProductsList = useSelector((state) => state.home.getProductsList);

  useEffect(() => {
    const clone =
      getProductsList &&
      getProductsList.map((item) => {
        return { value: item?.slug, label: item?.pname };
      });
    setSearchedProducts(clone);
  }, [getProductsList && getProductsList.length]);

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? "#eceff0"
          : undefined,
        color: isDisabled ? "green" : isSelected,

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : "#eceff0"
            : undefined,
        },
      };
    },

    menu: (provided, state) => ({
      ...provided,

      color: "#666",
      fontSize: "12px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const fontSize = "12px";
      const borderColor = "#eceff0";

      return { ...provided, fontSize, borderColor };
    },
  };

  const productHandler = (adId) => {
    navigate(`/product/${adId}`);
  };

  return (
    <>
      <section className="Sidebar-wrapper slide-in-left ">
        <div className="Sidelogo-wrapper">
          <Link to="/" onClick={() => setOpenSidebar(false)}>
            <img src="/img/logo.png" alt="#" />
          </Link>
          <i
            className="fas fa-times cut-icon text-light"
            onClick={() => setOpenSidebar(false)}
          ></i>
        </div>

        <div className="sidebar-itemswrapper">
          <ul className="sidebar-menu-items">
            <Link to="#">
              <span className="serchbarweapps">
                <div className="serch-bartopside">
                  <Select
                    placeholder="Search Product"
                    styles={customStyles}
                    menuIsOpen={search ? true : false}
                    hideSelectedOptions
                    name="option-select"
                    noOptionsMessage={() => <div>No Products Available</div>}
                    inputValue={search}
                    value={search}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        keypressedEnter.current = e.key;
                      } else {
                        keypressedEnter.current = "";
                      }
                      if (e.key != "Enter") {
                        keypressed.current = e.key;
                      } else if (e.key == "Enter") {
                        dispatch({
                          type: SET_SEARCH_LIST,
                          payload:
                            getProductsList &&
                            getProductsList.filter((filtprod) =>
                              filtprod?.pname
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            ),
                        });
                        navigate(`/searched-item/${e.target.value}`);
                        setOpenSidebar(false);
                      }
                    }}
                    onInputChange={(e) => {
                      setSearch(e);

                      if (e) {
                        setselectSearch(e);
                      }
                    }}
                    onChange={(e, action) => {
                      id.current = e.value;
                      if (
                        keypressed.current == "ArrowUp" ||
                        keypressed.current == "ArrowDown"
                      ) {
                        productHandler(e.value);
                      } else if (keypressedEnter.current == "") {
                        productHandler(e.value);
                      }
                    }}
                    options={searchedProducts}
                  />
                  <button
                    className="serch-btn-top-bg"
                    onClick={() => {
                      if (!selectsearch) {
                        toast.error("Enter something to search");
                      } else {
                        dispatch({
                          type: SET_SEARCH_LIST,
                          payload:
                            getProductsList &&
                            getProductsList.filter((filtprod) =>
                              filtprod?.pname
                                .toLowerCase()
                                .includes(selectsearch.toLowerCase())
                            ),
                        });
                        navigate(`/searched-item/${selectsearch}`);
                        setOpenSidebar(false);
                      }
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </span>
            </Link>
            <Link to="/" onClick={() => setOpenSidebar(false)}>
              <li className="sidebar-toggle-items">Home</li>
            </Link>
            <Link to="/shops" onClick={() => setOpenSidebar(false)}>
              <li className="sidebar-toggle-items">shop</li>
            </Link>
            <Link to="/sell" onClick={() => setOpenSidebar(false)}>
              <li className="sidebar-toggle-items">sell</li>
            </Link>
            <Link to="/contact" onClick={() => setOpenSidebar(false)}>
              <li className="sidebar-toggle-items">contact</li>
            </Link>
            <Link to="/products" onClick={() => setOpenSidebar(false)}>
              <li className="deliverylocatline">All Categories</li>
            </Link>
          </ul>
        </div>
      </section>
    </>
  );
};

export default SideNavbar;
