import React, { useEffect } from "react";
import "./productView.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import ProductPage from "../../pages/productDetails/ProductPage";
import ProductQuickPage from "./ProductquickPage/ProductQuickPage";

const ProductView = ({ quickView, onCloseModal, currentProduct }) => {
  return (
    <>
      <Modal
        open={quickView}
        onClose={onCloseModal}
        center
        classNames={{ modal: "form-popup-modal" }}
      >
        <div className="inner-container-signup">
          <ProductQuickPage currentProduct={currentProduct} />
        </div>
      </Modal>
    </>
  );
};

export default ProductView;
