import React, { useEffect, useState } from "react";
import "./ProductsDescription.scss";
import UserReview from "../UserReview/UserReview";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackList } from "../../redux/homeAction";
import { useParams } from "react-router-dom";

const ProductsDescription = ({ productDetail }) => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const getFeedback = useSelector((state) => state.home.getFeedback);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    const data = {
      product_id: slug,
    };
    dispatch(getFeedbackList(data));
  }, []);

  return (
    <div className="productdeswrapper">
      <div className="description-link-wrapper">
        <span
          className={`description-heading ${
            currentTab === 0 && "description-active"
          }`}
          onClick={() => setCurrentTab(0)}
        >
          DESCRIPTION
        </span>
        <span
          className={`description-heading ${
            currentTab === 1 && "description-active"
          }`}
          onClick={() => setCurrentTab(1)}
        >
          ADDITIONAL INFORMATION
        </span>
        {getFeedback && getFeedback.length === undefined ? null : (
          <span
            className={`description-heading ${
              currentTab === 2 && "description-active"
            }`}
            onClick={() => setCurrentTab(2)}
          >
            REVIEWS
          </span>
        )}
      </div>
      {currentTab === 0 && (
        <div className="descripton-texts">
          <p
            dangerouslySetInnerHTML={{
              __html: productDetail && productDetail.long_description,
            }}
          ></p>
        </div>
      )}

      {currentTab === 1 && (
        <div className="descripton-texts">
          <p
            dangerouslySetInnerHTML={{
              __html: productDetail && productDetail.short_description,
            }}
          ></p>
        </div>
      )}

      {currentTab === 2 && (
        <div className="descripton-texts">
          <UserReview getFeedback={getFeedback} />
        </div>
      )}
    </div>
  );
};

export default ProductsDescription;
