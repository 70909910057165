import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPagesData } from "../../redux/homeAction";
import "./About.scss";
import Loader from "../Home/loader/loader";

const About = () => {
  const dispatch = useDispatch();
  const aboutUs = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 12,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div className="pages-bg-img">
        <div className="blog_page_style">
          <div className="container">
            <h1 className="blog_page_style_h1">About Us </h1>
          </div>
        </div>
        <div className="container">
          <div className="aboutpage-tag-wrapper">
            {/* <p class="lh-lg">
              Smileszy Limited Is an innovative multi-vendor online e-commerce
              portal offering a variety of products to consumers
            </p>
            <p class="lh-lg">
              throughout the globe. Founded by Mr Smiles a seasoned business
              visionary with an eye towards profit and achievement.
            </p>
            <p class="lh-lg">
              Smileszy organization started is market operations during 2022.
              The timing of market entry is nothing less than exceptional as
              consumers
            </p>
            <p class="lh-lg">
              throughout the globe have begun releasing pent-up spending that
              was held as a result of the global recession due to pandemic.
            </p>
            <p class="lh-lg">
              Online shopping is at an all-time high with new consumer mindsets
              calling for them to shop for the types of deals and bargains
            </p>
            <p class="lh-lg">
              that will be so much a part of the Smileszy business model.{" "}
            </p>
            <p class="lh-lg">
              Smileszy Platform is Easy to register with a user-friendly
              functionality
            </p>
            <ol>
              <li class="lh-lg">
                It has an elastic search to cater to today’s consumer behaviour
              </li>
              <li class="lh-lg">
                The process of adding reviews simple, quick, and device
                compatibility
              </li>
              <li class="lh-lg">it’s easy to set up a payment mechanism</li>
              <li class="lh-lg">
                It has many platform features which actually fit your
                requirements.
              </li>
              <li class="lh-lg">
                Our Vision To revive the domestic market by creating a reliable
                platform that will help both expert and none expert buy and sell
                with easy
              </li>
            </ol>
            <div>
              <h4>Mission Statement</h4>
              <p class="lh-lg">
                The mission of Smileszy Limited is to provide user friendly and
                a reliable online marketplace globally for consumers and provide
                global economic opportunity
              </p>
            </div>
            <div>
              <h4>Company Ownership</h4>
              <p class="lh-lg">
                Smiles is the sole owner of Smileszy Limited There are no other
                parties with the ability to claim an ownership stake in this
              </p>
              <p class="lh-lg">
                venture. It should however be noted that Mr. Smiles E is
                currently soliciting offers from investors or investment groups
                that share
              </p>
              <p class="lh-lg">
                in his long-term vision for profit and success.
              </p>
            </div>
            <div>
              <h4>Business Location</h4>
              <p class="lh-lg">
                Smileszy will be located in London, United Kingdom and has
                branches in Nigeria and with the ability to serve clients
                throughout the globe. Long-term plans are for this to remain the
                base of operations due to the familiarity of the founder with
                the area and the reach that the Internet provides.
              </p>
            </div>
            <div>
              <h4>Products and Services</h4>
              <p class="lh-lg">
                Smileszy Ltd will be a middle-man between the vendors and the
                customers and offer a security Online
              </p>
              <p class="lh-lg">
                marketplace for consumers looking to transact purchases for
                reduced cost items. The organization will have a drop shipping
              </p>
              <p class="lh-lg">
                approach which will greatly enhance the appeal and lower the
                overall overhead costs that are incurred.
              </p>
            </div>
            <div>
              <h4>About the Founder</h4>
              <p class="lh-lg">
                Mr. Smiles E is the founder of Smileszy Ltd. Mr. Smiles is a
                seasoned business professional with the ability to develop
                strategies
              </p>
              <p class="lh-lg">
                that yield optimal financial results. His background in
                Technology will be invaluable as he looks to meticulously create
                a
              </p>
              <p class="lh-lg">
                business model and approach that allow Smileszy to become
                recognized as a true market leader.
              </p>
            </div> */}
            {aboutUs ? (
              <div
                dangerouslySetInnerHTML={{ __html: aboutUs && aboutUs.content }}
              ></div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
