import React from "react";
import "./MyAccounts.scss";

const MyAccounts = ({
  onFileChange,
  onAccountChange,
  values,
  submitAccountForm,
}) => {
  return (
    <>
      <div className="accounts-rightside-wrapper">
        <form className="form-wrapper" onSubmit={submitAccountForm}>
          <div className="file file--upload">
            <label htmlFor="input-file">
              <i className="fa fa-cloud-upload" aria-hidden="true"></i>
              Profile Upload
            </label>
            <input id="input-file" type="file" onChange={onFileChange} />
          </div>
          <label>
            <p>
              First name <span>*</span>
            </p>
            <input
              type="text"
              placeholder="First name"
              name="first_name"
              value={values?.first_name}
              onChange={onAccountChange}
            />
          </label>
          <label>
            <p>
              Last name <span>*</span>
            </p>
            <input
              type="text"
              placeholder="Last name"
              name="last_name"
              value={values?.last_name}
              onChange={onAccountChange}
            />
          </label>
          <label>
            <p>
              Display name <span>*</span>
            </p>
            <input
              type="text"
              placeholder="Display name"
              name="name"
              value={values?.name}
              onChange={onAccountChange}
            />
          </label>
          <label>
            <p>
              Email address <span>*</span>
            </p>
            <input
              type="text"
              placeholder="Email address"
              name="email"
              value={values?.email}
              onChange={onAccountChange}
              disabled={values?.email === undefined ? false : true}
            />
          </label>
          <label>
            <p>Phone</p>
            <input
              type="number"
              placeholder="Phone"
              name="phone"
              value={values?.phone}
              onChange={onAccountChange}
              onWheel={(e) => e.target.blur()}
            />
          </label>
          <label>
            <p>Current password</p>
            <input
              type="password"
              placeholder="Current password"
              name="currentPassword"
              value={values?.currentPassword}
              onChange={onAccountChange}
            />
          </label>
          <label>
            <p>New password</p>
            <input
              type="password"
              placeholder="New password"
              name="newPassword"
              value={values?.newPassword}
              onChange={onAccountChange}
            />
          </label>
          <label>
            <p>Confirm New Password</p>
            <input
              type="password"
              placeholder="Confirm New Password"
              name="confirmNewPassword"
              value={values?.confirmNewPassword}
              onChange={onAccountChange}
            />
          </label>
          <button className="save-changes-btn" type="submit">
            Save Change
          </button>
        </form>
      </div>
    </>
  );
};

export default MyAccounts;
