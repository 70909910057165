import React, { useEffect } from "react";
import { getPagesData } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Home/loader/loader";

const Registration = () => {
  const dispatch = useDispatch();
  const registration = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 15,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <div>
      <div className="registration_bg">
        <div className="container">
          <h1>Your Smileszy Account</h1>
          <p>
            It takes just a few minutes to create an Smileszy account. Once your
            account is set up, you can buy and sell, send messages to other
            Smileszy members, and leave feedback for buyers and sellers.
          </p>
        </div>
      </div>
      <div className="container">
        {registration ? (
          <div
            dangerouslySetInnerHTML={{
              __html: registration && registration.content,
            }}
          ></div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default Registration;
