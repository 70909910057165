import React from "react";
import "./rendomPage.scss";
import { Link } from "react-router-dom";

const Rendompage = () => {
  return (
    <>
      <section className="rendomPagebg">
        <div className="container">
          <div className="rendompagewrapper">
            <h3 className="bigtextline">
              <b>404</b>
            </h3>
            {/* <p>OOPS! NOTHING WAS FOUND</p> */}
            <p className="wearesorrylines">
              we are sorry but the page you requested was not found
            </p>
            <div className="rendompagrbtn-wrapp">
              <Link to="/">
                <button className="rendomgohomebtn">Go Home</button>
              </Link>
              <Link to="/contact">
                <button className="rendomcontect-btn">Contact Us</button>
              </Link>
            </div>
            <div className="rendom-social-wrapper">
              <ul>
                <li>
                  <a
                    className="social-link"
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="social-link"
                    href="https://in.pinterest.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="social-link"
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    className="social-link"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rendompage;
