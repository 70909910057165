import React, { useState, useEffect } from "react";
import "./UserDeshbord.scss";
import MyAccounts from "../MyAccounts";
import Address from "./Address/Address";
import Deshbordpage from "./Desboardpage/Deshbordpage";
import FeedBack from "./feedback/FeedBack";
import Mywallet from "./MyWallet/Mywallet";
import OrderDetailspage from "./OrderDetailspage/OrderDetailspage";
import OrderPage from "./OrderPage/OrderPage";
import OrderTrack from "./ordertrack/OrderTrack";
import PaymetsCard from "./paymentscard/PaymetsCard";
import MyBids from "./mybids/MyBids";
import MyBooking from "./mybooking/MyBooking";
import WishList from "./wishList/WishList";
import AddAddress from "../../addresschange/addressform/AddAddress";
import EditAddress from "../../addresschange/addressform/EditAddress";
import { useDispatch, useSelector } from "react-redux";
import {
  addCard,
  allWalletTransection,
  bookedServices,
  cancelOrder,
  deleteCard,
  getAccountData,
  getAddressData,
  getAllCard,
  getAllCity,
  getAllCountries,
  getAllState,
  getAllWinningBidsList,
  onDeleteAddressData,
  onEditAccountData,
  onEditAddressData,
  onRemoveFromWishList,
  orderHistory,
} from "../../../redux/homeAction";
import { useNavigate, useParams } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import Loader from "../../Home/loader/loader";
import { toast } from "react-toastify";

const UserDeshbord = () => {
  const { params } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [addressValues, setAddressValues] = useState({});
  const [loader, setLoader] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [currentTab, setCurrentTab] = useState("dashboard");
  const [editAddress, setEditAddress] = useState();
  const updateAccount = useSelector((state) => state.home.updateAccount);
  const deleteAddress = useSelector((state) => state.home.deleteAddress);
  const updateAddress = useSelector((state) => state.home.updateAddress);
  const getWishList = useSelector((state) => state.home.getWishList);
  const getCountryList = useSelector((state) => state.home.getCountryList);
  const getStateList = useSelector((state) => state.home.getStateList);
  const getCityList = useSelector((state) => state.home.getCityList);
  const getOrderHistory = useSelector((state) => state.home.getOrderHistory);
  const getAllCardList = useSelector((state) => state.home.getAllCardList);
  const addPaymentCard = useSelector((state) => state.home.addPaymentCard);
  const deleteCardList = useSelector((state) => state.home.deleteCardList);
  const winningBidList = useSelector((state) => state.home.winningBidList);
  const addedWallet = useSelector((state) => state.home.addedWallet);
  const orderCancel = useSelector((state) => state.home.orderCancel);
  const getWalletTransection = useSelector(
    (state) => state.home.getWalletTransection
  );

  const getBookedServices = useSelector(
    (state) => state.home.getBookedServices
  );

  const getAccountDetails = useSelector(
    (state) => state.home.getAccountDetails
  );
  const billingAddress = useSelector(
    (state) => state?.home?.getAddressList?.billing
  );
  const shippingAddress = useSelector(
    (state) => state?.home?.getAddressList?.shipping
  );
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;
  var numberRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  useEffect(() => {
    if (getAccountDetails) {
      setValues(getAccountDetails);
    }
  }, [getAccountDetails]);

  useEffect(() => {
    if (editAddress) {
      setAddressValues(editAddress);
    }
  }, [editAddress]);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [updateAccount]);

  useEffect(() => {
    setCurrentTab(params);
  }, [params]);

  useEffect(() => {
    const data = {
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(getAllCountries());
    dispatch(getAllWinningBidsList(data));
  }, []);

  useEffect(() => {
    const data = {
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(orderHistory(data));
  }, [orderCancel]);

  useEffect(() => {
    dispatch(getAllCard());
  }, [deleteCardList, addPaymentCard]);

  useEffect(() => {
    dispatch(getAccountData());
  }, [updateAccount]);

  useEffect(() => {
    dispatch(getAddressData());
  }, [deleteAddress, updateAddress]);

  useEffect(() => {
    dispatch(bookedServices());
  }, []);

  useEffect(() => {
    dispatch(allWalletTransection());
  }, [addedWallet]);

  const handleLogout = () => {
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-details");
    window.location.replace("/");
  };

  const onAccountChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await ConvertBase64(file);
    setImageFile(base64.slice(23, base64.length));
  };

  const ConvertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const submitAccountForm = (e) => {
    e.preventDefault();

    const data = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      name: values?.name,
      email: values?.email,
      phone: values?.phone,
      password: values?.newPassword,
      profile: imageFile,
    };

    if (!values?.first_name) {
      toast.error("Enter First Name");
    } else if (!values.last_name) {
      toast.error("Enter Last Name");
    } else if (!values?.name) {
      toast.error("Enter Your Display Name");
    } else if (!values.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values.email)) {
      toast.error("Invalid Email Address");
    } else if (!values?.phone) {
      toast.error("Enter Phone Number");
    } else {
      dispatch(onEditAccountData(data));
    }
  };

  const handleDeleteAddress = (id) => {
    const data = {
      id: id,
    };
    dispatch(onDeleteAddressData(data));
  };

  const deleteFromWishlist = (id) => {
    const data = {
      product_id: id,
    };

    dispatch(onRemoveFromWishList(data));
  };

  const handleAddCard = (token) => {
    const data = {
      src_token: token.id,
    };
    dispatch(addCard(data));
  };

  const handleDeleteCard = (id) => {
    const data = {
      card_id: id,
    };
    dispatch(deleteCard(data));
  };

  const handleUpdateAddress = (e) => {
    e.preventDefault();

    const data = {
      id: editAddress?.id,
      first_name: addressValues?.first_name,
      last_name: addressValues?.last_name,
      country: addressValues?.country,
      phone: addressValues?.phone,
      alternate_phone: addressValues?.alternate_phone,
      zip_code: addressValues?.zip_code,
      address: addressValues?.address,
      address2: addressValues?.address2,
      landmark: addressValues?.landmark,
      city: addressValues?.city,
      state: addressValues?.state,
      title: addressValues?.type,
      address_type: addressValues?.address_type,
    };

    if (!addressValues?.country) {
      toast.error("Select Your Country");
    } else if (!addressValues?.first_name) {
      toast.error("Enter First Name");
    } else if (!addressValues?.last_name) {
      toast.error("Enter Last Name");
    } else if (!addressValues?.phone) {
      toast.error("Enter Phone Number");
    } else if (!addressValues?.zip_code) {
      toast.error("Enter Postal Code");
    } else if (!addressValues?.address) {
      toast.error("Enter Address Line 1");
    } else if (!addressValues?.landmark) {
      toast.error("Enter Your Landmark");
    } else if (!addressValues?.state) {
      toast.error("Select Your State");
    } else if (!addressValues?.city) {
      toast.error("Select Your City");
    } else if (!addressValues?.type) {
      toast.error("Select Your Home Address or Office Address");
    } else if (!addressValues?.address_type) {
      toast.error("Select Your Address type");
    } else {
      dispatch(onEditAddressData(data, navigate, setAddressValues));
    }
  };

  const onAddressChange = (e) => {
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const handleCountryChange = (e) => {
    if (getCountryList) {
      for (let i = 0; i < getCountryList.length; i++) {
        if (getCountryList[i].name === e.target.value) {
          const data = {
            country_id: getCountryList[i].id,
          };
          dispatch(getAllState(data));
        }
      }
    }
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const handleStateChange = (e) => {
    if (getStateList) {
      for (let i = 0; i < getStateList.length; i++) {
        if (getStateList[i].state_name === e.target.value) {
          const data = {
            state_id: getStateList[i].state_id,
          };
          dispatch(getAllCity(data));
        }
      }
    }
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const handleCityChange = (e) => {
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const editFormHandler = (data) => {
    setCurrentTab("editaddress");
    navigate("/user/editaddress");
    setEditAddress(data);
  };

  return (
    <>
      {loader === false ? (
        <div className="UserDeshbord-bg">
          <div className="container">
            <section className="deshbordtabs-wrapper">
              <UserSidebar
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                handleLogout={handleLogout}
                getAccountDetails={getAccountDetails}
              />
              <div className="right-side-wrapp">
                {currentTab === "dashboard" && (
                  <Deshbordpage
                    handleLogout={handleLogout}
                    getOrderHistory={getOrderHistory}
                    setCurrentTab={setCurrentTab}
                  />
                )}
                {currentTab === "myaccount" && (
                  <MyAccounts
                    values={values}
                    onFileChange={onFileChange}
                    onAccountChange={onAccountChange}
                    submitAccountForm={submitAccountForm}
                  />
                )}
                {currentTab === "myorders" && (
                  <OrderPage
                    setCurrentTab={setCurrentTab}
                    getOrderHistory={getOrderHistory}
                  />
                )}
                {currentTab === "mybids" && (
                  <MyBids winningBidList={winningBidList} />
                )}
                {currentTab === "mybooking" && (
                  <MyBooking
                    getBookedServices={getBookedServices}
                    setCurrentTab={setCurrentTab}
                  />
                )}
                {currentTab === "payments" && (
                  <PaymetsCard
                    handleAddCard={handleAddCard}
                    getAllCardList={getAllCardList}
                    handleDeleteCard={handleDeleteCard}
                  />
                )}
                {currentTab === "address" && (
                  <Address
                    setCurrentTab={setCurrentTab}
                    handleDeleteAddress={handleDeleteAddress}
                    billingAddress={billingAddress}
                    shippingAddress={shippingAddress}
                    editFormHandler={editFormHandler}
                  />
                )}
                {currentTab === "mywallet" && (
                  <Mywallet getWalletTransection={getWalletTransection} />
                )}
                {currentTab === "wishlist" && (
                  <WishList
                    getWishList={getWishList}
                    deleteFromWishlist={deleteFromWishlist}
                  />
                )}
                {currentTab === "orderdetails" && (
                  <OrderDetailspage
                    getOrderHistory={getOrderHistory}
                    setCurrentTab={setCurrentTab}
                  />
                )}
                {currentTab === "ordertrack" && (
                  <OrderTrack getOrderHistory={getOrderHistory} />
                )}
                {currentTab === "addaddress" && (
                  <AddAddress
                    handleUpdateAddress={handleUpdateAddress}
                    handleCountryChange={handleCountryChange}
                    getCountryList={getCountryList}
                    addressValues={addressValues}
                    onAddressChange={onAddressChange}
                    handleStateChange={handleStateChange}
                    getStateList={getStateList}
                    handleCityChange={handleCityChange}
                    getCityList={getCityList}
                  />
                )}
                {currentTab === "editaddress" && (
                  <EditAddress
                    handleUpdateAddress={handleUpdateAddress}
                    handleCountryChange={handleCountryChange}
                    getCountryList={getCountryList}
                    addressValues={addressValues}
                    onAddressChange={onAddressChange}
                    handleStateChange={handleStateChange}
                    getStateList={getStateList}
                    handleCityChange={handleCityChange}
                    getCityList={getCityList}
                  />
                )}
                {/*{currentTab === "" && <FeedBack />}
              {currentTab === "" && <OrderReturn />}
              */}
              </div>
            </section>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserDeshbord;
