import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addCard,
  addOrder,
  getAddressData,
  getAllCard,
  getAllCity,
  getAllCountries,
  getAllState,
  onApplyCoupon,
  onShippingMethod,
} from "../../redux/homeAction";
import BillingDetails from "./BillingDetails";
import "./checkout.scss";
import DiffrentAddress from "./DiffrentAddress";
import YourAddress from "./YourAddress";
import YourOrder from "./YourOrder";
import { useNavigate } from "react-router-dom";
import Loader from "../Home/loader/loader";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [changingTabValue, setChagingTabValue] = useState();
  const [addressValues, setAddressValues] = useState({});
  const [couponCode, setCouponCode] = useState();
  const [diffAddressValues, setDiffAddressValues] = useState({});
  const [diffrentAddress, setDiffrentAddress] = useState(false);
  const [stripeToken, setStripeToken] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [shippingPrice, setShippingPrice] = useState();
  const getCountryList = useSelector((state) => state.home.getCountryList);
  const getStateList = useSelector((state) => state.home.getStateList);
  const getCityList = useSelector((state) => state.home.getCityList);
  const getAllCardList = useSelector((state) => state.home.getAllCardList);
  const addPaymentCard = useSelector((state) => state.home.addPaymentCard);
  const orderPlaced = useSelector((state) => state.home.orderPlaced);
  const appliedCoupon = useSelector((state) => state.home.appliedCoupon);
  const fixedFreeShipping = useSelector(
    (state) => state.home.fixedFreeShipping
  );
  const liveCartItemsList = useSelector(
    (state) => state.home.liveCartItemsList
  );
  const billingAddress = useSelector(
    (state) => state?.home?.getAddressList?.billing
  );
  const shippingAddress = useSelector(
    (state) => state?.home?.getAddressList?.shipping
  );
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (getCountryList) {
      for (let i = 0; i < getCountryList.length; i++) {
        if (getCountryList[i].name === addressValues.country) {
          const data = {
            country_id: getCountryList[i].id,
          };
          dispatch(getAllState(data));
        }
      }
    } else {
      if (getStateList) {
        for (let i = 0; i < getStateList.length; i++) {
          if (getStateList[i].state_name === addressValues.state) {
            const data = {
              state_id: getStateList[i].state_id,
            };
            dispatch(getAllCity(data));
          }
        }
      }
    }
  }, [addressValues]);

  useEffect(() => {
    dispatch(getAddressData());
    dispatch(getAllCountries());
    dispatch(onShippingMethod());
  }, []);

  useEffect(() => {
    dispatch(getAllCard());
  }, [addPaymentCard]);

  const onAddressChange = (e) => {
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const addressCountryChange = (e) => {
    if (getCountryList) {
      for (let i = 0; i < getCountryList.length; i++) {
        if (getCountryList[i].name === e.target.value) {
          const data = {
            country_id: getCountryList[i].id,
          };
          dispatch(getAllState(data));
        }
      }
    }
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const addressStateChange = (e) => {
    if (getStateList) {
      for (let i = 0; i < getStateList.length; i++) {
        if (getStateList[i].state_name === e.target.value) {
          const data = {
            state_id: getStateList[i].state_id,
          };
          dispatch(getAllCity(data));
        }
      }
    }
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const addressCityChange = (e) => {
    setAddressValues({ ...addressValues, [e.target.name]: e.target.value });
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();

    if (!addressValues?.first_name) {
      toast.error("Enter Your First Name");
    } else if (!addressValues?.last_name) {
      toast.error("Enter Your Last Name");
    } else if (!addressValues?.country) {
      toast.error("Select Your Country");
    } else if (!addressValues?.address) {
      toast.error("Enter Your Street Address");
    } else if (!addressValues?.address2) {
      toast.error("Enter Area Colony or Street");
    }
    // else if (!addressValues?.landmark) {
    //   toast.error("Enter Your Landmark");
    // }
    else if (!addressValues?.state) {
      toast.error("Select Your State");
    } else if (!addressValues?.city) {
      toast.error("Select Your City");
    } else if (!addressValues?.zip_code) {
      toast.error("Enter Your Zip Code");
    } else if (!addressValues?.phone) {
      toast.error("Enter Your Phone Number");
    }
    // else if (!addressValues?.alternate_phone) {
    //   toast.error("Enter Alternate Number");
    // }
    else if (diffrentAddress) {
      if (!diffAddressValues?.first_name) {
        toast.error("Enter Your First Name");
      } else if (!diffAddressValues?.last_name) {
        toast.error("Enter Your Last Name");
      } else if (!diffAddressValues?.country) {
        toast.error("Select Your Country");
      } else if (!diffAddressValues?.address) {
        toast.error("Enter Your Street Address");
      } else if (!diffAddressValues?.address2) {
        toast.error("Enter Area Colony or Street");
      }
      // else if (!diffAddressValues?.landmark) {
      //   toast.error("Enter Your Landmark");
      // }
      else if (!diffAddressValues?.state) {
        toast.error("Select Your State");
      } else if (!diffAddressValues?.city) {
        toast.error("Select Your City");
      } else if (!diffAddressValues?.zip_code) {
        toast.error("Enter Your Zip Code");
      } else if (!diffAddressValues?.phone) {
        toast.error("Enter Your Phone Number");
      }
      // else if (!diffAddressValues?.alternate_phone) {
      //   toast.error("Enter Alternate Number");
      // }
      // else if (!shippingPrice) {
      //   toast.error("Select Shipping Method");
      // }
      else if (!paymentMethod) {
        toast.error("Select Payment Method");
      } else if (!stripeToken) {
        toast.error("Select Your Card");
      } else {
        const data = {
          shipping_price: liveCartItemsList && liveCartItemsList.shipping,
          subtotPrice:
            appliedCoupon && appliedCoupon.status === true
              ? appliedCoupon && appliedCoupon.total_amount
              : liveCartItemsList && liveCartItemsList.subtotal,
          gift_card_amount: "0",
          totPrice:
            appliedCoupon && appliedCoupon.status === true
              ? appliedCoupon && appliedCoupon.total_amount
              : liveCartItemsList && liveCartItemsList.total,
          // cart_id: "5",
          stripe_token: stripeToken,
          gift_card: "",
          coupon_code: "",
          currency_code:
            currencyCode && currencyCode.currencyCode
              ? currencyCode && currencyCode.currencyCode
              : "USD",
          refund_amount_in: "bank",
          shipping_method: paymentMethod,
          shipping: [
            {
              store_id: 13,
              store_name: "vendor",
              title: "Normal Shipping",
              ship_price: "2",
            },
          ],
          currency_sign:
            currencyCode && currencyCode.sign
              ? currencyCode && currencyCode.sign
              : "$",
          shipping_address: {
            first_name: addressValues?.first_name,
            last_name: addressValues?.last_name,
            phone: addressValues?.phone,
            alternate_phone: addressValues?.alternate_phone,
            address: addressValues?.address,
            address2: addressValues?.address2,
            address_type: addressValues?.address_type,
            city: addressValues?.city,
            country: addressValues?.country,
            state: addressValues?.state,
            zip_code: addressValues?.zip_code,
            landmark: addressValues?.landmark,
          },
          billing_address: {
            first_name: diffAddressValues?.first_name,
            last_name: diffAddressValues?.last_name,
            phone: diffAddressValues?.phone,
            alternate_phone: diffAddressValues?.alternate_phone,
            address: diffAddressValues?.address,
            address2: diffAddressValues?.address2,
            address_type: diffAddressValues?.address_type,
            city: diffAddressValues?.city,
            country: diffAddressValues?.country,
            state: diffAddressValues?.state,
            zip_code: diffAddressValues?.zip_code,
            landmark: diffAddressValues?.landmark,
          },
        };
        dispatch(addOrder(data, navigate));
      }
    }
    // else if (!shippingPrice) {
    //   toast.error("Select Shipping Method");
    // }
    else if (!paymentMethod) {
      toast.error("Select Payment Method");
    } else if (!stripeToken) {
      toast.error("Select Your Card");
    } else {
      const data = {
        shipping_price: liveCartItemsList && liveCartItemsList.shipping,
        subtotPrice:
          appliedCoupon && appliedCoupon.status === true
            ? appliedCoupon && appliedCoupon.total_amount
            : liveCartItemsList && liveCartItemsList.subtotal,
        gift_card_amount: "0",
        totPrice:
          appliedCoupon && appliedCoupon.status === true
            ? appliedCoupon && appliedCoupon.total_amount
            : liveCartItemsList && liveCartItemsList.total,
        // cart_id: "5",
        stripe_token: stripeToken,
        gift_card: "",
        coupon_code: "",
        currency_code:
          currencyCode && currencyCode.currencyCode
            ? currencyCode && currencyCode.currencyCode
            : "USD",
        refund_amount_in: "bank",
        shipping_method: paymentMethod,
        shipping: [
          {
            store_id: 13,
            store_name: "vendor",
            title: "Normal Shipping",
            ship_price: "2",
          },
        ],
        currency_sign:
          currencyCode && currencyCode.sign
            ? currencyCode && currencyCode.sign
            : "$",
        shipping_address: {
          first_name: addressValues?.first_name,
          last_name: addressValues?.last_name,
          phone: addressValues?.phone,
          alternate_phone: addressValues?.alternate_phone,
          address: addressValues?.address,
          address2: addressValues?.address2,
          address_type: addressValues?.address_type,
          city: addressValues?.city,
          country: addressValues?.country,
          state: addressValues?.state,
          zip_code: addressValues?.zip_code,
          landmark: addressValues?.landmark,
        },
        billing_address: {
          first_name: addressValues?.first_name,
          last_name: addressValues?.last_name,
          phone: addressValues?.phone,
          alternate_phone: addressValues?.alternate_phone,
          address: addressValues?.address,
          address2: addressValues?.address2,
          address_type: addressValues?.address_type,
          city: addressValues?.city,
          country: addressValues?.country,
          state: addressValues?.state,
          zip_code: addressValues?.zip_code,
          landmark: addressValues?.landmark,
        },
      };
      dispatch(addOrder(data, navigate));
    }
  };

  const diffAddressChange = (e) => {
    setDiffAddressValues({
      ...diffAddressValues,
      [e.target.name]: e.target.value,
    });
  };

  const diffAddressCountryChange = (e) => {
    if (getCountryList) {
      for (let i = 0; i < getCountryList.length; i++) {
        if (getCountryList[i].name === e.target.value) {
          const data = {
            country_id: getCountryList[i].id,
          };
          dispatch(getAllState(data));
        }
      }
    }
    setDiffAddressValues({
      ...diffAddressValues,
      [e.target.name]: e.target.value,
    });
  };

  const diffAddressStateChange = (e) => {
    if (getStateList) {
      for (let i = 0; i < getStateList.length; i++) {
        if (getStateList[i].state_name === e.target.value) {
          const data = {
            state_id: getStateList[i].state_id,
          };
          dispatch(getAllCity(data));
        }
      }
    }
    setDiffAddressValues({
      ...diffAddressValues,
      [e.target.name]: e.target.value,
    });
  };

  const diffAddressCityChange = (e) => {
    setDiffAddressValues({
      ...diffAddressValues,
      [e.target.name]: e.target.value,
    });
  };

  const changingTab = (e) => {
    setChagingTabValue(e.target.value);
  };

  const selectYourAddress = (address) => {
    setAddressValues(address);
  };

  const handleAddCard = (token) => {
    const data = {
      src_token: token.id,
    };
    dispatch(addCard(data));
  };

  const selectPaymentCard = (card) => {
    setStripeToken(card.id);
  };

  const selectPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const appleCouponCode = (e) => {
    e.preventDefault();

    if (!couponCode) {
      toast.error("Enter Your Code");
    } else {
      const data = {
        coupon_code: couponCode,
        total_price: liveCartItemsList && liveCartItemsList.total,
      };
      dispatch(onApplyCoupon(data));
    }
  };

  const onCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  return (
    <>
      {loader === true ? (
        <Loader />
      ) : (
        <div className="checkoutbg">
          <div className="container">
            <div className="checkout-form-wrapper">
              <div className="billingFormwrapper">
                <YourAddress
                  shippingAddress={shippingAddress}
                  billingAddress={billingAddress}
                  changingTab={changingTab}
                  changingTabValue={changingTabValue}
                  selectYourAddress={selectYourAddress}
                />

                <p>BILLING DETAILS</p>
                <BillingDetails
                  addressValues={addressValues}
                  getCountryList={getCountryList}
                  getStateList={getStateList}
                  getCityList={getCityList}
                  onAddressChange={onAddressChange}
                  addressCountryChange={addressCountryChange}
                  addressStateChange={addressStateChange}
                  addressCityChange={addressCityChange}
                />
                <div className="Shiptowrapper">
                  <div className="checkdifferent">
                    <input
                      type="checkbox"
                      onChange={() => setDiffrentAddress(!diffrentAddress)}
                    />
                    <span>Ship to a different address</span>
                  </div>
                  {diffrentAddress && (
                    <DiffrentAddress
                      diffAddressValues={diffAddressValues}
                      getCountryList={getCountryList}
                      getStateList={getStateList}
                      getCityList={getCityList}
                      diffAddressChange={diffAddressChange}
                      diffAddressCountryChange={diffAddressCountryChange}
                      diffAddressStateChange={diffAddressStateChange}
                      diffAddressCityChange={diffAddressCityChange}
                    />
                  )}
                </div>
              </div>
              <YourOrder
                handlePlaceOrder={handlePlaceOrder}
                getAllCardList={getAllCardList}
                handleAddCard={handleAddCard}
                selectPaymentCard={selectPaymentCard}
                selectPaymentMethod={selectPaymentMethod}
                orderPlaced={orderPlaced}
                liveCartItemsList={liveCartItemsList}
                appleCouponCode={appleCouponCode}
                onCouponChange={onCouponChange}
                couponCode={couponCode}
                appliedCoupon={appliedCoupon}
                fixedFreeShipping={fixedFreeShipping}
                setShippingPrice={setShippingPrice}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
