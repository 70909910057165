import React from "react";
import { useNavigate } from "react-router-dom";
import "./Address.scss";

const Address = ({
  setCurrentTab,
  billingAddress,
  handleDeleteAddress,
  shippingAddress,
  editFormHandler,
}) => {
  const navigate = useNavigate();

  return (
    <div className="Address-wrapper">
      <div className="Addressrow-wrapper">
        <div
          className="add-Address-wrapp"
          onClick={() => {
            setCurrentTab("addaddress");
            navigate("/user/addaddress");
          }}
        >
          <i className="fas fa-plus"></i>
          <h6>Add A New Address</h6>
        </div>

        {billingAddress &&
          billingAddress.map((data, key) => (
            <div className="save-Address-wrapp" key={key}>
              <div className="user-address-details">
                <h6>
                  {data.first_name} {data.last_name}
                </h6>
                <p className="delevar-address">{data.address}</p>
                <p className="delevar-address">
                  {data.city}, {data.state}, {data.zip_code}
                </p>
                <span className="delevar-address">{data.country}</span>
                <span className="delevar-address">{data.phone}</span>
              </div>
              <div className="edit-btns-wrapp">
                <button
                  className="edit-btn"
                  onClick={() => editFormHandler(data)}
                >
                  Edit
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleDeleteAddress(data.id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}

        {shippingAddress &&
          shippingAddress.map((data, key) => (
            <div className="save-Address-wrapp" key={key}>
              <div className="user-address-details">
                <h6>
                  {data.first_name} {data.last_name}
                </h6>
                <p className="delevar-address">{data.address}</p>
                <p className="delevar-address">
                  {data.city}, {data.state}, {data.zip_code}
                </p>
                <span className="delevar-address">{data.country}</span>
                <span className="delevar-address">{data.phone}</span>
              </div>
              <div className="edit-btns-wrapp">
                <button
                  className="edit-btn"
                  onClick={() => editFormHandler(data)}
                >
                  Edit
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleDeleteAddress(data.id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Address;
