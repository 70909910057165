import React from "react";
import { Link } from "react-router-dom";
import "./ProductCategories.scss";

const ProductCategories = ({
  categoriesList,
  openCategory,
  closeCategory,
  categoryId,
  closeSidebar,
}) => {
  return (
    <>
      <div className="productscategory-wrapper">
        <p className="product-heading">Product Categories</p>
        <div className="product-category-itmes-wrapper">
          <ul>
            {categoriesList &&
              categoriesList.map((data, index) => (
                <li key={index}>
                  <label>
                    <Link
                      className="text-dark"
                      to={`/category/${data.slug}`}
                      onClick={closeSidebar}
                    >
                      <span>{data.title}</span>
                    </Link>
                    {categoryId == data.id ? (
                      <i
                        className="fa fa-minus"
                        aria-hidden="true"
                        onClick={() => closeCategory(data.id)}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-plus"
                        aria-hidden="true"
                        onClick={() => openCategory(data)}
                      ></i>
                    )}
                  </label>

                  {categoryId == data.id && (
                    <>
                      {data.child_category.map((item, index) => (
                        <Link
                          to={`/category/${item.slug}`}
                          onClick={closeSidebar}
                        >
                          <div key={index}>
                            <p>{item.title}</p>
                          </div>
                        </Link>
                      ))}
                    </>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProductCategories;
