import React, { useEffect, useState } from "react";
import BestSellers from "../BESTSELLERS/BestSellers";
import Testimonial from "../Testimonial/Testimonial";
import "./Home.scss";
import { Link } from "react-router-dom";
import NewProduct from "../NEW PRODUCTS/NewProduct";
import HomeBanner from "./HomeBanner/HomeBanner";
import TrendingOn from "../TrendingOnSmile/TrendingOn";
import BestDeals from "../DealsPage/TodayDeals/BestDeals";
import { useDispatch, useSelector } from "react-redux";
import {
  onAddToCartList,
  onAddToWishList,
  onRemoveFromWishList,
  refresherAction,
  setLocalCartItems,
} from "../../redux/homeAction";
import Loader from "../Home/loader/loader";
import { toast } from "react-toastify";

const Home = () => {
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  const homeProductList = useSelector((state) => state.home.homeProductList);
  const homePageData = useSelector((state) => state.home.homePageData);
  const getWishList = useSelector((state) => state.home.getWishList);
  const categoriesList = useSelector((state) => state.home.categoriesList);
  const getTrendingOnList = useSelector(
    (state) => state.home.getTrendingOnList
  );
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );

          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  return (
    <>
      {homeProductList ? (
        <>
          <HomeBanner homePageData={homePageData} />
          <div className="container">
            <BestSellers
              homeProductList={homeProductList}
              homePageData={homePageData}
              getWishList={getWishList}
              handleAddToWishList={handleAddToWishList}
              handleRemoveFromWishList={handleRemoveFromWishList}
              addToCartHandler={addToCartHandler}
              currentId={currentId}
            />
            <TrendingOn getTrendingOnList={getTrendingOnList} />
            <BestDeals
              homeProductList={homeProductList}
              getWishList={getWishList}
              handleAddToWishList={handleAddToWishList}
              handleRemoveFromWishList={handleRemoveFromWishList}
            />
            <section className="getfreshes-bg">
              <Link
                to={`/category/${
                  homePageData &&
                  homePageData.home &&
                  homePageData.home.sale_banner_url
                }`}
              >
                <img
                  src={
                    homePageData &&
                    homePageData.home &&
                    homePageData.home.banner_img
                  }
                  alt="#"
                />
              </Link>
            </section>
            <NewProduct
              homeProductList={homeProductList}
              getWishList={getWishList}
              handleAddToWishList={handleAddToWishList}
              handleRemoveFromWishList={handleRemoveFromWishList}
              addToCartHandler={addToCartHandler}
              currentId={currentId}
            />
          </div>
          <Testimonial categoriesList={categoriesList} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Home;
