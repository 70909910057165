import React, { useEffect } from "react";
import { getPagesData } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Home/loader/loader";

const PaymentServices = () => {
  const dispatch = useDispatch();
  const paymentServices = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 22,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div class="registration_bg_change">
        <div class="container">
          <h1>Payment Services</h1>
          <p class="lh-lg">
            Our guidelines and policies regarding feedback listed below are in
            place to ensure it's used constructively and fairly.{" "}
          </p>
        </div>
      </div>

      <div class="container py-5">
        <div class="Smileszy-Guarantee">
          {paymentServices ? (
            <div
              dangerouslySetInnerHTML={{
                __html: paymentServices && paymentServices.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
          {/* <p class="lh-lg">
            Payments for goods and services sold using our Services are
            facilitated by designated Smileszy entities (each, an "Smileszy
            Payment Entity") pursuant to the Payments Terms of Use. You agree to
            the Payments Terms of Use to the extent applicable to you. To
            receive payment for an item sold using our Services, you must accept
            and comply with the <b>Payments terms</b>, including the
            requirements to provide to Smileszy Payment Entities information
            about you, your business, and the financial account you will use to
            receive payments.
          </p>

          <h4>Disclaimer of Warranties; Limitation of Liability</h4>
          <p class="lh-lg">
            We make every effort to keep our Services safe, secure, and
            operational, but we cannot guarantee continuous operation or access
            to our Services.
          </p>
          <p class="lh-lg">
            Bid updates and other notification features of Smileszy's
            applications may not be available in real time. Such feature is
            susceptible to delays that are outside of Smileszy's control.
          </p>
          <p class="lh-lg">
            You acknowledge that you are using our Services at your own risk and
            that they are provided to you "AS IS" and "AS AVAILABLE."
          </p>
          <p class="lh-lg">
            As a result, we exclude all express or implied warranties, terms,
            and conditions, including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement, to the degree permitted by relevant law.
          </p>

          <h4>Indemnity</h4>
          <p class="lh-lg">
            You agree to indemnify and hold us (including our affiliates and
            subsidiaries, as well as our and their respective officers,
            directors, employees, and agents) harmless from any claim or demand
            made by any third party due to or arising out of your breach of this
            User Agreement, improper use of our Services, or violation of any
            law or the rights of a third party.{" "}
          </p>
          <h4>Applicable Law</h4>
          <p class="lh-lg">
            You agree that, except to the extent inconsistent with or preempted
            by Nigeria law, without regard to principles of conflict of laws,
            will govern this User Agreement and any claim or dispute that has
            arisen or may arise between you and Smileszy, except as otherwise
            stated in this User Agreement.
          </p>

          <h4>Agreement to Arbitrate</h4>

          <p class="lh-lg">
            <b>
              You and Smileszy each agree that any and all disputes or claims
              that have arisen, or may arise, between you and Smileszy (or any
              related third parties) that relate in any way to or arise out of
              this, your use of or access to our Services, the actions of
              Smileszy or its agents, or any products or services sold, offered,
              or purchased through our Services shall be resolved exclusively
              through final and binding arbitration, rather than in court.
            </b>
          </p>
          <p class="lh-lg">
            If you and Smileszy are unable to resolve the claims described in a
            valid Notice within 30 days after Smileszy receives that Notice, you
            or Smileszy may initiate arbitration proceedings.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default PaymentServices;
