import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DefaultImg from "../../../images/background.png";
import Loader from "../../../Components/Home/loader/loader";

const ProductQuickPage = ({}) => {
  return (
    <>
      <section className="productquickwrapper">
        <div className="productquick-bg">
          <div className="product-wrapper">
            <div className="product-img-wrapper">
              <div className="leftsideimg-wrapper">
                <div className="leftimgssection">
                  <img src={DefaultImg} alt="" onLoad={DefaultImg} />
                </div>
              </div>
              <div className="proucts-main-img">
                <img src={DefaultImg} alt="" />
              </div>
            </div>

            <div className="quickproducts-details-wrapper">
              <div
                className="products-links-wrap"
                style={{ textAlign: "left" }}
              >
                <Link to="/">Home</Link>
                <label className="slash-wrap">
                  <i className="fas fa-slash" />
                </label>
                <Link to="">fetchProductData?.cat_id</Link>
                <label className="slash-wrap">
                  <i className="fas fa-slash" />
                </label>
                <Link to="#">fetchProductData?.pname</Link>
              </div>
              <h6>fetchProductData?.pnam</h6>

              <div className="amount-section">
                <p>fetchProductData?.short_description</p>

                <del>
                  {`${fetchProductData?.currency_sign} ` +
                    fetchProductData?.p_price}
                </del>
                <span>
                  {`${fetchProductData?.currency_sign} ` +
                    fetchProductData?.s_price}
                </span>

                <span className="stock-update">In Stock</span>
              </div>
              <div className="optionsize-wrappers">
                <div className="sizeoptin-wrapp">
                  <select className="sizeoption-drop" placeholder="">
                    <option>item?.name</option>
                  </select>
                </div>
              </div>

              <div className="add-cart-wrpaeer">
                <button className="decrement-btn">-</button>
                <button className="product-number">num</button>
                <button className="increment-btn">+</button>

                <button className="add-to-cart-btn">addtocart</button>
              </div>

              {localStorage.getItem("smileszy_token") && (
                <div
                  onClick={
                    () => {
                      if (
                        wishlistt?.wishlist?.find(
                          (dt) => dt?.product_id == fetchProductData?.id
                        )
                      ) {
                        deleteWishList(
                          wishlistt?.wishlist.find(
                            (dt) => dt?.product_id == fetchProductData?.id
                          )
                        );
                      } else {
                        wishListHandler(fetchProductData?.id);
                      }
                    }
                    // : () =>
                    //     deleteWishList(
                    //       wishlistt?.wishlist.find(
                    //         (dt) => dt?.product_id == fetchProductData?.id
                    //       )
                    //     )
                  }
                  className="wishlist-wrap"
                >
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {wishlistt?.wishlist?.find(
                      (dt) => dt?.product_id == fetchProductData?.id
                    ) ? (
                      <i className="fas fa-heart me-2"></i>
                    ) : (
                      <i className="far fa-heart me-2" />
                    )}

                    {wishlistt?.wishlist?.find(
                      (dt) => dt?.product_id == fetchProductData?.id
                    )
                      ? `${
                          langchange == "rtl_Arabic"
                            ? ` بالفعل في قائمة الرغبات `
                            : `Already In Wishlist`
                        }`
                      : `${
                          langchange == "rtl_Arabic"
                            ? arabic.addtowishlist
                            : english.addtowishlist
                        }`}
                  </span>
                </div>
              )}
              <div className="sku-text-wrapper">
                <b>
                  {langchange == "rtl_Arabic"
                    ? `عدد سكو
`
                    : "SKU"}
                  :
                </b>{" "}
                <span>{fetchProductData?.sku_id}</span>
                <br />
                <b>
                  {" "}
                  {langchange == "rtl_Arabic"
                    ? ` ${arabic.category} `
                    : ` ${english.category} `}
                  :
                </b>{" "}
                <span>
                  {langchange == "rtl_Arabic"
                    ? Object.values(categories)?.find(
                        (dt) => dt?.id == fetchProductData?.cat_id
                      )?.arab_title
                    : Object.values(categories)?.find(
                        (dt) => dt?.id == fetchProductData?.cat_id
                      )?.title}
                </span>
              </div>
              <div className="share-wrapper">
                <b>
                  {" "}
                  {langchange == "rtl_Arabic"
                    ? ` ${arabic.share} `
                    : ` ${english.share} `}
                  :
                </b>
                {/* <a
                      className="social-link"
                      href={siteSettings?.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="social-link"
                      href={siteSettings?.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      href={siteSettings?.instagram}
                      target="_blank"
                      className="social-link"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>{" "}
                    <a
                      className="social-link"
                      href={siteSettings?.pinterest}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-pinterest-p"></i>
                    </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductQuickPage;
