import React from "react";

const SecurityCenter = () => {
  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Security Center</h1>
        </div>
      </div>
    </>
  );
};

export default SecurityCenter;
