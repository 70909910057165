import React from "react";
import "./userReview.scss";

const UserReview = ({ getFeedback }) => {
  return (
    <>
      {getFeedback &&
        getFeedback.map((data, index) => (
          <div key={index} className="userreview-wrapper">
            <div className="user-wrapperd">
              <img src="/img/USER.jpg" alt="#" />{" "}
              <span>{data.name || "user"}</span>
            </div>
            {/* <StarRatings
              rating={data.rating}
              starDimension="15px"
              starSpacing="1px"
              starRatedColor="orange"
            ></StarRatings> */}
            <span className="recviddatewrapp">
              Reviewed in India on {data.created_at.toString().split("T")[0]}
            </span>
            <span className="comment-wrapper">{data.discription} 💁🏻‍♀️</span>
          </div>
        ))}
    </>
  );
};

export default UserReview;
