import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onAddToCartList,
  onAddToWishList,
  onRemoveFromWishList,
  refresherAction,
  setLocalCartItems,
  getAllHomeProducts,
} from "../../redux/homeAction";
import { useNavigate } from "react-router-dom";
import Login from "../../popups/Login/Login";
import Loader from "../Home/loader/loader";
import { toast } from "react-toastify";

const TodayDeals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(null);
  const [loader, setLoader] = useState(false);
  const getWishList = useSelector((state) => state.home.getWishList);
  const homeProductList = useSelector((state) => state.home.homeProductList);
  const getAllHomeProductsList = useSelector(
    (state) => state?.home?.getAllHomeProductsList
  );
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));

  useEffect(() => {
    const data = {
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
      type: "new",
    };
    dispatch(getAllHomeProducts(data));
  }, []);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  return (
    <>
      {loader === false ? (
        <div className="container">
          <div className="allfeatured-items-wrappers">
            {getAllHomeProductsList &&
              getAllHomeProductsList.map((data, index) => (
                <div className="product-wrapper" key={index}>
                  <div className="hover-wish-list-wrappper">
                    <div className="wish-list-imgs">
                      {localStorage.getItem("user-token") ? (
                        <img
                          src={
                            getWishList?.find((item) => item?.id === data?.id)
                              ? "/img/ha2.png"
                              : "/img/ha.png"
                          }
                          alt={data?.pname}
                          className="img-fluid"
                          onClick={
                            !getWishList?.find((item) => item?.id == data?.id)
                              ? () => handleAddToWishList(data?.id)
                              : () =>
                                  handleRemoveFromWishList(
                                    getWishList?.find(
                                      (item) => item?.id == data?.id
                                    )
                                  )
                          }
                        />
                      ) : (
                        <img
                          src="/img/ha.png"
                          alt=""
                          className="img-fluid"
                          onClick={openLoginModal}
                        />
                      )}
                    </div>
                  </div>
                  <div className="product-imgs-wrap">
                    <img
                      src={data.featured_image}
                      alt=""
                      className="img-fluid"
                      onClick={() => showProduct(data.slug)}
                    />
                  </div>
                  <div className="items-content-wrappers">
                    <p
                      className="items-name"
                      onClick={() => showProduct(data.slug)}
                    >
                      {data.pname}
                    </p>
                    {data.in_stock == 0 ? (
                      <p className="instock" style={{ color: "red" }}>
                        Out Of Stock
                      </p>
                    ) : (
                      <p className="instock">In stock</p>
                    )}
                    {data.s_price ? (
                      <>
                        <del className="last-price">
                          {data.currency_sign}
                          {data.p_price}
                        </del>
                        <span className="latest-price">
                          {data.currency_sign}
                          {data.s_price}
                        </span>
                      </>
                    ) : (
                      <del className="last-price">
                        {data.currency_sign}
                        {data.p_price}
                      </del>
                    )}
                    {data.product_type === "single" ? (
                      <button
                        className="addto-cart-btn"
                        onClick={() => addToCartHandler(data)}
                      >
                        {currentId == data.id ? (
                          <span className="loader-spinner">
                            <i className="fa-solid fa-spinner loading-gif"></i>
                          </span>
                        ) : (
                          <span className="loader-spinner">Add to cart</span>
                        )}
                      </button>
                    ) : data.product_type === "bid" ? (
                      <button
                        className="addto-cart-btn"
                        onClick={() => showProduct(data.slug)}
                      >
                        Bid Now
                      </button>
                    ) : data.product_type === "booking" ? (
                      <button
                        className="addto-cart-btn"
                        onClick={() => showProduct(data.slug)}
                      >
                        Book Now
                      </button>
                    ) : data.product_type === "variants" ? (
                      <button
                        className="addto-cart-btn"
                        onClick={() => addToCartHandler(data)}
                      >
                        {currentId == data.id ? (
                          <span className="loader-spinner">
                            <i className="fa-solid fa-spinner loading-gif"></i>
                          </span>
                        ) : (
                          <span className="loader-spinner">Add to cart</span>
                        )}
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default TodayDeals;
