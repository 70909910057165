import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./shopPage.scss";
import DefaultImg from "../../images/background.png";
import { getAllStores } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Home/loader/loader";

const ShopsPage = () => {
  const dispatch = useDispatch();
  const getStoresList = useSelector((state) => state.home.getStoresList);

  useEffect(() => {
    dispatch(getAllStores());
  }, []);

  return (
    <>
      {getStoresList ? (
        <div className="shopsbg">
          <div className="container">
            <div className="Shops-wrappper">
              {getStoresList &&
                getStoresList.stores &&
                getStoresList.stores.map((item, key) => (
                  <div className="shop-wrapp slide-in-bottom" key={key}>
                    <Link to={`/shopdetails/${item.vendor_id}`}>
                      <img
                        src={item.banner_img ? item.banner_img : DefaultImg}
                        alt=""
                        className="shopeimg"
                      />
                      <div className="shops-detais-wrapp">
                        <div
                          className="shoplogowrapper"
                          style={{
                            backgroundImage: `url(${
                              item.banner_img ? item.banner_img : DefaultImg
                            })`,
                          }}
                        ></div>
                        <span className="shopNameline">{item.store_name}</span>
                        <p className="shop-addres">
                          {item.street_1} {item.state} {item.city}{" "}
                          {item.country}
                        </p>
                        <button className="visit-store-btn">
                          visitstore
                          <img src="/img/aroww.png" alt="#"></img>
                        </button>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ShopsPage;
