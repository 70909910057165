import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductsDescription from "../../Components/productsDescription/ProductsDescription";
import RelatedProducts from "../../Components/RelatedProducts/RelatedProducts";
import Loader from "../../Components/Home/loader/loader";
import SmileszyStoreTab from "../../Components/SmileszyStoretab/SmileszyStoreTab";
import "./ProductPage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createBid,
  getSingleProductData,
  onAddToCartList,
  onAddToWishList,
  onRemoveFromWishList,
  refresherAction,
  setLocalCartItems,
} from "../../redux/homeAction";
import Login from "../../popups/Login/Login";
import ReactImageMagnify from "react-image-magnify";
import { toast } from "react-toastify";
import moment from "moment";
import Calender from "../../popups/Calendar/Calender";
import VariationPopup from "../../popups/variationPopup/VariationPopup";

const ProductPage = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const getWishList = useSelector((state) => state.home.getWishList);
  const productDetail = useSelector((state) => state.home.getProductDetails);
  const createdBid = useSelector((state) => state.home.createdBid);
  const getSiteSettingDetails = useSelector(
    (state) => state.home.getSiteSettingDetails
  );
  const categoriesList = useSelector((state) => state.home.categoriesList);
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const [showImg, setShowImg] = useState("");
  const [loginOpen, setLoginOpen] = useState(false);
  const [BookServiceOpen, setBookServiceOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [qty, setQty] = useState(1);
  const [variationPop, setVariationPopup] = useState(false);
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);
  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [slug]);

  useEffect(() => {
    const data = {
      slug: slug,
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(getSingleProductData(data));
  }, [createdBid, slug]);

  const onImgShow = (e) => {
    setShowImg(e.target.src);
  };

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  const handleBuyItNow = (product) => {
    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      const data = {
        product_id: product.id,
        quantity: 1,
      };
      dispatch(onAddToCartList(data));
      navigate("/checkout");
    }
  };

  const addQty = (product) => {
    if (qty < product.in_stock) {
      setQty(qty + 1);
    } else {
      toast.error("Out of stock");
    }
  };

  const removeQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const handleCreateBid = (product) => {
    const data = {
      product_id: product?.id,
      bid_price: productDetail && productDetail.current_bid + 1,
    };

    if (qty < product.in_stock) {
      dispatch(createBid(data));
    } else {
      toast.error("Out of stock");
    }
  };

  const onVaraintionChange = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="product-bg">
          <div className="container">
            <div className="product-wrapper">
              <div className="product-img-wrapper">
                <div className="leftsideimg-wrapper">
                  {productDetail &&
                    productDetail.gallery_image &&
                    productDetail.gallery_image.map((data, index) => (
                      <div className="leftimgssection" key={index}>
                        <img src={data} alt="" onClick={(e) => onImgShow(e)} />
                      </div>
                    ))}
                </div>
                <div className="proucts-main-img">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        isFluidWidth: true,
                        src: showImg
                          ? showImg
                          : productDetail && productDetail.featured_image,
                      },
                      largeImage: {
                        src: showImg
                          ? showImg
                          : productDetail && productDetail.featured_image,
                        width: 1800,
                        height: 1800,
                      },
                    }}
                  />
                </div>
              </div>

              <div className="products-details-wrapper">
                <div
                  className="products-links-wrap"
                  style={{ textAlign: "left" }}
                >
                  <Link to="/">Home</Link>{" "}
                  <label className="slash-wrap">/</label>{" "}
                  <Link
                    to={`/category/${
                      categoriesList?.find(
                        (dt) => dt?.id === productDetail?.cat_id
                      )?.slug
                    }`}
                  >
                    {
                      categoriesList?.find(
                        (dt) => dt?.id === productDetail?.cat_id
                      )?.title
                    }
                  </Link>{" "}
                  <label className="slash-wrap">/</label>{" "}
                  <Link to="#">{productDetail && productDetail.pname}</Link>
                </div>
                <h6>{productDetail && productDetail.pname}</h6>
                <div className="amount-section">
                  <p>{productDetail && productDetail.short_description}</p>
                </div>

                <div>
                  {productDetail &&
                  productDetail.product_type === "variants" ? (
                    <div className="singleproduct-wrapper">
                      <div className="quantity-wrapper">
                        {/* <div className="optionsize-wrappers">
                          <div className="sizeoptin-wrapp">
                            {productDetail &&
                              productDetail.attributes &&
                              productDetail.attributes.map((item, key) => (
                                <select
                                  className="sizeoption-drop"
                                  key={key}
                                  onChange={(e) => onVaraintionChange(e)}
                                >
                                  <option>{item.name}</option>
                                  {item.values.map((value) => (
                                    <option value={value}>{value}</option>
                                  ))}
                                </select>
                              ))}
                          </div>
                        </div> */}
                        <div className="quantitywrapp">
                          Quantity :
                          <div className="Quantitybtn-wrapepr">
                            <button
                              className="decrement-btn"
                              onClick={() => removeQty(productDetail)}
                            >
                              -
                            </button>
                            <button className="product-number">{qty}</button>
                            <button
                              className="increment-btn"
                              onClick={() => addQty(productDetail)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="products-price-wrapper">
                          Price:{" "}
                          {productDetail && productDetail.s_price ? (
                            <>
                              <del>
                                {productDetail && productDetail.currency_sign}
                                {productDetail && productDetail.p_price}
                              </del>
                              <span>
                                {productDetail && productDetail.currency_sign}
                                {productDetail && productDetail.s_price}
                              </span>
                            </>
                          ) : (
                            <del>
                              {productDetail && productDetail.currency_sign}
                              {productDetail && productDetail.p_price}
                            </del>
                          )}
                        </div>
                        <span>
                          Sale by{" "}
                          {productDetail &&
                            productDetail.storemeta &&
                            productDetail &&
                            productDetail.storemeta.first_name}
                        </span>
                      </div>
                      <div className="bye-btnwrapper">
                        {/* {localStorage.getItem("user-token") ? (
                          <button
                            className="ByeNow-btn"
                            onClick={() => handleBuyItNow(productDetail)}
                          >
                            Buy It Now
                          </button>
                        ) : (
                          <button
                            className="ByeNow-btn"
                            onClick={openLoginModal}
                          >
                            Buy It Now
                          </button>
                        )} */}

                        <button
                          className="addtobasket-btn"
                          onClick={() => setVariationPopup(true)}
                        >
                          <span className="loader-spinner">Add to cart</span>
                        </button>

                        {localStorage.getItem("user-token") ? (
                          <button
                            className="addto-wishlist-btn"
                            onClick={
                              !getWishList?.find(
                                (item) => item?.id == productDetail?.id
                              )
                                ? () => handleAddToWishList(productDetail?.id)
                                : () =>
                                    handleRemoveFromWishList(
                                      getWishList?.find(
                                        (item) => item?.id == productDetail?.id
                                      )
                                    )
                            }
                          >
                            {getWishList?.find(
                              (item) => item?.id === productDetail?.id
                            ) ? (
                              <span>
                                <i className="fas fa-heart" /> Already in
                                wishlist
                              </span>
                            ) : (
                              <span>
                                <i className="far fa-heart" /> Add to wishlist
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            className="addto-wishlist-btn"
                            onClick={openLoginModal}
                          >
                            <span>
                              <i className="far fa-heart" /> Add to wishlist
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  ) : productDetail &&
                    productDetail.product_type === "single" ? (
                    <div className="singleproduct-wrapper">
                      <div className="quantity-wrapper">
                        <div className="quantitywrapp">
                          Quantity :
                          <div className="Quantitybtn-wrapepr">
                            <button
                              className="decrement-btn"
                              onClick={() => removeQty(productDetail)}
                            >
                              -
                            </button>
                            <button className="product-number">{qty}</button>
                            <button
                              className="increment-btn"
                              onClick={() => addQty(productDetail)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="products-price-wrapper">
                          Price:{" "}
                          {productDetail && productDetail.s_price ? (
                            <>
                              <del>
                                {productDetail && productDetail.currency_sign}
                                {productDetail && productDetail.p_price}
                              </del>
                              <span>
                                {productDetail && productDetail.currency_sign}
                                {productDetail && productDetail.s_price}
                              </span>
                            </>
                          ) : (
                            <del>
                              {productDetail && productDetail.currency_sign}
                              {productDetail && productDetail.p_price}
                            </del>
                          )}
                        </div>
                        <span>
                          Sale by{" "}
                          {productDetail &&
                            productDetail.storemeta &&
                            productDetail &&
                            productDetail.storemeta.first_name}
                        </span>
                      </div>
                      <div className="bye-btnwrapper">
                        {localStorage.getItem("user-token") ? (
                          <button
                            className="ByeNow-btn"
                            onClick={() => handleBuyItNow(productDetail)}
                          >
                            Buy It Now
                          </button>
                        ) : (
                          <button
                            className="ByeNow-btn"
                            onClick={openLoginModal}
                          >
                            Buy It Now
                          </button>
                        )}

                        <button
                          className="addtobasket-btn"
                          onClick={() => addToCartHandler(productDetail)}
                        >
                          {currentId == productDetail.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                        {localStorage.getItem("user-token") ? (
                          <button
                            className="addto-wishlist-btn"
                            onClick={
                              !getWishList?.find(
                                (item) => item?.id == productDetail?.id
                              )
                                ? () => handleAddToWishList(productDetail?.id)
                                : () =>
                                    handleRemoveFromWishList(
                                      getWishList?.find(
                                        (item) => item?.id == productDetail?.id
                                      )
                                    )
                            }
                          >
                            {getWishList?.find(
                              (item) => item?.id === productDetail?.id
                            ) ? (
                              <span>
                                <i className="fas fa-heart" /> Already in
                                wishlist
                              </span>
                            ) : (
                              <span>
                                <i className="far fa-heart" /> Add to wishlist
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            className="addto-wishlist-btn"
                            onClick={openLoginModal}
                          >
                            <span>
                              <i className="far fa-heart" /> Add to wishlist
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  ) : productDetail && productDetail.product_type === "bid" ? (
                    <div className="productBid-wrapper">
                      <p className="Conditionline-wrapp">
                        <b> Condition </b>: <strong>New</strong>
                      </p>
                      <p className="Conditionline-wrapp">
                        <b> Time Left : </b>
                        {productDetail && productDetail.bid_close}
                      </p>

                      <p className="Conditionline-wrapp">
                        <b> Current Bid :</b>{" "}
                        <small>
                          {productDetail && productDetail.currency_sign}
                          {productDetail && productDetail.current_bid} Bid
                        </small>
                      </p>

                      <div className="bid-line-wrappper">
                        <div className="amountsection">
                          {/* <button className="decrement-btn">-</button> */}
                          <button className="product-number">
                            {productDetail && productDetail.currency_sign}
                            {productDetail && productDetail.next_bid_price}
                          </button>
                          {/* <button className="increment-btn">+</button> */}
                        </div>
                        <div>
                          {localStorage.getItem("user-token") ? (
                            <>
                              {productDetail &&
                              productDetail.bid_status === "is_available" ? (
                                <button
                                  className="bid-btn"
                                  onClick={() => handleCreateBid(productDetail)}
                                >
                                  Bid
                                </button>
                              ) : (
                                <button className="bid-btnDisabled" disabled>
                                  Bid
                                </button>
                              )}
                            </>
                          ) : (
                            <button
                              className="bid-btn"
                              onClick={() => openLoginModal()}
                            >
                              Bid
                            </button>
                          )}
                        </div>
                        {productDetail && productDetail.bid_close ? null : (
                          <div>
                            <button
                              className="BuyNow-bidbtn"
                              onClick={() => addToCartHandler(productDetail)}
                            >
                              Buy Now In{" "}
                              {productDetail && productDetail.currency_sign}
                              {productDetail && productDetail.s_price}
                            </button>
                          </div>
                        )}
                      </div>
                      <p className="Conditionline-wrapp">
                        <b>Start Date :</b>{" "}
                        {moment(
                          productDetail && productDetail.start_date
                        ).format("DD-MMM-YYYY")}
                      </p>
                      <p className="Conditionline-wrapp">
                        <b>End Date :</b>{" "}
                        {moment(productDetail && productDetail.end_date).format(
                          "DD-MMM-YYYY"
                        )}
                      </p>
                      {/* <p className="Conditionline-wrapp">
                        <b>Timezone :</b> UTC +2
                      </p> */}
                    </div>
                  ) : productDetail &&
                    productDetail.product_type === "booking" ? (
                    <div className="singleproduct-wrapper">
                      <div className="quantity-wrapper">
                        {/* <div className="quantitywrapp">
                          Quantity :
                          <div className="Quantitybtn-wrapepr">
                            <button
                              className="decrement-btn"
                              onClick={() => removeQty(productDetail)}
                            >
                              -
                            </button>
                            <button className="product-number">{qty}</button>
                            <button
                              className="increment-btn"
                              onClick={() => addQty(productDetail)}
                            >
                              +
                            </button>
                          </div>
                        </div> */}
                        <div className="products-price-wrapper">
                          Price :{" "}
                          {productDetail && productDetail.s_price ? (
                            <>
                              <del>
                                {productDetail && productDetail.currency_sign}
                                {productDetail && productDetail.p_price}
                              </del>
                              <span>
                                {productDetail && productDetail.currency_sign}
                                {productDetail && productDetail.s_price}
                              </span>
                            </>
                          ) : (
                            <del>
                              {productDetail && productDetail.currency_sign}
                              {productDetail && productDetail.p_price}
                            </del>
                          )}
                        </div>
                      </div>
                      <div className="bye-btnwrapper">
                        {localStorage.getItem("user-token") ? (
                          <button
                            className="addtobasket-btn"
                            onClick={() => setBookServiceOpen(true)}
                          >
                            Book Now
                          </button>
                        ) : (
                          <button
                            className="addtobasket-btn"
                            onClick={openLoginModal}
                          >
                            Book Now
                          </button>
                        )}

                        {localStorage.getItem("user-token") ? (
                          <button
                            className="addto-wishlist-btn"
                            onClick={
                              !getWishList?.find(
                                (item) => item?.id == productDetail?.id
                              )
                                ? () => handleAddToWishList(productDetail?.id)
                                : () =>
                                    handleRemoveFromWishList(
                                      getWishList?.find(
                                        (item) => item?.id == productDetail?.id
                                      )
                                    )
                            }
                          >
                            {getWishList?.find(
                              (item) => item?.id === productDetail?.id
                            ) ? (
                              <span>
                                <i className="fas fa-heart" /> Already in
                                wishlist
                              </span>
                            ) : (
                              <span>
                                <i className="far fa-heart" /> Add to wishlist
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            className="addto-wishlist-btn"
                            onClick={openLoginModal}
                          >
                            <span>
                              <i className="far fa-heart" /> Add to wishlist
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="sku-text-wrapper" style={{ textAlign: "left" }}>
                  <b>SKU:</b>
                  <span>{productDetail && productDetail.sku_id}</span>
                  <br />
                  <b>category :</b>
                  <span>
                    {
                      categoriesList?.find(
                        (dt) => dt?.id === productDetail?.cat_id
                      )?.title
                    }
                  </span>
                  <br />
                  <b>Return :</b>

                  <span>
                    {productDetail && productDetail.return_policy_desc}
                  </span>
                </div>
                <div className="share-wrapper">
                  <b>share</b>
                  <a
                    className="social-link"
                    href={
                      getSiteSettingDetails && getSiteSettingDetails.twitter
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href={
                      getSiteSettingDetails && getSiteSettingDetails.instagram
                    }
                    target="_blank"
                    className="social-link"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>{" "}
                  <a
                    className="social-link"
                    href={
                      getSiteSettingDetails && getSiteSettingDetails.pinterest
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                </div>
              </div>
            </div>
            <ProductsDescription productDetail={productDetail} />
            <SmileszyStoreTab
              productDetail={productDetail && productDetail.storemeta}
            />
            <RelatedProducts
              productDetail={productDetail && productDetail.cat_id}
              setShowImg={setShowImg}
            />
          </div>
        </div>
      )}

      <Login open={loginOpen} onCloseModal={closeLoginModal} />
      <Calender
        BookServiceOpen={BookServiceOpen}
        onCloseModal={() => setBookServiceOpen(false)}
        productDetail={productDetail}
        qty={qty}
      />
      <VariationPopup
        open={variationPop}
        productDetail={productDetail}
        onCloseModal={() => setVariationPopup(false)}
      />
    </>
  );
};

export default ProductPage;
