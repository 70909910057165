import React, { useEffect, useState } from "react";
import ProductCategories from "./Shop-left/Product-Categories/ProductCategories";
import ShopHeader from "./Shop-right/Shop-header/ShopHeader";
import "./Shop.scss";
import {
  getAllBrands,
  getAttributeList,
  getProductsData,
  onAddToCartList,
  onAddToWishList,
  onRemoveFromWishList,
  refresherAction,
  setLocalCartItems,
} from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShopByCategory from "./ShopByCategory/ShopByCategory";
import ShopItemsone from "./Shop-right/ShopItems/ShopItemsone";
import Loader from "../Home/loader/loader";
import { toast } from "react-toastify";
import FilterByPrice from "./Shop-left/Filter-by-Price/FilterByPrice";
import ProductStatus from "./Shop-left/Product-Status/ProductStatus";
import ProductListStatus from "./Shop-left/Product-list-status/ProductListStatus";

const Shop = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState();
  const [currentId, setCurrentId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filterValue, setFilterValue] = useState("top_rated");
  const [filterByPrice, setFilterByPrice] = useState(1000);
  const [brands, setBrands] = useState([]);
  const [outInStock, setOutInStock] = useState();
  const [onSale, setOnSale] = useState();
  const [newArrival, setNewArrival] = useState();
  const [attributeId, setAttributeId] = useState([]);
  const [childCategoryList, setChildCategoryList] = useState();
  // const [menusDataList, setMenusDataList] = useState();
  const getProductsList = useSelector((state) => state.home.getProductsList);
  const categoriesList = useSelector((state) => state.home.categoriesList);
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const getWishList = useSelector((state) => state.home.getWishList);
  const getBrands = useSelector((state) => state.home.getBrands);
  const getAllAttributeList = useSelector(
    (state) => state.home.getAllAttributeList
  );
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  // const getMenusList = useSelector((state) => state.home.getMenusList);
  // const moreSubMenuList = menusDataList && menusDataList.more_sub_menu;
  // const topSubMenuList = menusDataList && menusDataList.top_sub_menu;

  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  useEffect(() => {
    fetchProducts();
  }, [
    slug,
    filterValue,
    filterByPrice,
    brands,
    outInStock,
    onSale,
    newArrival,
    attributeId,
  ]);

  useEffect(() => {
    if (categoriesList) {
      for (let i = 0; i < categoriesList.length; i++) {
        if (categoriesList[i].slug === slug) {
          setChildCategoryList(categoriesList[i]);
        }
      }
    }
  }, [slug, categoriesList]);

  useEffect(() => {
    dispatch(getAllBrands());
  }, []);

  useEffect(() => {
    dispatch(getAttributeList());
  }, []);

  // useEffect(() => {
  //   if (getMenusList) {
  //     for (let i = 0; i < getMenusList.length; i++) {
  //       if (getMenusList[i].slug === slug) {
  //         setMenusDataList(getMenusList[i]);
  //       }
  //     }
  //   }
  // }, [slug, getMenusList]);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [
    slug,
    filterValue,
    filterByPrice,
    brands,
    outInStock,
    onSale,
    newArrival,
    attributeId,
  ]);

  const fetchProducts = () => {
    const data = {
      category_slug: slug,
      sort_by: filterValue,
      price_range: `1 - ${filterByPrice}`,
      brand: brands,
      in_stock: outInStock,
      on_sale: onSale,
      new_arrival: newArrival,
      attr_value_id: attributeId,
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(getProductsData(data));
  };

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  const openCategory = (data) => {
    setCategoryId(data.id);
    navigate(`/category/${data.slug}`);
  };

  const closeCategory = () => {
    setCategoryId();
  };

  const onFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  return (
    <>
      <div className="shop-bg">
        <div className="container">
          <div className="shop-wrapper">
            <div className="shop-left-section">
              <ProductCategories
                categoriesList={categoriesList}
                openCategory={openCategory}
                closeCategory={closeCategory}
                categoryId={categoryId}
              />
              <FilterByPrice
                setFilterByPrice={setFilterByPrice}
                filterByPrice={filterByPrice}
              />
              <ProductStatus
                getAllAttributeList={getAllAttributeList}
                getBrands={getBrands}
                setBrands={setBrands}
                setOutInStock={setOutInStock}
                setOnSale={setOnSale}
                setNewArrival={setNewArrival}
                setAttributeId={setAttributeId}
                brands={brands}
                attributeId={attributeId}
              />
              {/* <ProductListStatus /> */}
              {/* <Shopad /> */}
            </div>
            <div className="shop-right-section">
              <ShopByCategory
                childCategoryList={childCategoryList}
                // topSubMenuList={topSubMenuList}
                // moreSubMenuList={moreSubMenuList}
              />
              <ShopHeader
                onFilterChange={onFilterChange}
                categoriesList={categoriesList}
                openCategory={openCategory}
                closeCategory={closeCategory}
                categoryId={categoryId}
              />
              {loader === false ? (
                <ShopItemsone
                  getProductsList={getProductsList}
                  slug={slug}
                  handleAddToWishList={handleAddToWishList}
                  handleRemoveFromWishList={handleRemoveFromWishList}
                  getWishList={getWishList}
                  addToCartHandler={addToCartHandler}
                  currentId={currentId}
                />
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
