export const SET_CURRENY = "SET_CURRENY";

export const SET_CATEGORIES = "SET_CATEGORIES";

export const SET_HOME_PRODUCT = "SET_HOME_PRODUCT";

export const SET_HOME_PAGE_DATA = "SET_HOME_PAGE_DATA";

export const SET_TRENDING_ON_CATEGORY = "SET_TRENDING_ON_CATEGORY";

export const SET_MENUS_DATA = "SET_MENUS_DATA";

export const SET_PAGES_DATA = "SET_PAGES_DATA";

export const SET_SITE_SETTING_DATA = "SET_SITE_SETTING_DATA";

export const SET_ALL_STORES = "SET_ALL_STORES";

export const SET_SINGLE_STORES = "SET_SINGLE_STORES";

export const SET_SINGLE_STORES_CATEGORY = "SET_SINGLE_STORES_CATEGORY";

export const SET_MY_ACCOUNT_DATA = "SET_MY_ACCOUNT_DATA";

export const SET_EDIT_ACCOUNT_DATA = "SET_EDIT_ACCOUNT_DATA";

export const SET_MY_ADDRESS_DATA = "SET_MY_ADDRESS_DATA";

export const SET_EDIT_ADDRESS_DATA = "SET_EDIT_ADDRESS_DATA";

export const SET_DELETE_ADDRESS_DATA = "SET_DELETE_ADDRESS_DATA";

export const SET_COUNTRY = "SET_COUNTRY";

export const SET_STATE = "SET_STATE";

export const SET_CITY = "SET_CITY";

export const SET_CONTACT_US = "SET_CONTACT_US";

export const SET_ADD_TO_WISHLIST = "SET_ADD_TO_WISHLIST";

export const SET_REMOVE_FROM_WISHLIST = "SET_REMOVE_FROM_WISHLIST";

export const SET_WISHLIST = "SET_WISHLIST";

export const SET_BLOGS = "SET_BLOGS";

export const SET_BLOGS_COMMENTS = "SET_BLOGS_COMMENTS";

export const SET_ALL_PRODUCT = "SET_ALL_PRODUCT";

export const SET_RELATED_PRODUCT = "SET_RELATED_PRODUCT";

export const SET_FEEDBACK_LIST = "SET_FEEDBACK_LIST";

export const SET_SEARCH_LIST = "SET_SEARCH_LIST";

export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";

export const SET_VENDOR_REGISTER = "SET_VENDOR_REGISTER";

export const SET_LOCAL_CART_ITEMS = "SET_LOCAL_CART_ITEMS";

export const SET_LIVE_CART_ITEMS = "SET_LIVE_CART_ITEMS";

export const SET_LIVE_CART_ITEMS_LIST = "SET_LIVE_CART_ITEMS_LIST";

export const SET_DELETE_CART_ITEMS = "SET_DELETE_CART_ITEMS";

export const SET_ADD_TO_CART_ITEMS = "SET_ADD_TO_CART_ITEMS";

export const SET_UPDATE_CART_QTY = "SET_UPDATE_CART_QTY";

export const SET_ADD_ORDER = "SET_ADD_ORDER";

export const SET_ORDER_HISTORY = "SET_ORDER_HISTORY";

export const SET_ORDER_TRACK = "SET_ORDER_TRACK";

export const SET_ADD_PAYMENT_CARD = "SET_ADD_PAYMENT_CARD";

export const SET_ALL_CARD_LIST = "SET_ALL_CARD_LIST";

export const SET_DELETE_CARD_LIST = "SET_DELETE_CARD_LIST";

export const SET_CREATE_BID_ITEM = "SET_CREATE_BID_ITEM";

export const SET_ALL_WINNING_BID_LIST = "SET_ALL_WINNING_BID_LIST";

export const SET_DATE_AVAILABILTY = "SET_DATE_AVAILABILTY";

export const SET_BOOK_NOW = "SET_BOOK_NOW";

export const SET_BOOKED_SERVICES = "SET_BOOKED_SERVICES";

export const SET_APPLY_COUPON = "SET_APPLY_COUPON";

export const SET_ALL_WALLET_TRANSECTION = "SET_ALL_WALLET_TRANSECTION";

export const SET_WALLET = "SET_WALLET";

export const SET_CANCEL_ORDER = "SET_CANCEL_ORDER";

export const SET_REFRESHER_ACTION = "SET_REFRESHER_ACTION";

export const SET_ALL_BRANDS = "SET_ALL_BRANDS";

export const SET_SHIPPING_METHODS = "SET_SHIPPING_METHODS";

export const SET_ALL_HOME_PRODUCTS_LIST = "SET_ALL_HOME_PRODUCTS_LIST";

export const SET_ATTRIBUTE_LIST = "SET_ATTRIBUTE_LIST";
