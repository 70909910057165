import React, { useEffect, useState } from "react";
import Loader from "../../Home/loader/loader";
import ShopDetailsHeader from "./shopDetailsHeader/ShopDetailsHeader";
import "./shopDetailsPage.scss";
import StoreSideform from "./StoreCategoryForm/StoreSideform";
import StoreItems from "./StoreItems/StoreItems";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getProductsData,
  getSingleStores,
  getSingleStoresCategory,
  onAddToCartList,
  onAddToWishList,
  onRemoveFromWishList,
  refresherAction,
  setLocalCartItems,
} from "../../../redux/homeAction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ShopDetailsPage = () => {
  const navigate = useNavigate();
  const { shopId } = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [productList, setProductList] = useState();
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  const getSingleStoresList = useSelector(
    (state) => state.home.getSingleStoresList
  );
  const getWishList = useSelector((state) => state.home.getWishList);
  const getProductsList = useSelector((state) => state.home.getProductsList);
  const getSingleStoresCategoryList = useSelector(
    (state) => state.home.getSingleStoresCategoryList
  );
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  useEffect(() => {
    if (getSingleStoresList) {
      setProductList(getSingleStoresList && getSingleStoresList.product);
    }
  }, [getSingleStoresList]);

  useEffect(() => {
    if (getProductsList) {
      setProductList(getProductsList);
    }
  }, [getProductsList]);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [shopId]);

  useEffect(() => {
    const data = {
      id: shopId,
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(getSingleStores(data));
    dispatch(getSingleStoresCategory(data));
  }, [shopId]);

  const filterProduct = (category) => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);

    const data = {
      category_slug: category.slug,
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };
    dispatch(getProductsData(data));
  };

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  return (
    <>
      <div className="shopDetailsbg">
        <div className="container">
          <div className="shopdetails-wrapper">
            <div className="ShopLeft-side-form">
              <StoreSideform
                getSingleStoresCategoryList={getSingleStoresCategoryList}
                filterProduct={filterProduct}
              />
            </div>
            <div className="Shopright-side-form">
              {loader === false ? (
                <>
                  <ShopDetailsHeader
                    getSingleStoresList={getSingleStoresList}
                  />
                  <StoreItems
                    showProduct={showProduct}
                    handleRemoveFromWishList={handleRemoveFromWishList}
                    handleAddToWishList={handleAddToWishList}
                    productList={productList}
                    getWishList={getWishList}
                    addToCartHandler={addToCartHandler}
                    currentId={currentId}
                  />
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopDetailsPage;
