import React, { useEffect, useState } from "react";
import "./wishList.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  onAddToCartList,
  refresherAction,
  setLocalCartItems,
} from "../../../../redux/homeAction";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const WishList = ({ getWishList, deleteFromWishlist }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  return (
    <>
      {getWishList && getWishList.length === 0 ? (
        <section className="welcome-cartbg-wrappp">
          <div className="container">
            <div className="welcome-screenWrapper">
              <h4 className="no-productwrapp">
                Your Wish List is Currently Empty
              </h4>

              <p className="no-producttext-wrapp mb-3">
                you will find a lot of interesting products on our "shop" page.
              </p>
              <Link to="/products">
                <button className="shopp-btn-incart">Continue Shopping</button>
              </Link>
            </div>
          </div>
        </section>
      ) : (
        <div className="wishlist-wrapper">
          <div className="carts-bg">
            <div className="product-topline">
              <span className="producttag">
                <b>Product</b>
              </span>
              <span className="price-tag">
                <b>Status</b>
              </span>
              <span className="price-tag">
                <b>Price</b>
              </span>
              <span className="price-tag">
                <b>Cart</b>
              </span>
            </div>
            <div className="cartswrapped">
              {getWishList &&
                getWishList.map((item, key) => (
                  <div className="carts-line" key={key}>
                    <div className="products-name-wrapper">
                      <i
                        className="far fa-trash-alt"
                        onClick={() => deleteFromWishlist(item.id)}
                      />
                      <div style={{ cursor: "pointer" }} className="imgs-bg">
                        <img
                          src={item.featured_image}
                          alt=""
                          className="img-fluid"
                          onClick={() => showProduct(item.slug)}
                        />
                      </div>
                      <span
                        style={{ cursor: "pointer" }}
                        className="product-name"
                        onClick={() => showProduct(item.slug)}
                      >
                        {item.pname}
                      </span>
                    </div>
                    {item.in_stock == 0 ? (
                      <span
                        className="product-price"
                        id="productmobview"
                        style={{ color: "red" }}
                      >
                        Out of Stock
                      </span>
                    ) : (
                      <span className="product-price" id="productmobview">
                        In Stock
                      </span>
                    )}

                    <span className="product-price" id="productmobview">
                      {item.currency_sign}
                      {item.s_price}
                    </span>

                    <div className="product-price">
                      {item.product_type === "single" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(item)}
                        >
                          {currentId == item.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : item.product_type === "bid" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(item.slug)}
                        >
                          Bid Now
                        </button>
                      ) : item.product_type === "booking" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(item.slug)}
                        >
                          Book Now
                        </button>
                      ) : item.product_type === "variants" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(item)}
                        >
                          {currentId == item.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WishList;
