import {
  SET_ADD_ORDER,
  SET_ADD_PAYMENT_CARD,
  SET_ADD_TO_CART_ITEMS,
  SET_ADD_TO_WISHLIST,
  SET_ALL_BRANDS,
  SET_ALL_CARD_LIST,
  SET_ALL_HOME_PRODUCTS_LIST,
  SET_ALL_PRODUCT,
  SET_ALL_STORES,
  SET_ALL_WALLET_TRANSECTION,
  SET_ALL_WINNING_BID_LIST,
  SET_APPLY_COUPON,
  SET_ATTRIBUTE_LIST,
  SET_BLOGS,
  SET_BLOGS_COMMENTS,
  SET_BOOKED_SERVICES,
  SET_BOOK_NOW,
  SET_CANCEL_ORDER,
  SET_CATEGORIES,
  SET_CITY,
  SET_COUNTRY,
  SET_CREATE_BID_ITEM,
  SET_CURRENY,
  SET_DATE_AVAILABILTY,
  SET_DELETE_ADDRESS_DATA,
  SET_DELETE_CARD_LIST,
  SET_DELETE_CART_ITEMS,
  SET_EDIT_ACCOUNT_DATA,
  SET_EDIT_ADDRESS_DATA,
  SET_FEEDBACK_LIST,
  SET_HOME_PAGE_DATA,
  SET_HOME_PRODUCT,
  SET_LIVE_CART_ITEMS,
  SET_LIVE_CART_ITEMS_LIST,
  SET_LOCAL_CART_ITEMS,
  SET_MENUS_DATA,
  SET_MY_ACCOUNT_DATA,
  SET_MY_ADDRESS_DATA,
  SET_ORDER_HISTORY,
  SET_ORDER_TRACK,
  SET_PAGES_DATA,
  SET_PRODUCTS_LIST,
  SET_REFRESHER_ACTION,
  SET_RELATED_PRODUCT,
  SET_REMOVE_FROM_WISHLIST,
  SET_SEARCH_LIST,
  SET_SHIPPING_METHODS,
  SET_SINGLE_STORES,
  SET_SINGLE_STORES_CATEGORY,
  SET_SITE_SETTING_DATA,
  SET_STATE,
  SET_TRENDING_ON_CATEGORY,
  SET_UPDATE_CART_QTY,
  SET_VENDOR_REGISTER,
  SET_WALLET,
  SET_WISHLIST,
} from "./types";

const cartItemsFromStorage = JSON.parse(localStorage.getItem("syCartList"))
  ? JSON.parse(localStorage.getItem("syCartList"))
  : [];

const initialState = {
  currencyList: [],
  getFeedback: [],
  localCartItems: cartItemsFromStorage,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENY:
      return {
        ...state,
        currencyList: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categoriesList: action.payload,
      };
    case SET_HOME_PRODUCT:
      return {
        ...state,
        homeProductList: action.payload,
      };
    case SET_HOME_PAGE_DATA:
      return {
        ...state,
        homePageData: action.payload,
      };
    case SET_TRENDING_ON_CATEGORY:
      return {
        ...state,
        getTrendingOnList: action.payload,
      };
    case SET_MENUS_DATA:
      return {
        ...state,
        getMenusList: action.payload,
      };
    case SET_PAGES_DATA:
      return {
        ...state,
        getPagesList: action.payload,
      };
    case SET_SITE_SETTING_DATA:
      return {
        ...state,
        getSiteSettingDetails: action.payload,
      };
    case SET_ALL_STORES:
      return {
        ...state,
        getStoresList: action.payload,
      };
    case SET_SINGLE_STORES:
      return {
        ...state,
        getSingleStoresList: action.payload,
      };
    case SET_SINGLE_STORES_CATEGORY:
      return {
        ...state,
        getSingleStoresCategoryList: action.payload,
      };
    case SET_MY_ACCOUNT_DATA:
      return {
        ...state,
        getAccountDetails: action.payload,
      };
    case SET_EDIT_ACCOUNT_DATA:
      return {
        ...state,
        updateAccount: action.payload,
      };
    case SET_MY_ADDRESS_DATA:
      return {
        ...state,
        getAddressList: action.payload,
      };
    case SET_EDIT_ADDRESS_DATA:
      return {
        ...state,
        updateAddress: action.payload,
      };
    case SET_DELETE_ADDRESS_DATA:
      return {
        ...state,
        deleteAddress: action.payload,
      };
    case SET_COUNTRY:
      return {
        ...state,
        getCountryList: action.payload,
      };
    case SET_STATE:
      return {
        ...state,
        getStateList: action.payload,
      };
    case SET_CITY:
      return {
        ...state,
        getCityList: action.payload,
      };
    case SET_ADD_TO_WISHLIST:
      return {
        ...state,
        addToWishlist: action.payload,
      };
    case SET_REMOVE_FROM_WISHLIST:
      return {
        ...state,
        removeFromWishlist: action.payload,
      };
    case SET_WISHLIST:
      return {
        ...state,
        getWishList: action.payload,
      };
    case SET_BLOGS:
      return {
        ...state,
        getBlogList: action.payload,
      };
    case SET_BLOGS_COMMENTS:
      return {
        ...state,
        getCommentList: action.payload,
      };
    case SET_ALL_PRODUCT:
      return {
        ...state,
        getProductDetails: action.payload,
      };
    case SET_PRODUCTS_LIST:
      return {
        ...state,
        getProductsList: action.payload,
      };
    case SET_RELATED_PRODUCT:
      return {
        ...state,
        getRelatedList: action.payload,
      };
    case SET_FEEDBACK_LIST:
      return {
        ...state,
        getFeedback: action.payload,
      };
    case SET_SEARCH_LIST:
      return {
        ...state,
        getSearchProductList: action.payload,
      };
    case SET_VENDOR_REGISTER:
      return {
        ...state,
        getVenderRagister: action.payload,
      };
    case SET_LOCAL_CART_ITEMS:
      return {
        ...state,
        localCartItems: action.payload,
      };
    case SET_LIVE_CART_ITEMS:
      return {
        ...state,
        liveCartItems: action.payload,
      };
    case SET_LIVE_CART_ITEMS_LIST:
      return {
        ...state,
        liveCartItemsList: action.payload,
      };
    case SET_DELETE_CART_ITEMS:
      return {
        ...state,
        deleteCartItems: action.payload,
      };
    case SET_ADD_TO_CART_ITEMS:
      return {
        ...state,
        addToCartItems: action.payload,
      };
    case SET_UPDATE_CART_QTY:
      return {
        ...state,
        updateCartQty: action.payload,
      };
    case SET_ORDER_HISTORY:
      return {
        ...state,
        getOrderHistory: action.payload,
      };
    case SET_ORDER_TRACK:
      return {
        ...state,
        getOrderTracking: action.payload,
      };
    case SET_ADD_ORDER:
      return {
        ...state,
        orderPlaced: action.payload,
      };
    case SET_ADD_PAYMENT_CARD:
      return {
        ...state,
        addPaymentCard: action.payload,
      };
    case SET_ALL_CARD_LIST:
      return {
        ...state,
        getAllCardList: action.payload,
      };
    case SET_DELETE_CARD_LIST:
      return {
        ...state,
        deleteCardList: action.payload,
      };
    case SET_CREATE_BID_ITEM:
      return {
        ...state,
        createdBid: action.payload,
      };
    case SET_ALL_WINNING_BID_LIST:
      return {
        ...state,
        winningBidList: action.payload,
      };
    case SET_DATE_AVAILABILTY:
      return {
        ...state,
        dateAvailability: action.payload,
      };
    case SET_BOOK_NOW:
      return {
        ...state,
        bookNow: action.payload,
      };
    case SET_BOOKED_SERVICES:
      return {
        ...state,
        getBookedServices: action.payload,
      };
    case SET_APPLY_COUPON:
      return {
        ...state,
        appliedCoupon: action.payload,
      };
    case SET_ALL_WALLET_TRANSECTION:
      return {
        ...state,
        getWalletTransection: action.payload,
      };
    case SET_WALLET:
      return {
        ...state,
        addedWallet: action.payload,
      };
    case SET_CANCEL_ORDER:
      return {
        ...state,
        orderCancel: action.payload,
      };
    case SET_REFRESHER_ACTION:
      return {
        ...state,
        refresher: action.payload,
      };
    case SET_ALL_BRANDS:
      return {
        ...state,
        getBrands: action.payload,
      };
    case SET_SHIPPING_METHODS:
      return {
        ...state,
        fixedFreeShipping: action.payload,
      };
    case SET_ALL_HOME_PRODUCTS_LIST:
      return {
        ...state,
        getAllHomeProductsList: action.payload,
      };
    case SET_ATTRIBUTE_LIST:
      return {
        ...state,
        getAllAttributeList: action.payload,
      };

    default:
      return state;
  }
};

export default homeReducer;
