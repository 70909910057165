import React from "react";
import { Link } from "react-router-dom";
import "./shopByCategory.scss";

const ShopByCategory = ({
  childCategoryList,
  // topSubMenuList,
  // moreSubMenuList,
}) => {
  return (
    <>
      {childCategoryList == undefined ? null : (
        <div className="shopbyCategory-wrapper">
          <h2 className="shopbycategory-tag-wrapper">Shop By Category</h2>
          <div className="shopbycategorys-wrapp">
            {childCategoryList &&
              childCategoryList.child_category &&
              childCategoryList.child_category.map((data, key) => (
                <div className="shopitemscategory-wrapper" key={key}>
                  <Link to={`/category/${data.slug}`}>
                    <div className="shopitemscategory-wrapper-div">
                      <img
                        src={data.category_image}
                        alt=""
                        className="category-img-wrapp"
                      />
                    </div>
                    <p className="categoryimgname-wrapp text-dark">
                      {data.title}
                    </p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      )}

      {/* {topSubMenuList === undefined ? null : (
        <div className="shopbyCategory-wrapper">
          <h2 className="shopbycategory-tag-wrapper">Top Categories</h2>
          <div className="shopbycategorys-wrapp">
            {topSubMenuList &&
              topSubMenuList.map((data, key) => (
                <div className="shopitemscategory-wrapper" key={key}>
                  <Link to={`/category/${data.slug}`}>
                    <div className="shopitemscategory-wrapper-div">
                      <img
                        src={data.category_image}
                        alt=""
                        className="category-img-wrapp"
                      />
                    </div>
                    <p className="categoryimgname-wrapp text-dark">
                      {data.title}
                    </p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      )}

      {moreSubMenuList === undefined ? null : (
        <div className="shopbyCategory-wrapper">
          <h2 className="shopbycategory-tag-wrapper">More Categories</h2>
          <div className="shopbycategorys-wrapp">
            {moreSubMenuList &&
              moreSubMenuList.map((data, key) => (
                <div className="shopitemscategory-wrapper" key={key}>
                  <Link to={`/category/${data.slug}`}>
                    <div className="shopitemscategory-wrapper-div">
                      <img
                        src={data.category_image}
                        alt=""
                        className="category-img-wrapp"
                      />
                    </div>
                    <p className="categoryimgname-wrapp text-dark">
                      {data.title}
                    </p>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      )} */}
    </>
  );
};

export default ShopByCategory;
