import React from "react";
import "./FilterByPrice.scss";

const FilterByPrice = ({ setFilterByPrice, filterByPrice }) => {
  return (
    <>
      <div className="filter-wrapper">
        <p className="Filterby-pricename">FILTER BY PRICE</p>
        <input
          min={1}
          max={1000}
          defaultValue={1000}
          type="range"
          onChange={(e) => setFilterByPrice(e.target.value)}
        />
        <div className="Filter-changes">
          <span>
            Price :<b> ${filterByPrice}</b>
          </span>
          <label>Filter</label>
        </div>
      </div>
    </>
  );
};

export default FilterByPrice;
