import React from "react";
import "./howitwork.scss";

const HowItWork = () => {
  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">HOW IT WORKS</h1>
        </div>
      </div>
      <div className="Howwork-bg">
        <div className="container">
          <p className="howits-work-line">HOW IT WORKS</p>
          <p className="heres-step-line">Here is a step by step guide</p>
          <div className="stepsWrapper">
            <div className="step-wrapper">
              <div className="steps-wrapp">
                <img src="/img/Register.png" alt="#" className="img-fluid" />
                <span>Register and list your products for free</span>
              </div>
              <div className="steps-wrapp">
                <img src="/img/Receive.png" alt="#" className="img-fluid" />
                <span>Receive orders and sell your product</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="steps-wrapp">
                <img src="/img/Package.png" alt="#" className="img-fluid" />
                <span>Package and deliver with ease</span>
              </div>
              <div className="steps-wrapp">
                <img src="/img/GetPayment.png" alt="#" className="img-fluid" />
                <span>Get payments and grow your busines</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
