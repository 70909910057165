import React from "react";
import "./trendingOn.scss";
import { Link } from "react-router-dom";

const TrendingOn = ({ getTrendingOnList }) => {
  return (
    <>
      <div className="trending-smilewrapper">
        <h2 className="Trendingline">Trending on smileszy</h2>
        <div className="ternding-items-wrapepr">
          {getTrendingOnList &&
            getTrendingOnList.map((item, index) => (
              <div className="trending-items" key={index}>
                <div className="trendingproduct-wrapp">
                  <Link to={`/category/${item.slug}`}>
                    <img
                      src={item.category_image}
                      alt="#"
                      className="trendingProduct-img"
                    />
                  </Link>
                  <Link to={`/category/${item.slug}`}>
                    <p className="trendingProductline">{item.title}</p>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default TrendingOn;
