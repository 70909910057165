import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlogsData } from "../../redux/homeAction";
import Loader from "../Home/loader/loader";
import "./Blog.scss";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getBlogList = useSelector((state) => state.home.getBlogList);
  const getSiteSettingDetails = useSelector(
    (state) => state.home.getSiteSettingDetails
  );

  useEffect(() => {
    dispatch(getBlogsData());
  }, []);

  const showSinglePage = (slug) => {
    navigate(`/singleblog/${slug}`);
  };

  return (
    <div>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Blog Page</h1>
        </div>
      </div>
      {getBlogList ? (
        <div className="blog_Page_space">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                {getBlogList &&
                  getBlogList.blogs &&
                  getBlogList.blogs.map((data, index) => (
                    <div className="" key={index}>
                      <div className="blog_Page_img">
                        <img
                          src={data.image}
                          alt="img"
                          onClick={() => showSinglePage(data.slug)}
                        />
                        <a>{data.cat_title}</a>
                      </div>
                      <div className="blog_Page_deatils">
                        <h1
                          className="blog_Page_deatils_h1"
                          onClick={() => showSinglePage(data.slug)}
                        >
                          {data.title}
                        </h1>
                        <div className="blog_Page_deatils_date">
                          <p>
                            <i className="fa fa-calendar"></i>
                            {moment(data.created_at).format("DD-MMM-YYYY")}
                          </p>
                          <p>
                            <i className="fa fa-comments"></i>
                            {data.blog_comment.length
                              ? data.blog_comment.length
                              : "0"}{" "}
                            Comments
                          </p>
                        </div>
                        <p className="blog_Page_deatils_P">
                          {data.short_description}
                        </p>
                        <div className="blog_Page_deatils_a">
                          <a
                            href={
                              getSiteSettingDetails &&
                              getSiteSettingDetails.facebook
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-facebook"></i>
                          </a>
                          <a
                            href={
                              getSiteSettingDetails &&
                              getSiteSettingDetails.twitter
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a
                            href={
                              getSiteSettingDetails &&
                              getSiteSettingDetails.pinterest
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa-brands fa-pinterest"></i>
                          </a>

                          <a
                            href={
                              getSiteSettingDetails &&
                              getSiteSettingDetails.instagram
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa-brands fa-instagram"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="col-lg-4 px-4">
                <div className="blog_Page_recent">
                  <h3>Recent Post</h3>
                  {getBlogList &&
                    getBlogList.recent_blog &&
                    getBlogList.recent_blog.map((data, index) => (
                      <div className="blog_Page_recent_flex" key={index}>
                        <div className="blog_Page_recent_img">
                          <img
                            src={data.image}
                            alt="img"
                            onClick={() => showSinglePage(data.slug)}
                          />
                        </div>
                        <div className="blog_Page_recent_title">
                          <p onClick={() => showSinglePage(data.slug)}>
                            {data.title}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="blog_Page_tags">
                  <h3>Tags</h3>
                  <div className="row pt-3">
                    {getBlogList &&
                      getBlogList.all_tags &&
                      getBlogList.all_tags.map((data, index) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-4 col-6"
                          key={index}
                        >
                          <p onClick={() => showSinglePage(data.blog_slug)}>
                            {data.title}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Blog;
