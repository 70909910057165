import React, { useEffect } from "react";
import { getPagesData } from "../../redux/homeAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Home/loader/loader";

const FeedbackGuideline = () => {
  const dispatch = useDispatch();
  const feedbackGuideline = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 20,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div class="registration_bg_change">
        <div class="container">
          <h1>Feedback guideline</h1>
          <p class="lh-lg">
            Our guidelines and policies regarding feedback listed below are in
            place to ensure it's used constructively and fairly.{" "}
          </p>
        </div>
      </div>

      <div class="container py-5">
        <div class="Smileszy-Guarantee">
          {feedbackGuideline ? (
            <div
              dangerouslySetInnerHTML={{
                __html: feedbackGuideline && feedbackGuideline.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
          {/* <h4>Feedback extortion</h4>
          <p class="lh-lg">
            Sellers may not need favorable feedback from purchasers, and buyers
            may not utilise threats of bad comments or low detailed seller
            ratings to get items that were not originally listed.
          </p>

          <h4>manipulation of feedback</h4>
          <p class="lh-lg">
            Members are not allowed to exchange comments with the express intent
            of boosting their feedback totals, obtaining Smileszy benefits, or
            improving their online reputation.
          </p>
          <p class="lh-lg">
            Additionally, they are unable to sway another member's rating by
            making several subsequent purchases or by posting critical remarks
            alongside favorable ratings.
          </p>
          <p class="lh-lg">
            Terms and conditions in a listing that prevent or restrict the buyer
            from posting feedback are not permitted by sellers.{" "}
          </p>

          <h4>Feedback misuse</h4>
          <p class="lh-lg">It is forbidden to abuse feedback in any way.</p>
          <p class="lh-lg">
            The ratings in both buyer and seller feedback, whether positive or
            negative, should be reflected in the feedback remarks.
          </p>
          <p class="lh-lg">
            For instance, it is inappropriate to leave a positive rating with a
            negative comment. Additional details
          </p>
          <p class="lh-lg">
            In your feedback profile, you can see every piece of feedback you've
            ever given or gotten. You may also follow up and address feedback
            from there.
          </p> */}
        </div>
      </div>
    </>
  );
};

export default FeedbackGuideline;
