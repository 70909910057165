import React, { useEffect, useState } from "react";
import RecentOrder from "../Desboardpage/Deshbord-recent-order/RecentOrder";
import "./orderDetailspage.scss";
import ShippingAddress from "./ShippingAddress/ShippingAddress";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const OrderDetailspage = ({ getOrderHistory, setCurrentTab }) => {
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState();

  useEffect(() => {
    if (getOrderHistory) {
      setOrderDetails(
        getOrderHistory.filter(
          (item, index) =>
            item.id === parseInt(localStorage.getItem("orderViewId"))
        )
      );
    }
  }, [getOrderHistory]);

  const calculateSubTotal = (orderItem) => {
    let subTotalPrice = 0;

    orderItem &&
      orderItem.forEach((p) => {
        subTotalPrice += parseInt(p.product_price);
      });

    return subTotalPrice.toFixed(2);
  };

  const calculateTotal = (data) => {
    let totalPrice = 0;

    data &&
      data.order_item &&
      data.order_item.forEach((p) => {
        totalPrice += parseInt(p.product_price);
      });

    return totalPrice.toFixed(2);
  };

  return (
    <>
      <section className="Deshbord-page-wrapper">
        {orderDetails &&
          orderDetails.map((data) => (
            <>
              <div className="welcome-wrapper">
                <div className="walcome-status-wrapper">
                  <p className="OrderDetailsline">Order Details</p>
                  <div className="order-dates-wrapper">
                    <span className="orders-date">
                      Ordered on{" "}
                      {moment(data && data.created_date).format("DD-MMM-YYYY")}
                    </span>
                    <span className="order-number">
                      Order # {data && data.order_id}
                    </span>
                  </div>
                </div>
              </div>
              <div className="shipingwrapper">
                <div className="shipingAddreswrapper">
                  <h6>Shipping Address</h6>
                  <span>{data?.order_meta?.shipping_first_name}</span>
                  <span>{data?.order_meta?.shipping_address2}</span>
                  <span>{data?.order_meta?.shipping_landmark}</span>
                  <span>
                    {data?.order_meta?.shipping_city},{" "}
                    {data?.order_meta?.shipping_state}{" "}
                    {data?.order_meta?.shipping_zip_code}
                  </span>
                  <span>{data?.order_meta?.shipping_country}</span>
                </div>
                <div className="shipingAddreswrapper">
                  <h6>Payment Method</h6>
                  <div className="card-method">
                    <img src="/img/cards.png" alt="#" />
                    <span>xxxxxxxx4242</span>
                  </div>
                </div>
                <div className="shipingAddreswrapper">
                  <h6>Order Summary</h6>
                  <div className="orderSunnary-wrapper">
                    <ul>
                      <li>
                        <span>Item(s) Subtotal: </span>
                        <span>
                          {data?.order_meta?.currency_sign}
                          {calculateSubTotal(data.order_item)}
                        </span>
                      </li>
                      <li>
                        <span>Shipping</span>
                        <span>{data?.order_meta?.currency_sign}0</span>
                      </li>
                      <li>
                        <span>
                          <b>Grand Total: </b>
                        </span>
                        <span>
                          <b>
                            {data?.order_meta?.currency_sign}
                            {calculateTotal(data)}
                          </b>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ))}

        {orderDetails &&
          orderDetails.map((data, key) => (
            <section className="recent-orderwrapper" key={key}>
              <div className="recent-order-toptab">
                <div className="order-place-wrapp">
                  <p className="order-place-line">Order-placed</p>
                  <p className="order-date">
                    {moment(data.created_date, "DD/MM/YYYY").format(
                      "DD MMMM YYYY"
                    )}
                  </p>
                </div>
                <div className="shipto-wrapper">
                  <p className="shipto-line">Ship To</p>
                  <p className="owner-order-name">
                    {data.order_meta.shipping_first_name}
                  </p>
                  <p className="short-addras">
                    {data.order_meta.shipping_address2}
                  </p>
                </div>
                <div className="total-wrapper">
                  <p className="total-tag">Total</p>
                  <p className="total-amounts">
                    {data.order_meta.currency_sign}
                    {calculateSubTotal(data.order_item)}
                  </p>
                </div>
                <div className="order-details-wrapper">
                  <p className="order-number">Order #{data.order_id}</p>
                  <p className="vierorder-details-link">
                    <span
                      onClick={() => {
                        setCurrentTab("orderdetails");
                        navigate("/user/orderdetails");
                        localStorage.setItem("orderViewId", data.id);
                      }}
                    >
                      View Order Details
                    </span>{" "}
                    | <span>Download Invoice</span>
                  </p>
                </div>
              </div>
              {data &&
                data.order_item &&
                data.order_item.map((item, key) => (
                  <div className="order-items-wrapper" key={key}>
                    <p className="deliverd-status">Deliverd Tuesday</p>
                    <section className="order-product-wrapper">
                      <div className="order-productimg">
                        <img src={item.featured_image} alt="#" />
                      </div>
                      <div className="order-items-wrapper">
                        <p className="order-product-name">
                          {item.product_name}
                        </p>
                        {/* <p className="order-product-Sold-by">
                        Sold by: Sami Kotob
                      </p>
                      <p className="order-product-Sold-by">
                        Return eligiblr through 20-Jan-2022
                      </p> */}
                        <label className="price-tag">
                          {data.order_meta.currency_sign}
                          {parseInt(item.product_price).toFixed(2)}
                        </label>
                      </div>
                    </section>
                  </div>
                ))}
            </section>
          ))}
      </section>
    </>
  );
};

export default OrderDetailspage;
