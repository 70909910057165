import React, { useEffect, useState } from "react";
import TheValueProducts from "../../theDayValue/theValueProducts/TheValueProducts";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getSearchList,
  onAddToCartList,
  onAddToWishList,
  onRemoveFromWishList,
  refresherAction,
  setLocalCartItems,
} from "../../../redux/homeAction";
import Loader from "../../Home/loader/loader";
import { toast } from "react-toastify";

const NewArrivals = () => {
  const dispatch = useDispatch();
  const { text } = useParams();
  const [currentId, setCurrentId] = useState(null);
  const getWishList = useSelector((state) => state.home.getWishList);
  const getSearchProductList = useSelector(
    (state) => state.home.getSearchProductList
  );
  let localCartItems = useSelector((state) => state.home.localCartItems);
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  const cartItemsLength = JSON.parse(localStorage.getItem("syCartList"));

  useEffect(() => {
    dispatch(refresherAction(cartItemsLength));
  }, [cartItemsLength]);

  useEffect(() => {
    const data = {
      search: text,
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
    };

    dispatch(getSearchList(data));
  }, []);

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      if (localStorage.getItem("user-token")) {
        // toast.success("Added to cart");
        const data = {
          product_id: product.id,
          quantity: 1,
        };
        dispatch(onAddToCartList(data));
      } else {
        if (!localCartItems) {
          localCartItems = [];

          localCartItems.push({
            ...product,
            qty: 1,
          });
        } else {
          const isProductExist = localCartItems.find(
            (c) => c.id === product.id
          );
          if (isProductExist) {
            localCartItems = localCartItems.map((p) =>
              p.id === product.id
                ? {
                    ...p,
                    qty: p.qty + 1,
                  }
                : p
            );
          } else {
            localCartItems.push({
              ...product,
              qty: 1,
            });
          }
        }

        dispatch(setLocalCartItems(localCartItems));

        if (localCartItems) {
          for (let i = 0; i < localCartItems.length; i++) {
            if (localCartItems[i].id === product.id) {
              if (localCartItems[i].qty > 1) {
                toast.success("Cart Updated");
              } else {
                toast.success("Added to cart");
              }
            }
          }
        }

        window.addEventListener("storage", () => {
          refetch();
        });
        return () => {
          window.removeEventListener("storage");
        };
      }
    }
  };

  return (
    <>
      {getSearchProductList ? (
        <div className="section">
          {getSearchProductList && getSearchProductList.length === 0 ? (
            <section
              className="welcome-cartbg-wrappp"
              style={{ backgroundColor: "white" }}
            >
              <div className="container">
                <div className="welcome-screenWrapper">
                  <div className="container" style={{ padding: "0" }}>
                    <h5 style={{ margin: "30px 0", padding: "0" }}>
                      You Searched For "{text}"
                    </h5>
                  </div>
                  <h4 className="no-productwrapp">
                    No Products Found for "{text}"
                  </h4>
                  <p className="no-producttext-wrapp mb-3">
                    You will find a lot of interesting products on our "shop"
                    page.
                  </p>
                  <Link to="/products">
                    <button className="shopp-btn-incart">
                      Continue Shopping
                    </button>
                  </Link>
                </div>
              </div>
            </section>
          ) : (
            <>
              <div className="container">
                <h5 style={{ margin: "30px 0" }}>You Searched For "{text}"</h5>
              </div>
              <TheValueProducts
                getSearchProductList={getSearchProductList}
                handleAddToWishList={handleAddToWishList}
                getWishList={getWishList}
                handleRemoveFromWishList={handleRemoveFromWishList}
                addToCartHandler={addToCartHandler}
                currentId={currentId}
              />
            </>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default NewArrivals;
