import React, { useState } from "react";
import "./theValueProducts.scss";
import { useNavigate } from "react-router-dom";
import Login from "../../../popups/Login/Login";

const TheValueProducts = ({
  getSearchProductList,
  handleAddToWishList,
  getWishList,
  handleRemoveFromWishList,
  addToCartHandler,
  currentId,
}) => {
  const navigate = useNavigate();
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  return (
    <>
      <div className="container">
        <div className="allfeatured-items-wrappers">
          {getSearchProductList &&
            getSearchProductList.map((data, index) => (
              <div className="product-wrapper" key={index}>
                <div className="hover-wish-list-wrappper">
                  <div className="wish-list-imgs">
                    {localStorage.getItem("user-token") ? (
                      <img
                        src={
                          getWishList?.find((item) => item?.id === data?.id)
                            ? "/img/ha2.png"
                            : "/img/ha.png"
                        }
                        alt={data?.pname}
                        className="img-fluid"
                        onClick={
                          !getWishList?.find((item) => item?.id == data?.id)
                            ? () => handleAddToWishList(data?.id)
                            : () =>
                                handleRemoveFromWishList(
                                  getWishList?.find(
                                    (item) => item?.id == data?.id
                                  )
                                )
                        }
                      />
                    ) : (
                      <img
                        src="/img/ha.png"
                        alt=""
                        className="img-fluid"
                        onClick={openLoginModal}
                      />
                    )}
                  </div>
                </div>
                <div className="product-imgs-wrap">
                  <img
                    src={data.featured_image}
                    alt=""
                    className="img-fluid"
                    onClick={() => showProduct(data.slug)}
                  />
                </div>
                <div className="items-content-wrappers">
                  <p
                    className="items-name"
                    onClick={() => showProduct(data.slug)}
                  >
                    {data.pname}
                  </p>

                  {data.in_stock == 0 ? (
                    <p className="instock" style={{ color: "red" }}>
                      Out Of Stock
                    </p>
                  ) : (
                    <p className="instock">In stock</p>
                  )}

                  {data.s_price ? (
                    <>
                      <del className="last-price">
                        {data.currency_sign}
                        {data.p_price}
                      </del>
                      <span className="latest-price">
                        {data.currency_sign}
                        {data.s_price}
                      </span>
                    </>
                  ) : (
                    <del className="last-price">
                      {data.currency_sign}
                      {data.p_price}
                    </del>
                  )}
                  {data.product_type === "single" ? (
                    <button
                      className="addto-cart-btn"
                      onClick={() => addToCartHandler(data)}
                    >
                      {currentId == data.id ? (
                        <span className="loader-spinner">
                          <i className="fa-solid fa-spinner loading-gif"></i>
                        </span>
                      ) : (
                        <span className="loader-spinner">Add to cart</span>
                      )}
                    </button>
                  ) : data.product_type === "bid" ? (
                    <button
                      className="addto-cart-btn"
                      onClick={() => showProduct(item.slug)}
                    >
                      Bid Now
                    </button>
                  ) : data.product_type === "booking" ? (
                    <button
                      className="addto-cart-btn"
                      onClick={() => showProduct(item.slug)}
                    >
                      Book Now
                    </button>
                  ) : data.product_type === "variants" ? (
                    <button
                      className="addto-cart-btn"
                      onClick={() => addToCartHandler(data)}
                    >
                      {currentId == data.id ? (
                        <span className="loader-spinner">
                          <i className="fa-solid fa-spinner loading-gif"></i>
                        </span>
                      ) : (
                        <span className="loader-spinner">Add to cart</span>
                      )}
                    </button>
                  ) : null}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default TheValueProducts;
