import React from "react";
import { Link } from "react-router-dom";
import "./cartWelcome.scss";

const CartWelcome = () => {
  return (
    <>
      <section className="welcome-cartbg-wrappp">
        <div className="container">
          <div className="welcome-screenWrapper">
            <h4 className="no-productwrapp">Your Cart is Currently Empty.</h4>
            <p className="no-producttext-wrapp">
              Before proceeding to checkout you must add some products to your
              shopping cart.
            </p>
            <p className="no-producttext-wrapp mb-3">
              you will find a lot of interesting products on our "shop" page.
            </p>
            <Link to="/products">
              <button className="shopp-btn-incart">Continue Shopping</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default CartWelcome;
