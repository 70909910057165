import React, { useEffect, useState } from "react";
import "./homeBanner.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeBanner = ({ homePageData }) => {
  const bannerSlider = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <>
      <section className="Homebanner-wrapper">
        <Slider {...bannerSlider}>
          {homePageData &&
            homePageData.home &&
            homePageData.home.slider &&
            homePageData.home.slider.map((data, index) => (
              <div className="home-banner" key={index}>
                <Link to={`/category/${data.url}`}>
                  <img src={data.image} alt="" />
                </Link>
              </div>
            ))}
        </Slider>
      </section>
    </>
  );
};

export default HomeBanner;
