import React, { useState } from "react";
import "./Forgot.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { forgotPassword } from "../../redux/homeAction";
import ResetVerifyOtp from "../otpPopups/ResetVerifyOtp";

const Forgot = ({ open, onCloseModal }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [otpPopup, setOtpPopup] = useState(false);
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const openOtpPopup = () => {
    setOtpPopup(true);
  };
  const closeOtpPopup = () => setOtpPopup(false);

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    localStorage.setItem("registerEmail", values?.email);

    if (!values.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values.email)) {
      toast.error("Invalid Email Address");
    } else {
      const data = {
        email: values?.email,
      };
      dispatch(forgotPassword(data, openOtpPopup, onCloseModal));
    }
  };

  return (
    <>
      <Modal open={open} onClose={onCloseModal}>
        <form className="Reset-formwrapper" onSubmit={onForgotPassword}>
          <div className="forgot-title">
            <h2 className="title-text">Forgot Password</h2>
            <i
              class="fa fa-times"
              aria-hidden="true"
              onClick={onCloseModal}
            ></i>
          </div>
          <div className="forgot-formWrapper">
            <div className="forgot-form">
              <input
                type="text"
                name="email"
                className="countyr-code-wrapp"
                placeholder="Enter Your Email"
                value={values?.email}
                onChange={onInputChange}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <button className="Submit-btn" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Modal>
      <ResetVerifyOtp otpPopup={otpPopup} closeOtpPopup={closeOtpPopup} />
    </>
  );
};

export default Forgot;
