import React from "react";

const CartWrapper = ({
  cartItems,
  productHandler,
  deleteCart,
  addQty,
  removeQty,
}) => {
  return (
    <>
      <div className="cartswrapped">
        <div className="carts-line">
          <div className="products-name-wrapper">
            <i
              className="far fa-trash-alt"
              onClick={() => deleteCart(cartItems && cartItems.id)}
            />
            <div
              className="imgs-bg"
              onClick={() => productHandler(cartItems && cartItems.slug)}
            >
              <img
                src={cartItems && cartItems.featured_image}
                alt=""
                className="img-fluid"
              />
            </div>
            <span
              className="product-name"
              onClick={() => productHandler(cartItems && cartItems.slug)}
            >
              {cartItems && cartItems.pname}
            </span>
          </div>
          <span className="product-price" id="productmobview">
            {cartItems && cartItems.currency_sign}
            {cartItems && cartItems.s_price}
          </span>
          <div className="product-price">
            <button
              className="decrement-btn"
              onClick={() => removeQty(cartItems)}
            >
              -
            </button>
            <button className="product-number">
              {cartItems && cartItems.qty}
            </button>
            <button className="increment-btn" onClick={() => addQty(cartItems)}>
              +
            </button>
          </div>
          <span className="product-price" id="productmobview">
            {cartItems && cartItems.currency_sign}
            <span>
              {parseInt(cartItems && cartItems.s_price) *
                parseInt(cartItems && cartItems.qty)}
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default CartWrapper;
