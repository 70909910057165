import Axios from "./axios";
import {
  SET_CATEGORIES,
  SET_CURRENY,
  SET_HOME_PAGE_DATA,
  SET_HOME_PRODUCT,
  SET_MENUS_DATA,
  SET_TRENDING_ON_CATEGORY,
  SET_PAGES_DATA,
  SET_SITE_SETTING_DATA,
  SET_ALL_STORES,
  SET_MY_ACCOUNT_DATA,
  SET_EDIT_ACCOUNT_DATA,
  SET_MY_ADDRESS_DATA,
  SET_DELETE_ADDRESS_DATA,
  SET_EDIT_ADDRESS_DATA,
  SET_COUNTRY,
  SET_STATE,
  SET_CITY,
  SET_CONTACT_US,
  SET_SINGLE_STORES,
  SET_SINGLE_STORES_CATEGORY,
  SET_ADD_TO_WISHLIST,
  SET_REMOVE_FROM_WISHLIST,
  SET_WISHLIST,
  SET_BLOGS,
  SET_BLOGS_COMMENTS,
  SET_ALL_PRODUCT,
  SET_RELATED_PRODUCT,
  SET_FEEDBACK_LIST,
  SET_SEARCH_LIST,
  SET_PRODUCTS_LIST,
  SET_VENDOR_REGISTER,
  SET_LOCAL_CART_ITEMS,
  SET_LIVE_CART_ITEMS,
  SET_DELETE_CART_ITEMS,
  SET_ADD_TO_CART_ITEMS,
  SET_ORDER_HISTORY,
  SET_UPDATE_CART_QTY,
  SET_ADD_ORDER,
  SET_ALL_CARD_LIST,
  SET_DELETE_CARD_LIST,
  SET_ADD_PAYMENT_CARD,
  SET_LIVE_CART_ITEMS_LIST,
  SET_CREATE_BID_ITEM,
  SET_ALL_WINNING_BID_LIST,
  SET_DATE_AVAILABILTY,
  SET_ORDER_TRACK,
  SET_BOOK_NOW,
  SET_BOOKED_SERVICES,
  SET_APPLY_COUPON,
  SET_ALL_WALLET_TRANSECTION,
  SET_WALLET,
  SET_CANCEL_ORDER,
  SET_REFRESHER_ACTION,
  SET_ALL_BRANDS,
  SET_SHIPPING_METHODS,
  SET_ALL_HOME_PRODUCTS_LIST,
  SET_ATTRIBUTE_LIST,
} from "./types";
import { toast } from "react-toastify";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("user-token")}`,
  },
};

// Login
export const onLogin = (data) => (dispatch) => {
  Axios.post("/login", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      localStorage.setItem("user-token", res.data.token);
      localStorage.setItem("user-details", JSON.stringify(res.data.user));
      if (localStorage.getItem("syCartList")) {
        const localCartList = JSON.parse(localStorage.getItem("syCartList"));
        if (localCartList.length > 0) {
          localCartList.forEach((p) => {
            dispatch(
              setLiveAddToCart(
                {
                  product_id: p.id,
                  quantity: p.qty,
                },
                res.data.token
              )
            );
          });
        }
      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      localStorage.setItem("syCartList", JSON.stringify([]));
    } else {
      toast.error(res.data.message);
    }
  });
};

// REGISTER
export const onRegister = (data, openOtpPopup) => (dispatch) => {
  Axios.post("/user-signup", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      openOtpPopup();
    } else {
      toast.error(res.data.message);
    }
  });
};

// VERIFY OTP
export const onVerifyOtp = (data) => (dispatch) => {
  Axios.post("/verify-otp", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      window.location.reload();
    } else {
      toast.error(res.data.message);
    }
  });
};

// RESEND OTP
export const onResendOtp = (data) => (dispatch) => {
  Axios.post("/resend-otp", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  });
};

// FORGOT PASSWORD
export const forgotPassword =
  (data, openOtpPopup, onCloseModal) => (dispatch) => {
    Axios.post(`/userforgototp`, data).then((res) => {
      if (res.data.status === true) {
        openOtpPopup();
        onCloseModal();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    });
  };

// FORGET PASSWORD VERIFY OTP
export const forgotPasswordVerifyOtp =
  (data, setOpenChangePassword, closeOtpPopup) => (dispatch) => {
    Axios.post("/verify-otp-email", data).then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        setOpenChangePassword(true);
        closeOtpPopup();
      } else {
        toast.error(res.data.message);
      }
    });
  };

// CHANGE PASSWORD
export const onChangeForgotPassword = (data) => (dispatch) => {
  Axios.post("/change-password", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      window.location.reload();
    } else {
      toast.error(res.data.message);
    }
  });
};

// SOCIAL LOGIN
export const onSocialLogin = (data) => (dispatch) => {
  Axios.post("/social", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      window.location.reload();
      localStorage.setItem("user-token", res.data.data.token);
      localStorage.setItem("user-details", JSON.stringify(res.data.details));
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET CURRENCY
export const getCurrency = () => (dispatch) => {
  Axios.post(`/currency`).then((res) => {
    dispatch({
      type: SET_CURRENY,
      payload: res.data.Currency.currency,
    });
  });
};

// GET CATEGORY
export const getCategories = () => (dispatch) => {
  Axios.post(`/category`).then((res) => {
    dispatch({
      type: SET_CATEGORIES,
      payload: res.data.data,
    });
  });
};

// GET HOME PRODUCT
export const getHomeProduct = (data) => (dispatch) => {
  Axios.post(`/home-products`, data).then((res) => {
    dispatch({
      type: SET_HOME_PRODUCT,
      payload: res.data.product,
    });
  });
};

// GET HOME PAGE DATA
export const getHomePageData = () => (dispatch) => {
  Axios.post(`/home`).then((res) => {
    dispatch({
      type: SET_HOME_PAGE_DATA,
      payload: res.data,
    });
  });
};

// GET TRENDING ON CATEGORY
export const getTrendingOnCategory = () => (dispatch) => {
  Axios.post(`/trending-category`).then((res) => {
    dispatch({
      type: SET_TRENDING_ON_CATEGORY,
      payload: res.data.category,
    });
  });
};

// GET MENUS DATA
export const getMenusData = () => (dispatch) => {
  Axios.post(`/menus`).then((res) => {
    dispatch({
      type: SET_MENUS_DATA,
      payload: res.data.data,
    });
  });
};

// FOOTER PAGES DATA
export const getPagesData = (data) => (dispatch) => {
  Axios.post(`/page-data`, data).then((res) => {
    dispatch({
      type: SET_PAGES_DATA,
      payload: res.data.data,
    });
  });
};

// SITE SETTING DATA
export const getSiteSettingData = (data) => (dispatch) => {
  Axios.post(`/site-setting`, data).then((res) => {
    dispatch({
      type: SET_SITE_SETTING_DATA,
      payload: res.data.setting,
    });
  });
};

// CONTACT US
export const onContactUs = (data, setValues) => (dispatch) => {
  Axios.post(`/get-in-touch`, data).then((res) => {
    if (res.data.status === true) {
      toast.success("Thank you for getting in touch! ");
      dispatch({
        type: SET_CONTACT_US,
        payload: res.data,
      });
      setValues({ name: "", email: "", phone: "", company: "", message: "" });
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET STORES
export const getAllStores = (data) => (dispatch) => {
  Axios.post(`/stores`, data).then((res) => {
    dispatch({
      type: SET_ALL_STORES,
      payload: res.data.data,
    });
  });
};

// GET SINGLE STORES
export const getSingleStores = (data) => (dispatch) => {
  Axios.post(`/single-store`, data).then((res) => {
    dispatch({
      type: SET_SINGLE_STORES,
      payload: res.data.data,
    });
  });
};

// GET SINGLE STORES CATEGORY
export const getSingleStoresCategory = (data) => (dispatch) => {
  Axios.post(`/single-store-category`, data).then((res) => {
    dispatch({
      type: SET_SINGLE_STORES_CATEGORY,
      payload: res.data.data,
    });
  });
};

// MY ACCOUNT
export const getAccountData = (data) => (dispatch) => {
  Axios.post(`/my-account`, data, config).then((res) => {
    dispatch({
      type: SET_MY_ACCOUNT_DATA,
      payload: res.data.user,
    });
  });
};

// EDIT MY ACCOUNT
export const onEditAccountData = (data) => (dispatch) => {
  Axios.post(`/edit-account`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_EDIT_ACCOUNT_DATA,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET ADDRESS DATA
export const getAddressData = (data) => (dispatch) => {
  Axios.post(`/address`, data, config).then((res) => {
    dispatch({
      type: SET_MY_ADDRESS_DATA,
      payload: res.data.address,
    });
  });
};

// UPDATE ADDRESS DATA
export const onEditAddressData =
  (data, navigate, setAddressValues) => (dispatch) => {
    Axios.post(`/edit-address`, data, config).then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        navigate("/user/address");
        dispatch({
          type: SET_EDIT_ADDRESS_DATA,
          payload: res.data,
        });
        // window.location.reload();
        setAddressValues({});
      } else {
        toast.error(res.data.message);
      }
    });
  };

// DELETE ADDRESS DATA
export const onDeleteAddressData = (data) => (dispatch) => {
  Axios.post(`/delete-address`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_DELETE_ADDRESS_DATA,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET ALL COUNTRY
export const getAllCountries = (data) => (dispatch) => {
  Axios.post(`/all-countries`, data, config).then((res) => {
    dispatch({
      type: SET_COUNTRY,
      payload: res.data.country,
    });
  });
};

// GET ALL STATE
export const getAllState = (data) => (dispatch) => {
  Axios.post(`/all-states`, data, config).then((res) => {
    dispatch({
      type: SET_STATE,
      payload: res.data.state,
    });
  });
};

// GET ALL CITY
export const getAllCity = (data) => (dispatch) => {
  Axios.post(`/all-cities`, data, config).then((res) => {
    dispatch({
      type: SET_CITY,
      payload: res.data.city,
    });
  });
};

// ADD TO WISHLIST
export const onAddToWishList = (data) => (dispatch) => {
  Axios.post(`/add-to-wishlist`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_ADD_TO_WISHLIST,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// REMOVE FROM WISHLIST
export const onRemoveFromWishList = (data) => (dispatch) => {
  Axios.post(`/remove-from-wishlist`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_REMOVE_FROM_WISHLIST,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET WISHLIST
export const getWishListData = (data) => (dispatch) => {
  Axios.post(`/wishlist`, data, config).then((res) => {
    dispatch({
      type: SET_WISHLIST,
      payload: res.data.wishlist,
    });
  });
};

// GET BLOGS
export const getBlogsData = () => (dispatch) => {
  Axios.post(`/blogs`).then((res) => {
    dispatch({
      type: SET_BLOGS,
      payload: res.data.data,
    });
  });
};

// UPDATE BLOGS COMMENTS
export const getBlogsComment = (data) => (dispatch) => {
  Axios.post(`/add-comment`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_BLOGS_COMMENTS,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET SINGLE PRODUCT DETAILS
export const getSingleProductData = (data) => (dispatch) => {
  Axios.post(`/product`, data).then((res) => {
    dispatch({
      type: SET_ALL_PRODUCT,
      payload: res.data.product,
    });
  });
};

// GET ALL PRODUCTS
export const getProductsData = (data) => (dispatch) => {
  Axios.post(`/products`, data).then((res) => {
    dispatch({
      type: SET_PRODUCTS_LIST,
      payload: res.data.product,
    });
  });
};

// GET RELATED PRODUCT
export const getRelatedData = (data) => (dispatch) => {
  Axios.post(`/related-product`, data).then((res) => {
    dispatch({
      type: SET_RELATED_PRODUCT,
      payload: res.data.product,
    });
  });
};

// GET FEEDBACK LIST
export const getFeedbackList = (data) => (dispatch) => {
  Axios.post(`/feedback-list`, data).then((res) => {
    dispatch({
      type: SET_FEEDBACK_LIST,
      payload: res.data.data,
    });
  });
};

// SEARCH LIST
export const getSearchList = (data) => (dispatch) => {
  Axios.post(`/search-product`, data).then((res) => {
    dispatch({
      type: SET_SEARCH_LIST,
      payload: res.data.product,
    });
  });
};

// VENDOR REGISTER
export const getVenddorRegister = (data, setValues) => (dispatch) => {
  Axios.post(`/vendor-signup`, data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_VENDOR_REGISTER,
        payload: res.data.product,
      });

      setValues({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        mobile: "",
        location: "",
        shopName: "",
        shopUrl: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// SET LOCAL CART ITEMS
export const setLocalCartItems = (data) => (dispatch) => {
  dispatch({
    type: SET_LOCAL_CART_ITEMS,
    payload: data,
  });
  localStorage.setItem("syCartList", JSON.stringify(data));
};

// SET LIVE CART ITEMS
export const setLiveCartItems = (data) => (dispatch) => {
  dispatch({
    type: SET_LIVE_CART_ITEMS,
    payload: data,
  });
};

// SET REFRESHER
export const refresherAction = (details) => (dispatch) => {
  dispatch({
    type: SET_REFRESHER_ACTION,
    payload: details,
  });
};

// SET LIVE ADD TO CART
export const setLiveAddToCart = (data, token) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  Axios.post(`/add-cart`, data, config).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: SET_ADD_TO_CART_ITEMS,
        payload: res.data,
      });
    }
  });
};

// GET LIVE CART LIST
export const getLiveCartList = (data) => (dispatch) => {
  Axios.post(`/cart-list`, data, config).then((res) => {
    dispatch({
      type: SET_LIVE_CART_ITEMS,
      payload: res.data.cart,
    });
    dispatch({
      type: SET_LIVE_CART_ITEMS_LIST,
      payload: res.data,
    });
  });
};

// DELETE FROM CART
export const deleteFromCart = (data) => (dispatch) => {
  Axios.post(`/delete-cart`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_DELETE_CART_ITEMS,
        payload: res.data,
      });
    }
  });
};

// UPDATE CART QTY
export const onUpdateCartQty = (data) => (dispatch) => {
  Axios.post(`/qty-update`, data, config).then((res) => {
    dispatch({
      type: SET_UPDATE_CART_QTY,
      payload: res.data,
    });
  });
};

// ADD TO CART LIST
export const onAddToCartList = (data) => (dispatch) => {
  Axios.post(`/add-cart`, data, config).then((res) => {
    if (res.data.status === true) {
      dispatch({
        type: SET_ADD_TO_CART_ITEMS,
        payload: res.data,
      });
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  });
};

// ORDER HISTORY
export const orderHistory = (data) => (dispatch) => {
  Axios.post(`/order-history`, data, config).then((res) => {
    dispatch({
      type: SET_ORDER_HISTORY,
      payload: res.data.order,
    });
  });
};

// ADD ORDER
export const addOrder = (data, navigate) => (dispatch) => {
  Axios.post(`/add-order`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      navigate("/order-placed");
      dispatch({
        type: SET_ADD_ORDER,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// ORDER TRACK
export const orderTrack = (data) => (dispatch) => {
  Axios.post(`/order-track`, data, config).then((res) => {
    dispatch({
      type: SET_ORDER_TRACK,
      payload: res.data.order,
    });
  });
};

// ADD CARD
export const addCard = (data) => (dispatch) => {
  Axios.post(`/add-card`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_ADD_PAYMENT_CARD,
        payload: res.data,
      });
    }
  });
};

// ALL CARD
export const getAllCard = (data) => (dispatch) => {
  Axios.post(`/all-cards`, data, config).then((res) => {
    dispatch({
      type: SET_ALL_CARD_LIST,
      payload: res.data.payment_cards,
    });
  });
};

// DELETE CARD
export const deleteCard = (data) => (dispatch) => {
  Axios.post(`/delete-card`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_DELETE_CARD_LIST,
        payload: res.data,
      });
    }
  });
};

// CREATE BID
export const createBid = (data) => (dispatch) => {
  Axios.post(`/create-bid`, data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_CREATE_BID_ITEM,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// ALL CARD
export const getAllWinningBidsList = (data) => (dispatch) => {
  Axios.post(`/user-bids`, data, config).then((res) => {
    dispatch({
      type: SET_ALL_WINNING_BID_LIST,
      payload: res.data.bids,
    });
  });
};

// DATE AVAILABILITY
export const onDateAvailability = (data) => (dispatch) => {
  Axios.post("/date-availability", data).then((res) => {
    dispatch({
      type: SET_DATE_AVAILABILTY,
      payload: res.data.data,
    });
  });
};

// SET BOOK NOW
export const onBookNow = (data, onCloseModal) => (dispatch) => {
  Axios.post("/add-cart", data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      onCloseModal();
      dispatch({
        type: SET_BOOK_NOW,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// BOOK SERVICES
export const bookedServices = (data) => (dispatch) => {
  Axios.post("/booked-services", data, config).then((res) => {
    dispatch({
      type: SET_BOOKED_SERVICES,
      payload: res.data.order,
    });
  });
};

// APPLY COUPON
export const onApplyCoupon = (data) => (dispatch) => {
  Axios.post("/apply-coupon", data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_APPLY_COUPON,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// ALL WALLET TRANSECTION
export const allWalletTransection = (data) => (dispatch) => {
  Axios.post("/wallet-transaction", data, config).then((res) => {
    dispatch({
      type: SET_ALL_WALLET_TRANSECTION,
      payload: res.data,
    });
  });
};

// ADD WALLET AMOUNT
export const addWalletAmount = (data, onCloseModal) => (dispatch) => {
  Axios.post("/add-wallet-amount", data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      onCloseModal();
      dispatch({
        type: SET_WALLET,
        payload: res.data,
      });
    } else {
      toast.error(res.data.message);
    }
  });
};

// ORDER CANCEL
export const cancelOrder = (data) => (dispatch) => {
  Axios.post("/order-cancel", data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      dispatch({
        type: SET_CANCEL_ORDER,
        payload: res.data,
      });
      window.location.reload();
    } else {
      toast.error(res.data.message);
    }
  });
};

// FEEDBACK
export const onFeedback = (data) => (dispatch) => {
  Axios.post("/feedback", data, config).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      window.location.reload();
    } else {
      toast.error(res.data.message);
    }
  });
};

// VERIFY OTP EMAIL
export const verifyOtpEmail = (data) => (dispatch) => {
  Axios.post("/verify-otp-email", data).then((res) => {
    if (res.data.status === true) {
      toast.success(res.data.message);
      window.location.reload();
    } else {
      toast.error(res.data.message);
    }
  });
};

// GET BRANDS
export const getAllBrands = (data) => (dispatch) => {
  Axios.post("/brands", data, config).then((res) => {
    dispatch({
      type: SET_ALL_BRANDS,
      payload: res.data.brand,
    });
  });
};

// SHIPPING METHODS
export const onShippingMethod = (data) => (dispatch) => {
  Axios.post("/shipping-methods", data, config).then((res) => {
    dispatch({
      type: SET_SHIPPING_METHODS,
      payload: res.data.shipping,
    });
  });
};

// GET ALL HOME PRODUCTS
export const getAllHomeProducts = (data) => (dispatch) => {
  Axios.post("/get-all-home-products", data, config).then((res) => {
    dispatch({
      type: SET_ALL_HOME_PRODUCTS_LIST,
      payload: res.data.products,
    });
  });
};

export const getAttributeList = (data) => (dispatch) => {
  Axios.get("/attribute-list", data, config).then((res) => {
    dispatch({
      type: SET_ATTRIBUTE_LIST,
      payload: res.data,
    });
  });
};
