import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPagesData } from "../redux/homeAction";
import Loader from "../Components/Home/loader/loader";
import { Link } from "react-router-dom";

const Privacy = () => {
  const dispatch = useDispatch();
  const privacyAndPolicy = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 10,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">privacy & policy</h1>
        </div>
      </div>
      <div className="container">
        <div className="terms-page-wrapper">
          {/* <small>Effective date: 2022-09-10</small>
          <div>
            <h3>1. Introduction</h3>
            <p>Welcome to Smileszy.</p>
            <p>
              Smileszy (“us”, “we”, or “our”) operates (
              <Link to="#">www.Smileszy.com</Link>,{" "}
              <Link to="#">www.smileszy.ng</Link>,
              <Link to="#">www.smileszy.co.uk</Link>,{" "}
              <Link to="#">www.smileszy.gh</Link>, (hereinafter referred to as
              “Service”).
            </p>
            <p>
              Our Privacy Policy governs your visit to <b>www.Smileszy</b> and
              explains how we collect, safeguard and disclose information that
              results from your use of our Service.
            </p>
            <p>
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy. Unless otherwise defined in this Privacy Policy,
              the terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions.
            </p>
            <p>
              Our Terms and Conditions <b>(“Terms”)</b> govern all use of our
              Service and together with the Privacy Policy constitutes your
              agreement with us <b>(“agreement”)</b>.
            </p>
          </div>
          <div>
            <h3>2. Definitions</h3>
            <p>
              <b>SERVICE</b> means the <b>www.Smileszy.com</b> website operated
              by Smileszy.
            </p>
            <p>
              <b>PERSONAL DATA</b> means data about a living individual who can
              be identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
            <p>
              <b>USAGE DATA</b> is data collected automatically either generated
              by the use of Service or from Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
            <p>
              <b>COOKIES</b> are small files stored on your device (computer or
              mobile device).
            </p>
            <p>
              <b>DATA CONTROLLER</b> means a natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal data are,
              or are to be, processed. For the purpose of this Privacy Policy,
              we are a Data Controller of your data.
            </p>
            <p>
              <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or
              legal person who processes the data on behalf of the Data
              Controller. We may use the services of various Service Providers
              in order to process your data more effectively.
            </p>
            <p>
              <b>DATA SUBJECT</b> is any living individual who is the subject of
              Personal Data.
            </p>
            <p>
              <b>THE USER</b> is the individual using our Service. The User
              corresponds to the Data Subject, who is the subject of Personal
              Data.
            </p>
          </div>

          <div>
            <h3>3. Information Collection and Use</h3>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>
          </div>
          <div>
            <h3>4. Types of Data Collected</h3>
            <h5>Personal Data</h5>
            <p class="lh-lg">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“<b>Personal Data</b>”). Personally identifiable
              information may include, but is not limited to:
            </p>
            <p>0.1. Email address</p>
            <p>0.2. First name and last name</p>
            <p>0.3. Phone number</p>
            <p>0.4. Address, Country, State, Province, ZIP/Postal code, City</p>
            <p>0.5. Cookies and Usage Data</p>
            <p class="lh-lg">
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link.
            </p>
            <h5>Usage Data</h5>
            <p class="lh-lg">
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through any
              device (“<b>Usage Data</b>”).
            </p>
            <p class="lh-lg">
              This Usage Data may include information such as your computer’s
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
            <p class="lh-lg">
              When you access Service with a device, this Usage Data may include
              information such as the type of device you use, your device unique
              ID, the IP address of your device, your device operating system,
              the type of Internet browser you use, unique device identifiers
              and other diagnostic data.
            </p>

            <h5>Location Data</h5>
            <p class="lh-lg">
              We may use and store information about your location if you give
              us permission to do so (“<b>Location Data</b>”). We use this data
              to provide features of our Service, to improve and customize our
              Service.
            </p>
            <p class="lh-lg">
              You can enable or disable location services when you use our
              Service at any time by way of your device settings.
            </p>
            <h5>Tracking Cookies Data</h5>
            <p class="lh-lg">
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>
            <p class="lh-lg">
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </p>
            <p class="lh-lg">
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <p class="lh-lg">
              0.1. <b>Session Cookies</b>: We use Session Cookies to operate our
              Service.
            </p>
            <p class="lh-lg">
              0.2. <b>Preference Cookies</b>: We use Preference Cookies to
              remember your preferences and various settings.
            </p>
            <p class="lh-lg">
              0.3. <b>Security Cookies</b>: We use Security Cookies for security
              purposes.
            </p>
            <p class="lh-lg">
              0.4. <b>Advertising Cookies</b>: Advertising Cookies are used to
              serve you with advertisements that may be relevant to you and your
              interests.
            </p>
          </div>
          <div>
            <h3>5. Use of Data</h3>
            <p class="lh-lg">
              Smileszy uses the collected data for various purposes:
            </p>
            <p class="lh-lg">0.1. to provide and maintain our Service;</p>
            <p class="lh-lg">
              0.2. to notify you about changes to our Service;
            </p>
            <p class="lh-lg">
              0.3. to allow you to participate in interactive features of our
              Service when you choose to do so;
            </p>
            <p class="lh-lg">0.4. to provide customer support;</p>
            <p class="lh-lg">
              0.5. to gather analysis or valuable information so that we can
              improve our Service;
            </p>
            <p class="lh-lg">0.6. to monitor the usage of our Service;</p>
            <p class="lh-lg">
              0.7. to detect, prevent and address technical issues;
            </p>
            <p class="lh-lg">
              0.8. to fulfil any other purpose for which you provide it;
            </p>
            <p class="lh-lg">
              0.9. to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;
            </p>
            <p class="lh-lg">
              0.10. to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </p>
            <p class="lh-lg">
              0.11. to provide you with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless you have opted not to receive such
              information;
            </p>
            <p class="lh-lg">
              0.12. in any other way we may describe when you provide the
              information;
            </p>
            <p class="lh-lg">0.13. for any other purpose with your consent.</p>
          </div>
          <div>
            <h3> 6. Retention of Data</h3>
            <p class="lh-lg">
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p class="lh-lg">
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </p>
          </div>
          <div>
            <h3>7. Transfer of Data</h3>
            <p class="lh-lg">
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>
            <p class="lh-lg">
              If you are located outside Europe, Africa and Asia and choose to
              provide information to us, please note that we transfer the data,
              including Personal Data, to Europe, Africa and Asia and process it
              there.
            </p>
            <p class="lh-lg">
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p class="lh-lg">
              Smileszy will take all the steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of your data and other
              personal information.
            </p>
          </div>
          <div>
            <h3>8. Disclosure of Data</h3>
            <p class="lh-lg">
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <h5>0.1. Disclosure for Law Enforcement.</h5>
            <p class="lh-lg">
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </p>

            <h5>0.2. Business Transaction.</h5>
            <p class="lh-lg">
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.
            </p>

            <h5>0.3. Other cases. We may disclose your information also:</h5>
            <p class="lh-lg">0.3.1. to our subsidiaries and affiliates;</p>
            <p class="lh-lg">
              0.3.2. to contractors, service providers, and other third parties
              we use to support our business;
            </p>
            <p class="lh-lg">
              0.3.3. to fulfill the purpose for which you provide it;
            </p>
            <p class="lh-lg">
              0.3.4. for the purpose of including your company’s logo on our
              website;
            </p>
            <p class="lh-lg">
              0.3.5. for any other purpose disclosed by us when you provide the
              information;
            </p>
            <p class="lh-lg">0.3.6. with your consent in any other cases;</p>
            <p class="lh-lg">
              0.3.7. if we believe disclosure is necessary or appropriate to
              protect the rights, property, or safety of the Company, our
              customers, or others.
            </p>
          </div>
          <div>
            <h3>9. Security of Data</h3>
            <p class="lh-lg">
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </div>
          <div>
            <h3>
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR){" "}
            </h3>

            <p class="lh-lg">
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR.
            </p>
            <p class="lh-lg">
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </p>
            <p class="lh-lg">
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at …………………….
            </p>
            <p class="lh-lg">
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <p class="lh-lg">
              0.1. the right to access, update or to delete the information we
              have on you;
            </p>
            <p class="lh-lg">
              0.2. the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </p>
            <p class="lh-lg">
              0.3. the right to object. You have the right to object to our
              processing of your Personal Data;
            </p>
            <p class="lh-lg">
              0.4. the right of restriction. You have the right to request that
              we restrict the processing of your personal information;
            </p>
            <p class="lh-lg">
              0.5. the right to data portability. You have the right to be
              provided with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </p>
            <p class="lh-lg">
              0.6. the right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </p>
            <p class="lh-lg">
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not able to
              provide Service without some necessary data.
            </p>
          </div>
          <div>
            <h3>
              11. What personal information we have about you. If you make this
              request, we will return to you:
            </h3>
            <p class="lh-lg">
              0.0.1. The categories of personal information we have collected
              about you.
            </p>
            <p class="lh-lg">
              0.0.2. The categories of sources from which we collect your
              personal information.
            </p>
            <p class="lh-lg">
              0.0.3. The business or commercial purpose for collecting or
              selling your personal information.
            </p>
            <p class="lh-lg">
              0.0.4. The categories of third parties with whom we share personal
              information.
            </p>
            <p class="lh-lg">
              0.0.5. The specific pieces of personal information we have
              collected about you.
            </p>
            <p class="lh-lg">
              0.0.6. A list of categories of personal information that we have
              sold, along with the category of any other company we sold it to.
              If we have not sold your personal information, we will inform you
              of that fact.
            </p>
            <p class="lh-lg">
              0.0.7. A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.
            </p>
            <p class="lh-lg">
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous 12
              months.
            </p>
            <h5 class="lh-lg">
              0.2. To delete your personal information. If you make this
              request, we will delete the personal information we hold about you
              as of the date of your request from our records and direct any
              service providers to do the same. In some cases, deletion may be
              accomplished through de-identification of the information. If you
              choose to delete your personal information, you may not be able to
              use certain functions that require your personal information to
              operate.
            </h5>
            <h5 class="lh-lg">
              0.3. To stop selling your personal information. We don’t sell or
              rent your personal information to any third parties for any
              purpose. We do not sell your personal information for monetary
              consideration. However, under some circumstances, a transfer of
              personal information to a third party, or within our family of
              companies, without monetary consideration may be considered a
              “sale” under California law. You are the only owner of your
              Personal Data and can request disclosure or deletion at any time.
            </h5>
            <p class="lh-lg">
              If you submit a request to stop selling your personal information,
              we will stop making such transfers.
            </p>
            <p class="lh-lg">
              Please note, if you ask us to delete or stop selling your data, it
              may impact your experience with us, and you may not be able to
              participate in certain programs or membership services which
              require the usage of your personal information to function. But in
              no circumstances, we will discriminate against you for exercising
              your rights.
            </p>
            <p class="lh-lg">
              To exercise your California data protection rights described
              above, please send your request(s) by email: …………….
            </p>
            <p class="lh-lg">
              Your data protection rights, described above, are covered by the
              CCPA, short for the California Consumer Privacy Act. To find out
              more, visit the official California Legislative Information
              website. The CCPA took effect on 01/01/2020.
            </p>
          </div>
          <div>
            <h3>13. Listing Policy</h3>
            <p class="lh-lg">
              Smileszy may publish and promote your listings, including related
              content such as username, product reviews and feedback on the
              websites or in the applications, services and tools of other
              Smileszy Inc. corporate family members or cooperating third-party
              operators of websites, applications, services and tools.
            </p>
            <p class="lh-lg">
              When listing an item for sale on our Services, you agree to comply
              with Smileszy's <b>Listing Policy</b> and selling rules under our
              policy and also agree that:
            </p>
            <ul>
              <li class="lh-lg">
                You assume full responsibility for the item offered and the
                accuracy and content of the listing,
              </li>
              <li class="lh-lg">
                Your listing may not be immediately searchable by keyword or
                category for several hours (or up to 24 hours in some
                circumstances). Smileszy can't guarantee exact listing duration,
              </li>
              <li class="lh-lg">
                Your fixed-price listings may renew automatically every calendar
                month, based on the listing terms at the time, until all
                quantities sell or the listing is ended by you or Smileszy, in
                its sole discretion,
              </li>
              <li class="lh-lg">
                The content you provide complies with all of our listing
                policies, including the Images, videos and text policy,
              </li>
              <li class="lh-lg">
                Content that violates any of Smileszy's policies may be
                modified, obfuscated or deleted at Smileszy's sole discretion,
              </li>
              <li class="lh-lg">
                We may revise product data associated with listings to
                supplement, remove, or correct information,
              </li>
              <li class="lh-lg">
                We strive to create a marketplace where buyers find what they
                are looking for. Therefore, the appearance or placement of
                listings in search and browse results will depend on a variety
                of factors, including, but not limited to:{" "}
              </li>
              <ul>
                <li class="lh-lg">
                  buyer's location, search query, browsing site, and history;
                </li>
                <li class="lh-lg">
                  item's location, listing format, price and shipping cost,
                  terms of service, end time, history, and relevance to the user
                  query;
                </li>
                <li class="lh-lg">
                  seller's history, including listing practices, detail selling
                  rating, Smileszy policy compliance, feedback, and defect rate;
                  and
                </li>
                <li class="lh-lg">
                  number of listings matching the buyer's query.
                </li>
              </ul>
              <li class="lh-lg">
                You will not sell and will promptly remove all listings for any
                product recalled by a manufacturer or governmental agency if the
                sale of the product is prohibited by law or regulation or the
                product poses a health or safety hazard as specified by any
                governmental agency. Smileszy has no responsibility or liability
                for the safety or performance of any product that you list or
                sell using our Services, including any product that is subject
                to a recall. You are solely responsible for any non-conformity
                or defect in, or compliance with any public or private recall of
                any product you list or sell using our Services,
              </li>
            </ul>
          </div>
          <div>
            <h3>13. Service Providers</h3>
            <p class="lh-lg">
              We may employ third party companies and individuals to facilitate
              our Service (“<b>Service Providers</b>”), provide Service on our
              behalf, perform Service-related services or assist us in analysing
              how our Service is used. These third parties have access to your
              Personal Data only to perform these tasks on our behalf and are
              obligated not to disclose or use it for any other purpose.
            </p>
          </div>
          <div>
            <h3>14. Analytics</h3>
            <p class="lh-lg">
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>
          </div>
          <div>
            <h3>15. CI/CD tools</h3>
            <p class="lh-lg">
              We may use third-party Service Providers to automate the
              development process of our Service.
            </p>
          </div>
          <div>
            <h3>16. Advertising</h3>
            <p class="lh-lg">
              We may use third-party Service Providers to show advertisements to
              you to help support and maintain our Service.
            </p>
          </div>
          <div>
            <h3>17. Behavioral Remarketing</h3>
            <p class="lh-lg">
              We may use remarketing services to advertise on third party
              websites to you after you visited our Service. We and our
              third-party vendors use cookies to inform, optimise and serve ads
              based on your past visits to our Service.
            </p>
          </div>
          <div>
            <h3>18. Payments</h3>
            <p class="lh-lg">
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors).
            </p>
            <p class="lh-lg">
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy.{" "}
            </p>
            <p class="lh-lg">
              These payment processors adhere to the standards set by PCI-DSS as
              managed by the PCI Security Standards Council, which is a joint
              effort of brands like Visa, Mastercard, American Express and
              Discover. PCI-DSS requirements help ensure the secure handling of
              payment information.
            </p>
          </div>
          <div>
            <h3>19. Links to Other Sites</h3>
            <p class="lh-lg">
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p class="lh-lg">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </div>
          <div>
            <h3>20. Children’s Privacy</h3>
            <p class="lh-lg">
              Our Services are not intended for use by children under the age of
              18 (“<b>Child” or “Children</b>”).
            </p>
            <p class="lh-lg">
              We do not knowingly collect personally identifiable information
              from Children under 18. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </p>
          </div>
          <div>
            <h3>21. Changes to This Privacy Policy</h3>
            <p class="lh-lg">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p class="lh-lg">
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </p>
            <p class="lh-lg">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>
          <div>
            <h3>22. Contact Us</h3>
            <p class="lh-lg">
              If you have any questions about this Privacy Policy, please
              contact us by email: ……………..
            </p>
          </div> */}

          {privacyAndPolicy ? (
            <div
              dangerouslySetInnerHTML={{
                __html: privacyAndPolicy && privacyAndPolicy.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default Privacy;
