import React, { useState } from "react";
import { Link } from "react-router-dom";
import Login from "../../popups/Login/Login";
import "./OrderPlaced.scss";

const OrderPlaced = () => {
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  return (
    <>
      <div className="container">
        <div className="orderplaced-wrapper">
          <div className="orderplaced">
            <img src="/img/placing-order.gif" alt="" />
            <div className="thankyou-right">
              <h1>Thank You!</h1>
              <p>
                Your Order Has Been Placed, and a translater will start work
                shorty...
              </p>
              {localStorage.getItem("user-token") ? (
                <Link to="/user/myorders">
                  <div className="myorder-button">My Orders</div>
                </Link>
              ) : (
                <div className="myorder-button" onClick={openLoginModal}>
                  My Orders
                </div>
              )}
              <Link to="/products">
                <div className="myshopping-button">Continue Shopping</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default OrderPlaced;
