import React from "react";
import "./support.scss";

const SupportForm = () => {
  return (
    <>
      <div className="supportform-wrapper">
        <div className="container">
          <div>
            <form>
              <select>
                {/* <option>Requst of product</option> */}
                <option>Return</option>
                <option>refund</option>
              </select>
              <span>Reason of Request</span>
              <textarea
                rows={5}
                cols={15}
                placeholder="Complaint About,"
              ></textarea>
            </form>
            <label>
              <b>Attach Files</b>
            </label>
            <input type="file" />
            <button>Submit Requsest</button>
          </div>
          <div>
            <p>Refund Guidelines</p>
            <ul>
              <li>Product will be cross Checkesd by Store Owner.</li>
              <li>Refund items with in 30 days</li>

              <li>Product should not be damaged.</li>
              <li>shipping cost shoud be not refundable.</li>
              <li>
                Refund amount will be sent to customer with in 5 working days
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportForm;
