import React from "react";
import { Link } from "react-router-dom";
import "./thankuPage.scss";

const ThankuPage = () => {
  return (
    <>
      <section className="thankupage-wrapper">
        <div className="container">
          <div className="thankupagecontaint-wrapp">
            <img src="/footericons/check.png" alt="#" />
            <h2 className="thankuline">Thank You! </h2>

            {/* <ShippingAddress /> */}

            {/* <RecentOrder /> */}
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              amet at praesentium quos reprehenderit ab rem ad voluptates soluta
              mollitia, incidunt, ex aut neque in? Minima ea neque magni. Iure!
            </p>
            <Link to="/">
              <button className="continuebtn">Continue to shopping</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankuPage;
