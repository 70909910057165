import React, { useState } from "react";
import "./OrderPage.scss";
import OrderEmpty from "../../../Cart/orderEmptyView/OrderEmpty";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import FeedBack from "../feedback/FeedBack";
import OrderCancelForm from "../../../../popups/orderCancel/OrderCancelForm";

const OrderPage = ({ setCurrentTab, getOrderHistory }) => {
  const navigate = useNavigate();
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openOrderCancel, setOpenOrderCancel] = useState(false);
  const [productId, setProductId] = useState();

  const calculateTotal = (orderItem) => {
    var totalPrice = null;

    orderItem &&
      orderItem.forEach(function (value, index, arry) {
        totalPrice += parseInt(value.total_price);
      });

    return totalPrice;
  };

  return (
    <>
      <div className="OrderPage-wrapper">
        {getOrderHistory && getOrderHistory.length === 0 ? (
          <OrderEmpty />
        ) : (
          <>
            {getOrderHistory &&
              getOrderHistory.map((data, key) => (
                <section className="recent-orderwrapper" key={key}>
                  <div className="recent-order-toptab">
                    <div className="order-place-wrapp">
                      <p className="order-place-line">Order-placed</p>
                      <p className="order-date">
                        {moment(data?.created_date, "DD/MM/YYYY").format(
                          "DD MMMM YYYY"
                        )}
                      </p>
                    </div>
                    <div className="shipto-wrapper">
                      <p className="shipto-line">SHIP TO</p>
                      <p className="owner-order-name">
                        {data?.order_meta?.shipping_first_name}
                      </p>
                      <p className="short-addras">
                        {data?.order_meta?.shipping_address2}
                      </p>
                    </div>
                    <div className="total-wrapper">
                      <p className="total-tag">Total</p>
                      <p className="total-amounts">
                        {data?.order_meta?.currency_sign}
                        {calculateTotal(data?.order_item)}
                      </p>
                    </div>
                    <div className="order-details-wrapper">
                      <p className="order-number">Order #{data?.order_id}</p>
                      <p className="vierorder-details-link">
                        <span
                          onClick={() => {
                            setCurrentTab("orderdetails");
                            navigate("/user/orderdetails");
                            localStorage.setItem("orderViewId", data?.id);
                          }}
                        >
                          View Order Details
                        </span>{" "}
                        | <span>Download Invoice</span>
                      </p>
                    </div>
                  </div>
                  {data &&
                    data.order_item &&
                    data.order_item.map((item, key) => (
                      <div className="order-items-wrapper" key={key}>
                        {item.status === "delivered" ? null : (
                          <p className="deliverd-status">
                            Delivery Date{" "}
                            {moment(data?.expected_date).format("dddd")}
                          </p>
                        )}

                        <section className="order-product-wrapper">
                          <div className="order-product-wrapperImage">
                            <div className="order-productimg">
                              <img src={item?.featured_image} alt="#" />
                            </div>
                            <div className="order-items-info">
                              <p className="order-product-name">
                                {item?.product_name}
                              </p>
                              {/* <p className="order-product-Sold-by">
                              Sold by: Sami Kotob
                            </p>
                            <p className="order-product-Sold-by">
                              Return eligiblr through 20-Jan-2022
                            </p> */}
                              <label className="price-tag">
                                {data?.order_meta?.currency_sign}
                                {parseInt(item?.total_price).toFixed(2)}
                              </label>
                            </div>
                          </div>
                          <div className="orders-btns">
                            {item?.status === "delivered" ? (
                              <div className="trackpackage-btn">
                                Delivered
                                <i className="fas fa-angle-right" />
                              </div>
                            ) : item?.status === "cancel requested" ||
                              item?.status === "cancelled" ? null : (
                              <div
                                className="trackpackage-btn"
                                onClick={() => {
                                  setCurrentTab("ordertrack");
                                  navigate("/user/ordertrack");
                                  localStorage.setItem("orderViewId", data?.id);
                                  localStorage.setItem(
                                    "orderTrackId",
                                    item?.id
                                  );
                                }}
                              >
                                Track Package
                                <i className="fas fa-angle-right" />
                              </div>
                            )}

                            {item?.status === "delivered" ? (
                              <div
                                className="trackpackage-btn"
                                onClick={() => {
                                  setOpenFeedback(true);
                                  setProductId(item);
                                }}
                              >
                                Product Feedback
                                <i className="fas fa-angle-right" />
                              </div>
                            ) : item?.status === "cancel requested" ||
                              item?.status === "cancelled" ? null : (
                              <div
                                className="trackpackage-btn"
                                style={{ cursor: "not-allowed" }}
                              >
                                Product Feedback
                                <i className="fas fa-angle-right" />
                              </div>
                            )}
                            {item?.status === "cancel requested" ||
                            item?.status === "cancelled" ? (
                              <div className="trackpackage-btn">
                                Cancelled
                                <i className="fas fa-angle-right" />
                              </div>
                            ) : item?.status === "delivered" ? null : (
                              <div
                                className="trackpackage-btn"
                                onClick={() => {
                                  setOpenOrderCancel(true);
                                  setProductId(item);
                                }}
                              >
                                Cancel Order
                                <i className="fas fa-angle-right" />
                              </div>
                            )}
                          </div>
                        </section>
                      </div>
                    ))}
                </section>
              ))}
          </>
        )}
      </div>
      <FeedBack
        open={openFeedback}
        productId={productId}
        onCloseModal={() => setOpenFeedback(false)}
      />
      <OrderCancelForm
        open={openOrderCancel}
        productId={productId}
        onCloseModal={() => setOpenOrderCancel(false)}
      />
    </>
  );
};

export default OrderPage;
