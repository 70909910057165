import React from "react";
import "./categoriesdrop.scss";
import { Link, useNavigate } from "react-router-dom";

const CategoriesDrop = ({ categoriesList, wrapperRef }) => {
  const navigate = useNavigate();

  const showProduct = (slug) => {
    navigate(`/category/${slug}`);
  };

  return (
    <>
      <div ref={wrapperRef} className="Categories-dropdown-wrapper">
        <div className="categories-wrapp">
          {categoriesList &&
            categoriesList.map((item, key) => (
              <ul key={key}>
                <li onClick={() => showProduct(item.slug)}>
                  <b>{item.title}</b>
                </li>
                {item.child_category
                  .filter((data, key) => key < 2)
                  .map((item, key) => (
                    <li key={key}>
                      <span onClick={() => showProduct(item.slug)}>
                        {item.title}
                      </span>
                    </li>
                  ))}
              </ul>
            ))}
        </div>
        <div className="seeAllcategorieslink-wrapp">
          <Link to="/products">
            See All Categories <i className="fa-solid fa-angle-right ms-1"></i>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CategoriesDrop;
