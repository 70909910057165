import React from "react";
import BestFees from "./bestfees/BestFees";
import "./SellWithUs.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SellWithUs = () => {
  const getSiteSettingDetails = useSelector(
    (state) => state.home.getSiteSettingDetails
  );

  return (
    <>
      <div
        className="pages-bg-img"
        style={{
          backgroundImage: `url(${
            getSiteSettingDetails && getSiteSettingDetails.sale_with_us
          })`,
        }}
      >
        <div className="container">
          <div className="sellpage-tag-wrapper">
            <p className="pages-uper-text slide-in-left">
              Millions Of Shoppers Can't Wait To See What You Have In-Store
            </p>
            <Link to="/vendor-registration">
              <button className="pages-tag slide-in-bottom">
                Sell With us
              </button>
            </Link>
          </div>
        </div>
      </div>
      <BestFees />
    </>
  );
};

export default SellWithUs;
