import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import "./Calendar.scss";
import { Modal } from "react-responsive-modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { onBookNow, onDateAvailability } from "../../redux/homeAction";
import moment from "moment";
import { toast } from "react-toastify";

const Calender = ({ BookServiceOpen, onCloseModal, productDetail, qty }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(new Date());
  const dateAvailability = useSelector((state) => state.home.dateAvailability);
  const [selectDate, setSelectDate] = useState();
  const [selectTime, setSelectTime] = useState();

  useEffect(() => {
    if (dateAvailability) {
      for (let i = 0; i < dateAvailability.length; i++) {
        if (dateAvailability[i].date === moment(value).format("yyyy-MM-DD")) {
          setSelectDate(dateAvailability[i]);
        }
      }
    }
  }, [dateAvailability]);

  useEffect(() => {
    const data = {
      product_id: productDetail && productDetail.id,
      month: moment(value).format("MMM"),
      year: moment(value).format("YYYY"),
      qty: qty,
    };
    dispatch(onDateAvailability(data));
  }, [qty]);

  const onDateChange = (value) => {
    setValue(value);

    const data = {
      product_id: productDetail && productDetail.id,
      month: moment(value).format("MMM"),
      year: moment(value).format("YYYY"),
      qty: qty,
    };
    dispatch(onDateAvailability(data));
  };

  const handleChangeTime = (e) => {
    setSelectTime(e.target.value);
  };

  const handleBookNow = () => {
    const data = {
      product_id: productDetail && productDetail.id,
      quantity: qty,
      device_id: "f47a2d1c562ceed9",
      variation: "",
      start_date: moment(value).format("YYYY-MM-DD"),
      time_sloat: selectTime,
    };

    if (!value) {
      toast.error("Select Date");
    } else if (!selectTime) {
      toast.error("Select Time");
    } else {
      dispatch(onBookNow(data, onCloseModal));
    }
  };

  return (
    <>
      <Modal
        open={BookServiceOpen}
        onClose={onCloseModal}
        classNames={{ modal: "Datepopup-modal" }}
      >
        <div className="datetime-wrapepr">
          <h6 className="select-dateline">Select Date & Time</h6>
          <Calendar
            onChange={onDateChange}
            value={value}
            minDate={new Date()}
          />
          <div className="time-wrapper">
            <h6 className="select-timeline">Select Time</h6>
            <select className="select-time-wrapper" onChange={handleChangeTime}>
              <option>Select Time</option>
              {selectDate &&
                selectDate.time_sloats &&
                selectDate.time_sloats.map((time, key) => (
                  <option key={key}>{time}</option>
                ))}
            </select>
            <h6 className="total-priceline">
              Total Price :{" "}
              <span>
                {productDetail && productDetail.currency_sign}
                {productDetail && productDetail.s_price}
              </span>
            </h6>
          </div>

          {/* <span className="loader-spinner">
            <i className="fa-solid fa-spinner loading-gif"></i>
          </span> */}

          <button className="contormdate-btn" onClick={handleBookNow}>
            Confirm Date & Time
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Calender;
