import React from "react";
import "./storeSideform.scss";

const StoreSideform = ({ getSingleStoresCategoryList, filterProduct }) => {
  return (
    <>
      <div className="sideform-wrapper">
        <div className="store-category-wrappers">
          <h4 className="store-category-heading">Store Category</h4>
          <div className="productscategory-wrappernew">
            <div className="product-category-itmes-wrapper">
              <ul>
                {getSingleStoresCategoryList &&
                  getSingleStoresCategoryList.map((item, key) => (
                    <li key={key} onClick={() => filterProduct(item)}>
                      <label>
                        <span>{item.title}</span>
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreSideform;
