import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./BidsProducts.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Home/loader/loader";
import {
  getAllHomeProducts,
  onAddToWishList,
  onRemoveFromWishList,
} from "../../redux/homeAction";
import Login from "../../popups/Login/Login";

const BidsProducts = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const getWishList = useSelector((state) => state.home.getWishList);
  const homeProductList = useSelector((state) => state.home.homeProductList);
  const getAllHomeProductsList = useSelector(
    (state) => state?.home?.getAllHomeProductsList
  );
  const currencyCode = JSON.parse(localStorage.getItem("currencyCode"));
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  useEffect(() => {
    const data = {
      currency_code:
        currencyCode && currencyCode.currencyCode
          ? currencyCode && currencyCode.currencyCode
          : "USD",
      type: "bidding",
    };
    dispatch(getAllHomeProducts(data));
  }, []);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const handleAddToWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onAddToWishList(data));
  };

  const handleRemoveFromWishList = (id) => {
    const data = {
      product_id: id,
    };
    dispatch(onRemoveFromWishList(data));
  };

  return (
    <>
      {loader === false ? (
        <div className="container">
          <div className="best-dealsitems-wrappers">
            {getAllHomeProductsList &&
              getAllHomeProductsList.map((data, index) => (
                <div className="bidproduct-wrapp" key={index}>
                  <div className="hover-wish-list-wrappper">
                    <div className="wish-list-imgs">
                      {localStorage.getItem("user-token") ? (
                        <img
                          src={
                            getWishList?.find((item) => item?.id === data?.id)
                              ? "/img/ha2.png"
                              : "/img/ha.png"
                          }
                          alt={data?.pname}
                          className="img-fluid"
                          onClick={
                            !getWishList?.find((item) => item?.id == data?.id)
                              ? () => handleAddToWishList(data?.id)
                              : () =>
                                  handleRemoveFromWishList(
                                    getWishList?.find(
                                      (item) => item?.id == data?.id
                                    )
                                  )
                          }
                        />
                      ) : (
                        <img
                          src="/img/ha.png"
                          alt=""
                          className="img-fluid"
                          onClick={openLoginModal}
                        />
                      )}
                    </div>
                  </div>
                  <Link to={`/product/${data.slug}`}>
                    <div className="product-img-wrapper">
                      <img src={data.featured_image} alt="#" />
                    </div>
                  </Link>
                  <div className="product-description-wrapper">
                    <Link to={`/product/${data.slug}`}>
                      <p className="items-name">{data.pname}</p>
                    </Link>

                    {data.in_stock == 0 ? (
                      <p className="instock" style={{ color: "red" }}>
                        Out Of Stock
                      </p>
                    ) : (
                      <p className="instock">In stock</p>
                    )}
                    <span className="last-price">Starting BId</span>
                    <span className="bidamount">
                      {data.currency_sign}
                      {data.current_bid}
                    </span>
                    <Link to={`/product/${data.slug}`}>
                      <button className="bid-now-btn">Bid Now </button>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default BidsProducts;
