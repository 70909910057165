import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { onAddToCartList } from "../../../../redux/homeAction";
import OrderEmpty from "../../../Cart/orderEmptyView/OrderEmpty";
import "./MyBids.scss";

const MyBids = ({ winningBidList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentId, setCurrentId] = useState(null);

  const showProduct = (slug) => {
    console.log(slug);
    navigate(`/product/${slug}`);
  };

  const addToCartHandler = (product) => {
    setCurrentId(product.product_id);
    setTimeout(() => {
      setCurrentId(null);
    }, 500);

    if (product.in_stock == 0) {
      toast.error("Out Of Stock");
    } else {
      // toast.success("Added to cart");
      const data = {
        product_id: product.product_id,
        quantity: 1,
      };
      dispatch(onAddToCartList(data));
    }
  };
  console.log(winningBidList?.map((items) => console.log(items)));
  return (
    <div className="OrderPage-wrapper">
      {winningBidList && winningBidList.length === 0 ? (
        <OrderEmpty />
      ) : (
        <section className="recent-orderwrapper">
          {winningBidList &&
            winningBidList.map((item, key) => (
              <div className="order-items-wrapper" key={key}>
                <p className="deliverd-status">Winning Date</p>
                <span>{moment(item.created_at).format("DD-MMM-YYYY")}</span>
                <section className="order-product-wrapper">
                  <div className="order-product-wrapperImage">
                    <div className="order-productimg">
                      <img
                        src={item.featured_image}
                        alt="#"
                        onClick={() => showProduct(item.slug)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="order-items-info">
                      <p
                        className="order-product-name"
                        onClick={() => showProduct(item.slug)}
                        style={{ cursor: "pointer" }}
                      >
                        {item.pname}
                      </p>

                      <label className="price-tag">
                        {item.currency_sign}
                        {item.bid_price}
                      </label>
                    </div>
                  </div>
                  <div className="orders-btns">
                    <div
                      className="trackpackage-btn"
                      onClick={() => addToCartHandler(item)}
                      style={{ backgroundColor: "#f4a34d", color: "#fff" }}
                    >
                      Add to cart
                      <i className="fas fa-angle-right" />
                    </div>
                  </div>
                </section>
              </div>
            ))}
        </section>
      )}
    </div>
  );
};

export default MyBids;
