import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Blog.scss";
import { useSelector, useDispatch } from "react-redux";
import { getBlogsComment, getBlogsData } from "../../redux/homeAction";
import moment from "moment";
import Loader from "../Home/loader/loader";
import { toast } from "react-toastify";

const Singleblog = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getBlogList = useSelector((state) => state.home.getBlogList);
  const [values, setValues] = useState({});
  const [replyOpen, setReplyOpen] = useState(false);
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  useEffect(() => {
    dispatch(getBlogsData());
  }, []);

  const showBLog =
    getBlogList &&
    getBlogList.blogs &&
    getBlogList.blogs.find((data) => data.slug === slug);
  const [blogCommentId, setBlogCommentId] = useState(showBLog && showBLog.id);

  const inputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onReplyOpen = (id) => {
    setBlogCommentId(id);
    setReplyOpen(id);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("comment", values?.comment);
    formData.append("slug", slug);
    formData.append("parent_id", blogCommentId);

    if (!values?.name) {
      toast.error("Enter You Name");
    } else if (!values?.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values?.email)) {
      toast.error("Invalid Email Address");
    } else if (!values?.website) {
      toast.error("Enter you website");
    } else if (!values?.comment) {
      toast.error("Enter message");
    } else {
      setValues({
        name: "",
        email: "",
        website: "",
        comment: "",
      });
      dispatch(getBlogsComment(formData));
    }
  };

  const showSinglePage = (slug) => {
    navigate(`/singleblog/${slug}`);
  };

  const getSiteSettingDetails = useSelector(
    (state) => state.home.getSiteSettingDetails
  );

  return (
    <div>
      {getBlogList ? (
        <>
          <div className="blog_page_style">
            <div className="container">
              <h1 className="blog_page_style_h1">
                {showBLog && showBLog.title}
              </h1>
            </div>
          </div>
          <div className="blog_Page_space">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="blog_Page_img">
                    <img src={showBLog && showBLog.image} alt="" />
                    <a>{showBLog && showBLog.cat_title}</a>
                  </div>
                  <div className="blog_Page_deatils">
                    <h1 className="blog_Page_deatils_h1">
                      {showBLog && showBLog.title}
                    </h1>
                    <div className="blog_Page_deatils_date">
                      <p>
                        <i className="fa fa-calendar"></i>
                        {moment(showBLog && showBLog.created_at).format(
                          "DD-MMM-YYYY"
                        )}
                      </p>
                      <p>
                        <i className="fa fa-comments"></i>
                        {showBLog && showBLog.blog_comment.length
                          ? showBLog && showBLog.blog_comment.length
                          : "0"}{" "}
                        Comments
                      </p>
                    </div>
                    <p
                      className="blog_Page_deatils_P"
                      dangerouslySetInnerHTML={{
                        __html: showBLog && showBLog.long_description,
                      }}
                    ></p>
                    <div className="blog_Page_deatils_a">
                      <a
                        href={
                          getSiteSettingDetails &&
                          getSiteSettingDetails.facebook
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a
                        href={
                          getSiteSettingDetails && getSiteSettingDetails.twitter
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        href={
                          getSiteSettingDetails &&
                          getSiteSettingDetails.pinterest
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-pinterest"></i>
                      </a>

                      <a
                        href={
                          getSiteSettingDetails &&
                          getSiteSettingDetails.instagram
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </div>
                    <div className="blog_Page_deatils_comment">
                      <h1>All Comments</h1>
                      {showBLog &&
                        showBLog.blog_comment &&
                        showBLog.blog_comment.map((data, index) => (
                          <div
                            className="blog_Page_deatils_name_border"
                            key={index}
                          >
                            <div className="blog_Page_deatils_name">
                              <div className="blog_Page_deatils_logo">
                                <i className="fa fa-user"></i>
                              </div>
                              <div className="blog_Page_deatils_reply">
                                <h4>{data.user_name}</h4>
                                <span>
                                  {" "}
                                  {moment(data.created_at).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </span>

                                <p>{data.comment}</p>
                                <h6 onClick={() => onReplyOpen(data.id)}>
                                  {data.child_comment.length
                                    ? data.child_comment.length
                                    : "0"}{" "}
                                  Reply
                                </h6>
                                {replyOpen === data.id && (
                                  <form
                                    className="blog_Page_deatils_form_tag"
                                    onSubmit={onSubmit}
                                  >
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <input
                                          type="text"
                                          placeholder="Your Name *"
                                          className="blog_Page_deatils_form_input"
                                          name="name"
                                          value={values?.name}
                                          onChange={(e) => inputChange(e)}
                                        />
                                      </div>
                                      <div className="col-lg-4">
                                        <input
                                          type="text"
                                          placeholder="Email Address *"
                                          className="blog_Page_deatils_form_input"
                                          name="email"
                                          value={values?.email}
                                          onChange={(e) => inputChange(e)}
                                        />
                                      </div>
                                      <div className="col-lg-4">
                                        <input
                                          type="text"
                                          placeholder="Your Website *"
                                          className="blog_Page_deatils_form_input"
                                          name="website"
                                          value={values?.website}
                                          onChange={(e) => inputChange(e)}
                                        />
                                      </div>
                                      <div className="col-lg-12">
                                        <textarea
                                          placeholder="Comment"
                                          name="comment"
                                          className="blog_Page_deatils_form_textarea"
                                          rows="6"
                                          tabIndex="6"
                                          value={values?.comment}
                                          onChange={(e) => inputChange(e)}
                                        ></textarea>
                                      </div>
                                      <div className="col-lg-12">
                                        <input
                                          type="checkbox"
                                          placeholder="Your Website *"
                                          className="blog_Page_deatils_form_checkbox"
                                          style={{ marginRight: "5px" }}
                                          onChange={onCheckChange}
                                        />
                                        <span className="text-secondary">
                                          Save my name, email, and website in
                                          this browser for the next time I
                                          comment
                                        </span>
                                      </div>

                                      <div className="blog_Page_deatils_form_btn">
                                        <button type="submit">
                                          Post comment
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                )}
                              </div>
                            </div>
                            {data &&
                              data.child_comment &&
                              data.child_comment.map((data, i) => (
                                <div
                                  className="blog_Page_deatils_reply"
                                  key={i}
                                >
                                  <span>
                                    {moment(data.created_at).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </span>

                                  <p>{data.comment}</p>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                    <div className="blog_Page_deatils_form">
                      <h1>Leave a Reply</h1>
                      <p className="blog_Page_deatils_form_p">
                        Your email address will not be published.Required fields
                        are marked <span>*</span>
                      </p>
                      <form
                        className="blog_Page_deatils_form_tag"
                        onSubmit={onSubmit}
                      >
                        <div className="row">
                          <div className="col-lg-4">
                            <input
                              type="text"
                              placeholder="Your Name *"
                              className="blog_Page_deatils_form_input"
                              name="name"
                              value={values?.name}
                              onChange={(e) => inputChange(e)}
                            />
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              placeholder="Email Address *"
                              className="blog_Page_deatils_form_input"
                              name="email"
                              value={values?.email}
                              onChange={(e) => inputChange(e)}
                            />
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              placeholder="Your Website *"
                              className="blog_Page_deatils_form_input"
                              name="website"
                              value={values?.website}
                              onChange={(e) => inputChange(e)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <textarea
                              placeholder="Comment"
                              name="comment"
                              className="blog_Page_deatils_form_textarea"
                              rows="6"
                              tabIndex="6"
                              value={values?.comment}
                              onChange={(e) => inputChange(e)}
                            ></textarea>
                          </div>
                          <div className="col-lg-12">
                            <input
                              type="checkbox"
                              placeholder="Your Website *"
                              className="blog_Page_deatils_form_checkbox"
                              style={{ marginRight: "5px" }}
                            />
                            <span
                              className="text-secondary"
                              style={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              Save my name, email, and website in this browser
                              for the next time I comment
                            </span>
                          </div>

                          <div className="blog_Page_deatils_form_btn">
                            <button type="submit"> Post comment</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="blog_Page_recent">
                    <h3>Recent Post</h3>
                    {getBlogList &&
                      getBlogList.recent_blog &&
                      getBlogList.recent_blog.map((data, index) => (
                        <div className="blog_Page_recent_flex" key={index}>
                          <div className="blog_Page_recent_img">
                            <img
                              src={data.image}
                              alt="img"
                              onClick={() => showSinglePage(data.slug)}
                            />
                          </div>
                          <div className="blog_Page_recent_title">
                            <p onClick={() => showSinglePage(data.slug)}>
                              {data.title}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="blog_Page_tags">
                    <h3>Tags</h3>
                    <div className="row pt-3 g-0">
                      {getBlogList &&
                        getBlogList.all_tags &&
                        getBlogList.all_tags.map((data, index) => (
                          <div
                            className="col-lg-4 col-md-4 col-sm-4 col-6"
                            key={index}
                          >
                            <p onClick={() => showSinglePage(data.blog_slug)}>
                              {data.title}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="blog_Page_gallery">
                    <h3>Our gallery</h3>
                    <div className="row pt-3">
                      {showBLog &&
                        showBLog.blog_images &&
                        showBLog.blog_images.map((data, index) => (
                          <div
                            className="col-lg-4 col-md-4 col-sm-4 col-6"
                            key={index}
                          >
                            <div className="blog_Page_gellery_img">
                              <img src={data} alt="img" />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Singleblog;
