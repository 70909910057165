import React, { useState } from "react";
import "./changePassword.scss";

const ResetPassword = () => {
  return (
    <>
      <section className="changepaswordwrapper">
        <div className="container">
          <div className="changesinputwrapper">
            <form className="changeinputformwrapp">
              <h3 className="text-center">Reset Password</h3>
              <div className="inputswrpappers">
                <span>Enter new Password</span>
                <input
                  className="newpwdinptwrapp"
                  type="password"
                  name="password"
                  minLength="8"
                  placeholder="Enter new Password"
                  required
                />
              </div>
              <div className="inputswrpappers">
                <span>Confirm Password</span>
                <input
                  className="newpwdinptwrapp"
                  type="password"
                  name="NewPassword"
                  placeholder="Confirm Password"
                  minLength="8"
                  required
                />
              </div>
              <button className="chanegpwdbtn" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
