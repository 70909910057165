import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDHXK9LGzhzkdXOU81-wdbf1Yv14WwEsQ8",
  authDomain: "smileszy-7f962.firebaseapp.com",
  projectId: "smileszy-7f962",
  storageBucket: "smileszy-7f962.appspot.com",
  messagingSenderId: "1061599931938",
  appId: "1:1061599931938:web:13547bfc1455010f72247b",
  measurementId: "G-9NW4VEKKSR",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export { auth, googleProvider, facebookProvider };
export default db;
