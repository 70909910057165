import React from "react";

const DiffrentAddress = ({
  diffAddressValues,
  getCountryList,
  getStateList,
  getCityList,
  diffAddressChange,
  diffAddressCountryChange,
  diffAddressStateChange,
  diffAddressCityChange,
}) => {
  return (
    <div className="form-wrapper">
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="First name"
        name="first_name"
        value={diffAddressValues?.first_name}
        onChange={(e) => diffAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Last name"
        name="last_name"
        value={diffAddressValues?.last_name}
        onChange={(e) => diffAddressChange(e)}
      />
      <select
        name="country"
        value={diffAddressValues?.country}
        className="form-wrapperInput"
        onChange={(e) => diffAddressCountryChange(e)}
      >
        <option>Select Country</option>
        {getCountryList &&
          getCountryList.map((item, key) => (
            <option value={item.name} key={key}>
              {item.name}
            </option>
          ))}
      </select>

      <select
        className="form-wrapperInput"
        onChange={(e) => diffAddressStateChange(e)}
        name="state"
        value={diffAddressValues?.state}
      >
        <option>Select State</option>
        {getStateList &&
          getStateList.map((item, key) => (
            <option key={key} value={item.state_name}>
              {item.state_name}
            </option>
          ))}
      </select>
      <select
        className="form-wrapperInput"
        onChange={(e) => diffAddressCityChange(e)}
        name="city"
        value={diffAddressValues?.city}
      >
        <option>Select City</option>
        {getCityList &&
          getCityList.map((item, key) => (
            <option key={key} value={item.city_name}>
              {item.city_name}
            </option>
          ))}
      </select>
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Street address"
        name="address"
        value={diffAddressValues?.address}
        onChange={(e) => diffAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Area,Colony,Street"
        name="address2"
        value={diffAddressValues?.address2}
        onChange={(e) => diffAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Landmark"
        name="landmark"
        value={diffAddressValues?.landmark}
        onChange={(e) => diffAddressChange(e)}
      />

      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Postcode"
        name="zip_code"
        value={diffAddressValues?.zip_code}
        onChange={(e) => diffAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Phone"
        name="phone"
        value={diffAddressValues?.phone}
        onChange={(e) => diffAddressChange(e)}
      />
      <input
        type="text"
        className="form-wrapperInput"
        placeholder="Alternate Number"
        name="alternate_phone"
        value={diffAddressValues?.alternate_phone}
        onChange={(e) => diffAddressChange(e)}
      />
      <select
        className="form-wrapperInput"
        name="address_type"
        value={diffAddressValues?.address_type}
        onChange={(e) => diffAddressChange(e)}
      >
        <option>Select Address Type</option>
        <option value="billing">Billing</option>
        <option value="shipping">Shipping</option>
      </select>
      <p>Order Notes</p>
      <textarea
        rows="5"
        cols="15"
        className="form-wrapperInputTextArea"
        placeholder="Notes about your order, e.g. special notes for delivery."
      ></textarea>
    </div>
  );
};

export default DiffrentAddress;
