import React from "react";
import StripeCheckout from "react-stripe-checkout";
import "./PaymetsCard.scss";

const PaymetsCard = ({ handleAddCard, getAllCardList, handleDeleteCard }) => {
  //   Stripe -- Test Mode
  // Publishable key--
  // pk_test_51Ldat6Eh9lYQpdiH9sNGYdSx2PXUQvwafLW0bj0XtoFxAAkZEAeAuMcvfwri34RuJjcVYhkbkJ1xrTQc3u5iV2gv00wJUghfFu

  // Secret key--
  // sk_test_51Ldat6Eh9lYQpdiHc6VAsEECEQZntNRSDwFbGzrBIuc8ZUyCg1Bx1BTWiABAaPd1NqIfJzo9DbgSVRKIbtZwMIst00j5TdK7xO

  // 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

  // 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

  return (
    <>
      <div className="cards-wrapper">
        <div className="Addnewcard-wrappeer">
          <StripeCheckout
            label="Add Card"
            token={handleAddCard}
            stripeKey="pk_live_51Ldat6Eh9lYQpdiHNGxm3DQ8SIUtJPYl2Y5WyrDG0NaxtgX13b1AgbumcBmBy4pewxtNlGZ96p6DhUk3Q3QvFrW900kPexmNKD"
          >
            <button className="stripe-btns">
              <i className="fas fa-plus-circle me-2"></i>
              ADD CARDsssssssss
            </button>
          </StripeCheckout>
        </div>
        {getAllCardList &&
          getAllCardList.map((data, key) => (
            <div className="card-details-wrapper" key={key}>
              <div className="">
                <img src="/img/cards.png" alt="#" className="me-2"></img>
                <label>
                  ***** *****<span>{data.last4}</span>
                </label>
              </div>
              {/* <span className="loader-spinner">
          <i class="fa-solid fa-spinner loading-gif"></i>
        </span> */}
              <span
                className="remove-btn"
                onClick={() => handleDeleteCard(data.id)}
              >
                Remove
              </span>
            </div>
          ))}
      </div>
    </>
  );
};

export default PaymetsCard;
