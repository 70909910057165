import React from "react";

const StartSelling = () => {
  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">Start Selling</h1>
        </div>
      </div>
    </>
  );
};

export default StartSelling;
