import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPagesData } from "../redux/homeAction";
import "./pages.scss";
import Loader from "../Components/Home/loader/loader";

const Terms = () => {
  const dispatch = useDispatch();
  const termsAndCondition = useSelector((state) => state.home.getPagesList);

  useEffect(() => {
    const data = {
      id: 9,
    };
    dispatch(getPagesData(data));
  }, []);

  return (
    <>
      <div className="blog_page_style">
        <div className="container">
          <h1 className="blog_page_style_h1">term & conditions</h1>
        </div>
      </div>
      <div className="container">
        <div className="terms-page-wrapper">
          {termsAndCondition ? (
            <div
              dangerouslySetInnerHTML={{
                __html: termsAndCondition && termsAndCondition.content,
              }}
            ></div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default Terms;
