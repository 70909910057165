import React, { useEffect, useState } from "react";
import "./ShopItemsone.scss";
import { Link, useNavigate } from "react-router-dom";
import Login from "../../../../popups/Login/Login";

const ShopItemsone = ({
  getProductsList,
  slug,
  handleAddToWishList,
  handleRemoveFromWishList,
  getWishList,
  addToCartHandler,
  currentId,
}) => {
  const navigate = useNavigate();
  const [loginOpen, setLoginOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const pageNumbers = [];

  const openLoginModal = () => setLoginOpen(true);
  const closeLoginModal = () => setLoginOpen(false);

  useEffect(() => {
    if (getProductsList) {
      for (
        let i = 1;
        i <=
        Math.ceil(getProductsList && getProductsList.length / postsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
    }
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentBlogs =
    getProductsList && getProductsList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const showProduct = (slug) => {
    navigate(`/product/${slug}`);
  };

  return (
    <>
      <div className="shop-items">
        {getProductsList && getProductsList.length === 0 ? (
          <section
            className="welcome-cartbg-wrappp"
            style={{ backgroundColor: "white", display: "contents" }}
          >
            <div className="welcome-screenWrapper">
              <h4 className="no-productwrapp text-center">
                No Products Found {slug}
              </h4>
            </div>
          </section>
        ) : (
          <>
            {currentBlogs &&
              currentBlogs.map((data, index) => (
                <div className="items-card-wrapper" key={index}>
                  <div className="itemsone-wrapper">
                    <div className="hover-wish-list-wrappper">
                      <div className="wish-list-imgs">
                        {localStorage.getItem("user-token") ? (
                          <img
                            src={
                              getWishList?.find((item) => item?.id === data?.id)
                                ? "/img/ha2.png"
                                : "/img/ha.png"
                            }
                            alt={data?.pname}
                            className="img-fluid"
                            onClick={
                              !getWishList?.find((item) => item?.id == data?.id)
                                ? () => handleAddToWishList(data?.id)
                                : () =>
                                    handleRemoveFromWishList(
                                      getWishList?.find(
                                        (item) => item?.id == data?.id
                                      )
                                    )
                            }
                          />
                        ) : (
                          <img
                            src="/img/ha.png"
                            alt=""
                            className="img-fluid"
                            onClick={openLoginModal}
                          />
                        )}
                      </div>
                    </div>
                    <div className="product-imgs-wrap">
                      <img
                        src={data.featured_image}
                        alt=""
                        className="img-fluid"
                        onClick={() => showProduct(data.slug)}
                      ></img>
                    </div>
                    <div className="items-content-wrappers">
                      <p
                        className="items-name"
                        onClick={() => showProduct(data.slug)}
                      >
                        {data.pname}
                      </p>

                      {data.in_stock == 0 ? (
                        <p className="instock" style={{ color: "red" }}>
                          Out Of Stock
                        </p>
                      ) : (
                        <p className="instock">In stock</p>
                      )}
                      {data.s_price ? (
                        <>
                          <del className="last-price">
                            {data.currency_sign}
                            {data.p_price}
                          </del>
                          <span className="latest-price">
                            {data.currency_sign}
                            {data.s_price}
                          </span>
                        </>
                      ) : (
                        <del className="last-price">
                          {data.currency_sign}
                          {data.p_price}
                        </del>
                      )}
                      {data.product_type === "single" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(data)}
                        >
                          {currentId == data.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : data.product_type === "bid" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(data.slug)}
                        >
                          Bid Now
                        </button>
                      ) : data.product_type === "booking" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => showProduct(data.slug)}
                        >
                          Book Now
                        </button>
                      ) : data.product_type === "variants" ? (
                        <button
                          className="addto-cart-btn"
                          onClick={() => addToCartHandler(data)}
                        >
                          {currentId == data.id ? (
                            <span className="loader-spinner">
                              <i className="fa-solid fa-spinner loading-gif"></i>
                            </span>
                          ) : (
                            <span className="loader-spinner">Add to cart</span>
                          )}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            {getProductsList && getProductsList.length > 12 ? (
              <div className="row d-flex align-items-center justify-content-center w-100 mt-5">
                <div className="col d-flex align-items-center justify-content-center">
                  <ul className="mb-4">
                    <button
                      className={
                        currentPage > 1 ? "prevButton" : "prevButtonActive"
                      }
                      onClick={() => {
                        if (currentPage > 1) {
                          paginate(currentPage - 1);
                        }
                      }}
                    >
                      Prev
                    </button>
                    <button className="prevButton">{currentPage}</button>
                    <button
                      className={
                        parseInt(indexOfLastPost) <
                        parseInt(getProductsList && getProductsList.length)
                          ? "prevButton"
                          : "prevButtonActive"
                      }
                      onClick={() => {
                        if (
                          parseInt(indexOfLastPost) <
                          parseInt(getProductsList && getProductsList.length)
                        ) {
                          paginate(currentPage + 1);
                        }
                      }}
                    >
                      Next
                    </button>
                  </ul>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>

      <Login open={loginOpen} onCloseModal={closeLoginModal} />
    </>
  );
};

export default ShopItemsone;
