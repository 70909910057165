import React, { useEffect, useState } from "react";
import "./signUp.scss";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import OtpPopups from "../otpPopups/OtpPopups";
import { onRegister } from "../../redux/homeAction";
import { toast } from "react-toastify";

const SignUp = ({ open, onCloseModal, openLoginModal }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({ countryCode: "+91" });
  const [otpPopup, setOtpPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const currencyList = useSelector((state) => state.home.currencyList);
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;
  var numberRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const onInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const openOtpPopup = () => {
    setOtpPopup(true);
    onCloseModal();
  };
  const closeOtpPopup = () => setOtpPopup(false);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("registerEmail", values.email);

    const data = {
      name: values.name,
      phone: `${values.countryCode}${values.phone}`,
      email: values.email,
      password: values.password,
    };

    if (!values.name) {
      toast.error("Enter Your Name");
    } else if (!values.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values.email)) {
      toast.error("Invalid Email Address");
    } else if (!values.password) {
      toast.error("Enter Password");
    } else if (!strongRegex.test(values.password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else if (!values.phone) {
      toast.error("Enter Phone Number");
    } else if (!numberRegex.test(values.phone)) {
      toast.error("Invalid phone number");
    } else if (!values.countryCode) {
      toast.error("Select Country Code");
    } else if (!values.c_password) {
      toast.error("Enter Confirm Password");
    } else if (values.password !== values.c_password) {
      toast.error("Password and confirm password should be match");
    } else {
      setValues({
        name: "",
        email: "",
        password: "",
        phone: "",
        c_password: "",
      });
      dispatch(onRegister(data, openOtpPopup));
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{ modal: "singupform-wrapper-modal" }}
      >
        <div className="inner-container-signup">
          <div className="title">
            <h2 className="title-text">Sign up </h2>
          </div>

          <form className="form-wrapper" onSubmit={handleSubmitForm}>
            <div className="form-input-wrapper">
              <div className="form-element-group">
                <label htmlFor="username">Name</label>
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={values?.name}
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="main-inputswrap">
              <div className="left-wrapeer">
                <div className="form">
                  <div className="form-element-group input-res">
                    <label htmlFor="Username">Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values?.email}
                      onChange={onInputChange}
                    />
                  </div>

                  <div className="form-element-group input-res">
                    <label htmlFor="phone">Phone</label>
                    <div className="phones">
                      <input
                        type="number"
                        placeholder="Phone"
                        name="phone"
                        value={values?.phone}
                        onChange={onInputChange}
                        onWheel={(e) => e.target.blur()}
                      />
                      <select
                        className="countyr-code-wrapp"
                        name="countryCode"
                        value={values?.countryCode}
                        onChange={onInputChange}
                      >
                        {currencyList &&
                          currencyList.map((data, index) => (
                            <option key={index} value={data.country_code}>
                              {data.country_code}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-wrapeer">
                <div className="form">
                  <div className="form-element-group password-input">
                    <label htmlFor="username">Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={values?.password}
                      onChange={onInputChange}
                    />
                    {showPassword === false ? (
                      <i
                        class="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={() => setShowPassword(true)}
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => setShowPassword(false)}
                      ></i>
                    )}
                  </div>

                  <div className="form-element-group password-input">
                    <label htmlFor="username">Confirm Password</label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      className="username"
                      name="c_password"
                      value={values?.c_password}
                      onChange={onInputChange}
                    />
                    {showConfirmPassword === false ? (
                      <i
                        class="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={() => setShowConfirmPassword(true)}
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => setShowConfirmPassword(false)}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="submit-btn" name="submit-btn">
              Sign up
            </button>

            <p className="alreadyacountline">
              Already have an account?{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onCloseModal();
                  openLoginModal();
                }}
              >
                Return to Sign In
              </span>
            </p>
          </form>
        </div>
      </Modal>
      <OtpPopups otpPopup={otpPopup} closeOtpPopup={closeOtpPopup} />
    </>
  );
};

export default SignUp;
